const _gsScope = typeof module !== 'undefined' && module.exports && typeof global !== 'undefined'
  ? global
  : this || window;
(_gsScope._gsQueue || (_gsScope._gsQueue = [])).push(() => {
  _gsScope._gsDefine(
    'TweenMax',
    ['core.Animation', 'core.SimpleTimeline', 'TweenLite'],
    (t, e, i) => {
      const s = function (t) {
        let e
        const i = []
        const s = t.length
        for (e = 0; e !== s; i.push(t[e++]));
        return i
      }
      const r = function (t, e, i) {
        let s
        let r
        const n = t.cycle
        for (s in n) {
          (r = n[s]),
          (t[s] = typeof r === 'function' ? r.call(e[i], i) : r[i % r.length])
        }
        delete t.cycle
      }
      var n = function (t, e, s) {
        i.call(this, t, e, s),
        (this._cycle = 0),
        (this._yoyo = this.vars.yoyo === !0),
        (this._repeat = this.vars.repeat || 0),
        (this._repeatDelay = this.vars.repeatDelay || 0),
        (this._dirty = !0),
        (this.render = n.prototype.render)
      }
      const a = 1e-10
      const o = i._internals
      const l = o.isSelector
      const h = o.isArray
      const _ = (n.prototype = i.to({}, 0.1, {}))
      const u = [];
      (n.version = '1.18.0'),
      (_.constructor = n),
      (_.kill()._gc = !1),
      (n.killTweensOf = n.killDelayedCallsTo = i.killTweensOf),
      (n.getTweensOf = i.getTweensOf),
      (n.lagSmoothing = i.lagSmoothing),
      (n.ticker = i.ticker),
      (n.render = i.render),
      (_.invalidate = function () {
        return (
          (this._yoyo = this.vars.yoyo === !0),
          (this._repeat = this.vars.repeat || 0),
          (this._repeatDelay = this.vars.repeatDelay || 0),
          this._uncache(!0),
          i.prototype.invalidate.call(this)
        )
      }),
      (_.updateTo = function (t, e) {
        let s
        const r = this.ratio
        const n = this.vars.immediateRender || t.immediateRender
        e
            && this._startTime < this._timeline._time
            && ((this._startTime = this._timeline._time),
            this._uncache(!1),
            this._gc
              ? this._enabled(!0, !1)
              : this._timeline.insert(this, this._startTime - this._delay))
        for (s in t) this.vars[s] = t[s]
        if (this._initted || n) {
          if (e) (this._initted = !1), n && this.render(0, !0, !0)
          else if (
            (this._gc && this._enabled(!0, !1),
            this._notifyPluginsOfEnabled
                && this._firstPT
                && i._onPluginEvent('_onDisable', this),
            this._time / this._duration > 0.998)
          ) {
            const a = this._time
            this.render(0, !0, !1),
            (this._initted = !1),
            this.render(a, !0, !1)
          } else if (this._time > 0 || n) {
            (this._initted = !1), this._init()
            for (var o, l = 1 / (1 - r), h = this._firstPT; h;) (o = h.s + h.c), (h.c *= l), (h.s = o - h.c), (h = h._next)
          }
        }
        return this
      }),
      (_.render = function (t, e, i) {
        this._initted
            || (this._duration === 0 && this.vars.repeat && this.invalidate())
        let s
        let r
        let n
        let l
        let h
        let _
        let u
        let c
        const f = this._dirty ? this.totalDuration() : this._totalDuration
        const p = this._time
        const m = this._totalTime
        const d = this._cycle
        const g = this._duration
        const v = this._rawPrevTime
        if (
          (t >= f
            ? ((this._totalTime = f),
            (this._cycle = this._repeat),
            this._yoyo && (1 & this._cycle) !== 0
              ? ((this._time = 0),
              (this.ratio = this._ease._calcEnd
                ? this._ease.getRatio(0)
                : 0))
              : ((this._time = g),
              (this.ratio = this._ease._calcEnd
                ? this._ease.getRatio(1)
                : 1)),
            this._reversed
                  || ((s = !0),
                  (r = 'onComplete'),
                  (i = i || this._timeline.autoRemoveChildren)),
            g === 0
                  && (this._initted || !this.vars.lazy || i)
                  && (this._startTime === this._timeline._duration && (t = 0),
                  (t === 0 || v < 0 || v === a)
                    && v !== t
                    && ((i = !0), v > a && (r = 'onReverseComplete')),
                  (this._rawPrevTime = c = !e || t || v === t ? t : a)))
            : t < 1e-7
              ? ((this._totalTime = this._time = this._cycle = 0),
              (this.ratio = this._ease._calcEnd ? this._ease.getRatio(0) : 0),
              (m !== 0 || (g === 0 && v > 0))
                  && ((r = 'onReverseComplete'), (s = this._reversed)),
              t < 0
                  && ((this._active = !1),
                  g === 0
                    && (this._initted || !this.vars.lazy || i)
                    && (v >= 0 && (i = !0),
                    (this._rawPrevTime = c = !e || t || v === t ? t : a))),
              this._initted || (i = !0))
              : ((this._totalTime = this._time = t),
              this._repeat !== 0
                  && ((l = g + this._repeatDelay),
                  (this._cycle = (this._totalTime / l) >> 0),
                  this._cycle !== 0
                    && this._cycle === this._totalTime / l
                    && this._cycle--,
                  (this._time = this._totalTime - this._cycle * l),
                  this._yoyo
                    && (1 & this._cycle) !== 0
                    && (this._time = g - this._time),
                  this._time > g
                    ? (this._time = g)
                    : this._time < 0 && (this._time = 0)),
              this._easeType
                ? ((h = this._time / g),
                (_ = this._easeType),
                (u = this._easePower),
                (_ === 1 || (_ === 3 && h >= 0.5)) && (h = 1 - h),
                _ === 3 && (h *= 2),
                u === 1
                  ? (h *= h)
                  : u === 2
                    ? (h *= h * h)
                    : u === 3
                      ? (h *= h * h * h)
                      : u === 4 && (h *= h * h * h * h),
                (this.ratio = _ === 1
                  ? 1 - h
                  : _ === 2
                    ? h
                    : this._time / g < 0.5
                      ? h / 2
                      : 1 - h / 2))
                : (this.ratio = this._ease.getRatio(this._time / g))),
          p === this._time && !i && d === this._cycle)
        ) {
          return (
            m !== this._totalTime
                && this._onUpdate
                && (e || this._callback('onUpdate')),
            void 0
          )
        }
        if (!this._initted) {
          if ((this._init(), !this._initted || this._gc)) return
          if (
            !i
              && this._firstPT
              && ((this.vars.lazy !== !1 && this._duration)
                || (this.vars.lazy && !this._duration))
          ) {
            return (
              (this._time = p),
              (this._totalTime = m),
              (this._rawPrevTime = v),
              (this._cycle = d),
              o.lazyTweens.push(this),
              (this._lazy = [t, e]),
              void 0
            )
          }
          this._time && !s
            ? (this.ratio = this._ease.getRatio(this._time / g))
            : s
                && this._ease._calcEnd
                && (this.ratio = this._ease.getRatio(this._time === 0 ? 0 : 1))
        }
        for (
          this._lazy !== !1 && (this._lazy = !1),
          this._active
                || (!this._paused
                  && this._time !== p
                  && t >= 0
                  && (this._active = !0)),
          m === 0
                && (this._initted === 2 && t > 0 && this._init(),
                this._startAt
                  && (t >= 0
                    ? this._startAt.render(t, e, i)
                    : r || (r = '_dummyGS')),
                this.vars.onStart
                  && (this._totalTime !== 0 || g === 0)
                  && (e || this._callback('onStart'))),
          n = this._firstPT;
          n;

        ) {
          n.f
            ? n.t[n.p](n.c * this.ratio + n.s)
            : (n.t[n.p] = n.c * this.ratio + n.s),
          (n = n._next)
        }
        this._onUpdate
            && (t < 0
              && this._startAt
              && this._startTime
              && this._startAt.render(t, e, i),
            e || ((this._totalTime !== m || s) && this._callback('onUpdate'))),
        this._cycle !== d
              && (e
                || this._gc
                || (this.vars.onRepeat && this._callback('onRepeat'))),
        r
              && (!this._gc || i)
              && (t < 0
                && this._startAt
                && !this._onUpdate
                && this._startTime
                && this._startAt.render(t, e, i),
              s
                && (this._timeline.autoRemoveChildren && this._enabled(!1, !1),
                (this._active = !1)),
              !e && this.vars[r] && this._callback(r),
              g === 0
                && this._rawPrevTime === a
                && c !== a
                && (this._rawPrevTime = 0))
      }),
      (n.to = function (t, e, i) {
        return new n(t, e, i)
      }),
      (n.from = function (t, e, i) {
        return (
          (i.runBackwards = !0),
          (i.immediateRender = i.immediateRender != 0),
          new n(t, e, i)
        )
      }),
      (n.fromTo = function (t, e, i, s) {
        return (
          (s.startAt = i),
          (s.immediateRender = s.immediateRender != 0 && i.immediateRender != 0),
          new n(t, e, s)
        )
      }),
      (n.staggerTo = n.allTo = function (t, e, a, o, _, c, f) {
        o = o || 0
        let p
        let m
        let d
        let g
        let v = a.delay || 0
        const y = []
        const T = function () {
          a.onComplete
                  && a.onComplete.apply(a.onCompleteScope || this, arguments),
          _.apply(f || a.callbackScope || this, c || u)
        }
        const x = a.cycle
        let w = a.startAt && a.startAt.cycle
        for (
          h(t)
                || (typeof t === 'string' && (t = i.selector(t) || t),
                l(t) && (t = s(t))),
          t = t || [],
          o < 0 && ((t = s(t)), t.reverse(), (o *= -1)),
          p = t.length - 1,
          d = 0;
          p >= d;
          d++
        ) {
          m = {}
          for (g in a) m[g] = a[g]
          if ((x && r(m, t, d), w)) {
            w = m.startAt = {}
            for (g in a.startAt) w[g] = a.startAt[g]
            r(m.startAt, t, d)
          }
          (m.delay = v),
          d === p && _ && (m.onComplete = T),
          (y[d] = new n(t[d], e, m)),
          (v += o)
        }
        return y
      }),
      (n.staggerFrom = n.allFrom = function (t, e, i, s, r, a, o) {
        return (
          (i.runBackwards = !0),
          (i.immediateRender = i.immediateRender != 0),
          n.staggerTo(t, e, i, s, r, a, o)
        )
      }),
      (n.staggerFromTo = n.allFromTo = function (t, e, i, s, r, a, o, l) {
        return (
          (s.startAt = i),
          (s.immediateRender = s.immediateRender != 0 && i.immediateRender != 0),
          n.staggerTo(t, e, s, r, a, o, l)
        )
      }),
      (n.delayedCall = function (t, e, i, s, r) {
        return new n(e, 0, {
          delay: t,
          onComplete: e,
          onCompleteParams: i,
          callbackScope: s,
          onReverseComplete: e,
          onReverseCompleteParams: i,
          immediateRender: !1,
          useFrames: r,
          overwrite: 0,
        })
      }),
      (n.set = function (t, e) {
        return new n(t, 0, e)
      }),
      (n.isTweening = function (t) {
        return i.getTweensOf(t, !0).length > 0
      })
      var c = function (t, e) {
        for (var s = [], r = 0, n = t._first; n;) {
          n instanceof i
            ? (s[r++] = n)
            : (e && (s[r++] = n), (s = s.concat(c(n, e))), (r = s.length)),
          (n = n._next)
        }
        return s
      }
      const f = (n.getAllTweens = function (e) {
        return c(t._rootTimeline, e).concat(c(t._rootFramesTimeline, e))
      });
      (n.killAll = function (t, i, s, r) {
        i == null && (i = !0), s == null && (s = !0)
        let n
        let a
        let o
        const l = f(r != 0)
        const h = l.length
        const _ = i && s && r
        for (o = 0; h > o; o++) {
          (a = l[o]),
          (_
              || a instanceof e
              || ((n = a.target === a.vars.onComplete) && s)
              || (i && !n))
              && (t
                ? a.totalTime(a._reversed ? 0 : a.totalDuration())
                : a._enabled(!1, !1))
        }
      }),
      (n.killChildTweensOf = function (t, e) {
        if (t != null) {
          let r
          let a
          let _
          let u
          let c
          const f = o.tweenLookup
          if (
            (typeof t === 'string' && (t = i.selector(t) || t),
            l(t) && (t = s(t)),
            h(t))
          ) for (u = t.length; --u > -1;) n.killChildTweensOf(t[u], e)
          else {
            r = []
            for (_ in f) for (a = f[_].target.parentNode; a;) a === t && (r = r.concat(f[_].tweens)), (a = a.parentNode)
            for (c = r.length, u = 0; c > u; u++) {
              e && r[u].totalTime(r[u].totalDuration()),
              r[u]._enabled(!1, !1)
            }
          }
        }
      })
      const p = function (t, i, s, r) {
        (i = i !== !1), (s = s !== !1), (r = r !== !1)
        for (var n, a, o = f(r), l = i && s && r, h = o.length; --h > -1;) {
          (a = o[h]),
          (l
              || a instanceof e
              || ((n = a.target === a.vars.onComplete) && s)
              || (i && !n))
              && a.paused(t)
        }
      }
      return (
        (n.pauseAll = function (t, e, i) {
          p(!0, t, e, i)
        }),
        (n.resumeAll = function (t, e, i) {
          p(!1, t, e, i)
        }),
        (n.globalTimeScale = function (e) {
          let s = t._rootTimeline
          let r = i.ticker.time
          return arguments.length
            ? ((e = e || a),
            (s._startTime = r - ((r - s._startTime) * s._timeScale) / e),
            (s = t._rootFramesTimeline),
            (r = i.ticker.frame),
            (s._startTime = r - ((r - s._startTime) * s._timeScale) / e),
            (s._timeScale = t._rootTimeline._timeScale = e),
            e)
            : s._timeScale
        }),
        (_.progress = function (t) {
          return arguments.length
            ? this.totalTime(
              this.duration()
                  * (this._yoyo && (1 & this._cycle) !== 0 ? 1 - t : t)
                  + this._cycle * (this._duration + this._repeatDelay),
              !1,
            )
            : this._time / this.duration()
        }),
        (_.totalProgress = function (t) {
          return arguments.length
            ? this.totalTime(this.totalDuration() * t, !1)
            : this._totalTime / this.totalDuration()
        }),
        (_.time = function (t, e) {
          return arguments.length
            ? (this._dirty && this.totalDuration(),
            t > this._duration && (t = this._duration),
            this._yoyo && (1 & this._cycle) !== 0
              ? (t = this._duration
                    - t
                    + this._cycle * (this._duration + this._repeatDelay))
              : this._repeat !== 0
                  && (t += this._cycle * (this._duration + this._repeatDelay)),
            this.totalTime(t, e))
            : this._time
        }),
        (_.duration = function (e) {
          return arguments.length
            ? t.prototype.duration.call(this, e)
            : this._duration
        }),
        (_.totalDuration = function (t) {
          return arguments.length
            ? this._repeat === -1
              ? this
              : this.duration(
                (t - this._repeat * this._repeatDelay) / (this._repeat + 1),
              )
            : (this._dirty
                && ((this._totalDuration = this._repeat === -1
                  ? 999999999999
                  : this._duration * (this._repeat + 1)
                      + this._repeatDelay * this._repeat),
                (this._dirty = !1)),
            this._totalDuration)
        }),
        (_.repeat = function (t) {
          return arguments.length
            ? ((this._repeat = t), this._uncache(!0))
            : this._repeat
        }),
        (_.repeatDelay = function (t) {
          return arguments.length
            ? ((this._repeatDelay = t), this._uncache(!0))
            : this._repeatDelay
        }),
        (_.yoyo = function (t) {
          return arguments.length ? ((this._yoyo = t), this) : this._yoyo
        }),
        n
      )
    },
    !0,
  ),
  _gsScope._gsDefine(
    'TimelineLite',
    ['core.Animation', 'core.SimpleTimeline', 'TweenLite'],
    (t, e, i) => {
      const s = function (t) {
        e.call(this, t),
        (this._labels = {}),
        (this.autoRemoveChildren = this.vars.autoRemoveChildren === !0),
        (this.smoothChildTiming = this.vars.smoothChildTiming === !0),
        (this._sortChildren = !0),
        (this._onUpdate = this.vars.onUpdate)
        let i
        let s
        const r = this.vars
        for (s in r) {
          (i = r[s]),
          l(i)
                  && i.join('').indexOf('{self}') !== -1
                  && (r[s] = this._swapSelfInParams(i))
        }
        l(r.tweens) && this.add(r.tweens, 0, r.align, r.stagger)
      }
      const r = 1e-10
      const n = i._internals
      const a = (s._internals = {})
      const o = n.isSelector
      var l = n.isArray
      const h = n.lazyTweens
      const _ = n.lazyRender
      const u = _gsScope._gsDefine.globals
      const c = function (t) {
        let e
        const i = {}
        for (e in t) i[e] = t[e]
        return i
      }
      const f = function (t, e, i) {
        let s
        let r
        const n = t.cycle
        for (s in n) {
          (r = n[s]),
          (t[s] = typeof r === 'function' ? r.call(e[i], i) : r[i % r.length])
        }
        delete t.cycle
      }
      const p = (a.pauseCallback = function () {})
      const m = function (t) {
        let e
        const i = []
        const s = t.length
        for (e = 0; e !== s; i.push(t[e++]));
        return i
      }
      const d = (s.prototype = new e())
      return (
        (s.version = '1.18.0'),
        (d.constructor = s),
        (d.kill()._gc = d._forcingPlayhead = d._hasPause = !1),
        (d.to = function (t, e, s, r) {
          const n = (s.repeat && u.TweenMax) || i
          return e ? this.add(new n(t, e, s), r) : this.set(t, s, r)
        }),
        (d.from = function (t, e, s, r) {
          return this.add(((s.repeat && u.TweenMax) || i).from(t, e, s), r)
        }),
        (d.fromTo = function (t, e, s, r, n) {
          const a = (r.repeat && u.TweenMax) || i
          return e ? this.add(a.fromTo(t, e, s, r), n) : this.set(t, r, n)
        }),
        (d.staggerTo = function (t, e, r, n, a, l, h, _) {
          let u
          let p
          const d = new s({
            onComplete: l,
            onCompleteParams: h,
            callbackScope: _,
            smoothChildTiming: this.smoothChildTiming,
          })
          const g = r.cycle
          for (
            typeof t === 'string' && (t = i.selector(t) || t),
            t = t || [],
            o(t) && (t = m(t)),
            n = n || 0,
            n < 0 && ((t = m(t)), t.reverse(), (n *= -1)),
            p = 0;
            t.length > p;
            p++
          ) {
            (u = c(r)),
            u.startAt
                  && ((u.startAt = c(u.startAt)),
                  u.startAt.cycle && f(u.startAt, t, p)),
            g && f(u, t, p),
            d.to(t[p], e, u, p * n)
          }
          return this.add(d, a)
        }),
        (d.staggerFrom = function (t, e, i, s, r, n, a, o) {
          return (
            (i.immediateRender = i.immediateRender != 0),
            (i.runBackwards = !0),
            this.staggerTo(t, e, i, s, r, n, a, o)
          )
        }),
        (d.staggerFromTo = function (t, e, i, s, r, n, a, o, l) {
          return (
            (s.startAt = i),
            (s.immediateRender = s.immediateRender != 0 && i.immediateRender != 0),
            this.staggerTo(t, e, s, r, n, a, o, l)
          )
        }),
        (d.call = function (t, e, s, r) {
          return this.add(i.delayedCall(0, t, e, s), r)
        }),
        (d.set = function (t, e, s) {
          return (
            (s = this._parseTimeOrLabel(s, 0, !0)),
            e.immediateRender == null
                && (e.immediateRender = s === this._time && !this._paused),
            this.add(new i(t, 0, e), s)
          )
        }),
        (s.exportRoot = function (t, e) {
          (t = t || {}),
          t.smoothChildTiming == null && (t.smoothChildTiming = !0)
          let r
          let n
          const a = new s(t)
          const o = a._timeline
          for (
            e == null && (e = !0),
            o._remove(a, !0),
            a._startTime = 0,
            a._rawPrevTime = a._time = a._totalTime = o._time,
            r = o._first;
            r;

          ) {
            (n = r._next),
            (e && r instanceof i && r.target === r.vars.onComplete)
                  || a.add(r, r._startTime - r._delay),
            (r = n)
          }
          return o.add(a, 0), a
        }),
        (d.add = function (r, n, a, o) {
          let h; let _; let u; let c; let f; let
            p
          if (
            (typeof n !== 'number'
                && (n = this._parseTimeOrLabel(n, 0, !0, r)),
            !(r instanceof t))
          ) {
            if (r instanceof Array || (r && r.push && l(r))) {
              for (
                a = a || 'normal', o = o || 0, h = n, _ = r.length, u = 0;
                _ > u;
                u++
              ) {
                l((c = r[u])) && (c = new s({ tweens: c })),
                this.add(c, h),
                typeof c !== 'string'
                      && typeof c !== 'function'
                      && (a === 'sequence'
                        ? (h = c._startTime + c.totalDuration() / c._timeScale)
                        : a === 'start' && (c._startTime -= c.delay())),
                (h += o)
              }
              return this._uncache(!0)
            }
            if (typeof r === 'string') return this.addLabel(r, n)
            if (typeof r !== 'function') {
              throw (
                `Cannot add ${
                  r
                } into the timeline; it is not a tween, timeline, function, or string.`
              )
            }
            r = i.delayedCall(0, r)
          }
          if (
            (e.prototype.add.call(this, r, n),
            (this._gc || this._time === this._duration)
                && !this._paused
                && this._duration < this.duration())
          ) {
            for (f = this, p = f.rawTime() > r._startTime; f._timeline;) {
              p && f._timeline.smoothChildTiming
                ? f.totalTime(f._totalTime, !0)
                : f._gc && f._enabled(!0, !1),
              (f = f._timeline)
            }
          }
          return this
        }),
        (d.remove = function (e) {
          if (e instanceof t) {
            this._remove(e, !1)
            const i = (e._timeline = e.vars.useFrames
              ? t._rootFramesTimeline
              : t._rootTimeline)
            return (
              (e._startTime = (e._paused ? e._pauseTime : i._time)
                  - (e._reversed
                    ? e.totalDuration() - e._totalTime
                    : e._totalTime)
                    / e._timeScale),
              this
            )
          }
          if (e instanceof Array || (e && e.push && l(e))) {
            for (let s = e.length; --s > -1;) this.remove(e[s])
            return this
          }
          return typeof e === 'string'
            ? this.removeLabel(e)
            : this.kill(null, e)
        }),
        (d._remove = function (t, i) {
          e.prototype._remove.call(this, t, i)
          const s = this._last
          return (
            s
              ? this._time > s._startTime + s._totalDuration / s._timeScale
                  && ((this._time = this.duration()),
                  (this._totalTime = this._totalDuration))
              : (this._time = this._totalTime = this._duration = this._totalDuration = 0),
            this
          )
        }),
        (d.append = function (t, e) {
          return this.add(t, this._parseTimeOrLabel(null, e, !0, t))
        }),
        (d.insert = d.insertMultiple = function (t, e, i, s) {
          return this.add(t, e || 0, i, s)
        }),
        (d.appendMultiple = function (t, e, i, s) {
          return this.add(t, this._parseTimeOrLabel(null, e, !0, t), i, s)
        }),
        (d.addLabel = function (t, e) {
          return (this._labels[t] = this._parseTimeOrLabel(e)), this
        }),
        (d.addPause = function (t, e, s, r) {
          const n = i.delayedCall(0, p, s, r || this)
          return (
            (n.vars.onComplete = n.vars.onReverseComplete = e),
            (n.data = 'isPause'),
            (this._hasPause = !0),
            this.add(n, t)
          )
        }),
        (d.removeLabel = function (t) {
          return delete this._labels[t], this
        }),
        (d.getLabelTime = function (t) {
          return this._labels[t] != null ? this._labels[t] : -1
        }),
        (d._parseTimeOrLabel = function (e, i, s, r) {
          let n
          if (r instanceof t && r.timeline === this) this.remove(r)
          else if (r && (r instanceof Array || (r.push && l(r)))) {
            for (n = r.length; --n > -1;) {
              r[n] instanceof t
                  && r[n].timeline === this
                  && this.remove(r[n])
            }
          }
          if (typeof i === 'string') {
            return this._parseTimeOrLabel(
              i,
              s && typeof e === 'number' && this._labels[i] == null
                ? e - this.duration()
                : 0,
              s,
            )
          }
          if (
            ((i = i || 0),
            typeof e !== 'string' || (!isNaN(e) && this._labels[e] == null))
          ) e == null && (e = this.duration())
          else {
            if (((n = e.indexOf('=')), n === -1)) {
              return this._labels[e] == null
                ? s
                  ? (this._labels[e] = this.duration() + i)
                  : i
                : this._labels[e] + i
            }
            (i = parseInt(`${e.charAt(n - 1)}1`, 10) * Number(e.substr(n + 1))),
            (e = n > 1
              ? this._parseTimeOrLabel(e.substr(0, n - 1), 0, s)
              : this.duration())
          }
          return Number(e) + i
        }),
        (d.seek = function (t, e) {
          return this.totalTime(
            typeof t === 'number' ? t : this._parseTimeOrLabel(t),
            e !== !1,
          )
        }),
        (d.stop = function () {
          return this.paused(!0)
        }),
        (d.gotoAndPlay = function (t, e) {
          return this.play(t, e)
        }),
        (d.gotoAndStop = function (t, e) {
          return this.pause(t, e)
        }),
        (d.render = function (t, e, i) {
          this._gc && this._enabled(!0, !1)
          let s
          let n
          let a
          let o
          let l
          let u
          const c = this._dirty ? this.totalDuration() : this._totalDuration
          const f = this._time
          const p = this._startTime
          const m = this._timeScale
          const d = this._paused
          if (t >= c) {
            (this._totalTime = this._time = c),
            this._reversed
                  || this._hasPausedChild()
                  || ((n = !0),
                  (o = 'onComplete'),
                  (l = !!this._timeline.autoRemoveChildren),
                  this._duration === 0
                    && (t === 0
                      || this._rawPrevTime < 0
                      || this._rawPrevTime === r)
                    && this._rawPrevTime !== t
                    && this._first
                    && ((l = !0),
                    this._rawPrevTime > r && (o = 'onReverseComplete'))),
            (this._rawPrevTime = this._duration || !e || t || this._rawPrevTime === t ? t : r),
            (t = c + 1e-4)
          } else if (t < 1e-7) {
            if (
              ((this._totalTime = this._time = 0),
              (f !== 0
                  || (this._duration === 0
                    && this._rawPrevTime !== r
                    && (this._rawPrevTime > 0
                      || (t < 0 && this._rawPrevTime >= 0))))
                  && ((o = 'onReverseComplete'), (n = this._reversed)),
              t < 0)
            ) {
              (this._active = !1),
              this._timeline.autoRemoveChildren && this._reversed
                ? ((l = n = !0), (o = 'onReverseComplete'))
                : this._rawPrevTime >= 0 && this._first && (l = !0),
              (this._rawPrevTime = t)
            } else {
              if (
                ((this._rawPrevTime = this._duration || !e || t || this._rawPrevTime === t
                  ? t
                  : r),
                t === 0 && n)
              ) for (s = this._first; s && s._startTime === 0;) s._duration || (n = !1), (s = s._next);
              (t = 0), this._initted || (l = !0)
            }
          } else {
            if (this._hasPause && !this._forcingPlayhead && !e) {
              if (t >= f) {
                for (s = this._first; s && t >= s._startTime && !u;) {
                  s._duration
                      || s.data !== 'isPause'
                      || s.ratio
                      || (s._startTime === 0 && this._rawPrevTime === 0)
                      || (u = s),
                  (s = s._next)
                }
              } else {
                for (s = this._last; s && s._startTime >= t && !u;) {
                  s._duration
                      || (s.data === 'isPause' && s._rawPrevTime > 0 && (u = s)),
                  (s = s._prev)
                }
              }
              u
                  && ((this._time = t = u._startTime),
                  (this._totalTime = t
                    + this._cycle * (this._totalDuration + this._repeatDelay)))
            }
            this._totalTime = this._time = this._rawPrevTime = t
          }
          if ((this._time !== f && this._first) || i || l || u) {
            if (
              (this._initted || (this._initted = !0),
              this._active
                  || (!this._paused
                    && this._time !== f
                    && t > 0
                    && (this._active = !0)),
              f === 0
                  && this.vars.onStart
                  && this._time !== 0
                  && (e || this._callback('onStart')),
              this._time >= f)
            ) {
              for (
                s = this._first;
                s && ((a = s._next), !this._paused || d);

              ) {
                (s._active
                    || (s._startTime <= this._time && !s._paused && !s._gc))
                    && (u === s && this.pause(),
                    s._reversed
                      ? s.render(
                        (s._dirty ? s.totalDuration() : s._totalDuration)
                            - (t - s._startTime) * s._timeScale,
                        e,
                        i,
                      )
                      : s.render((t - s._startTime) * s._timeScale, e, i)),
                (s = a)
              }
            } else {
              for (
                s = this._last;
                s && ((a = s._prev), !this._paused || d);

              ) {
                if (
                  s._active
                    || (f >= s._startTime && !s._paused && !s._gc)
                ) {
                  if (u === s) {
                    for (u = s._prev; u && u.endTime() > this._time;) {
                      u.render(
                        u._reversed
                          ? u.totalDuration()
                                - (t - u._startTime) * u._timeScale
                          : (t - u._startTime) * u._timeScale,
                        e,
                        i,
                      ),
                      (u = u._prev)
                    }
                    (u = null), this.pause()
                  }
                  s._reversed
                    ? s.render(
                      (s._dirty ? s.totalDuration() : s._totalDuration)
                            - (t - s._startTime) * s._timeScale,
                      e,
                      i,
                    )
                    : s.render((t - s._startTime) * s._timeScale, e, i)
                }
                s = a
              }
            }
            this._onUpdate
                && (e || (h.length && _(), this._callback('onUpdate'))),
            o
                  && (this._gc
                    || ((p === this._startTime || m !== this._timeScale)
                      && (this._time === 0 || c >= this.totalDuration())
                      && (n
                        && (h.length && _(),
                        this._timeline.autoRemoveChildren
                          && this._enabled(!1, !1),
                        (this._active = !1)),
                      !e && this.vars[o] && this._callback(o))))
          }
        }),
        (d._hasPausedChild = function () {
          for (let t = this._first; t;) {
            if (t._paused || (t instanceof s && t._hasPausedChild())) return !0
            t = t._next
          }
          return !1
        }),
        (d.getChildren = function (t, e, s, r) {
          r = r || -9999999999
          for (var n = [], a = this._first, o = 0; a;) {
            r > a._startTime
                || (a instanceof i
                  ? e !== !1 && (n[o++] = a)
                  : (s !== !1 && (n[o++] = a),
                  t !== !1
                      && ((n = n.concat(a.getChildren(!0, e, s))),
                      (o = n.length)))),
            (a = a._next)
          }
          return n
        }),
        (d.getTweensOf = function (t, e) {
          let s
          let r
          const n = this._gc
          const a = []
          let o = 0
          for (
            n && this._enabled(!0, !0), s = i.getTweensOf(t), r = s.length;
            --r > -1;

          ) {
            (s[r].timeline === this || (e && this._contains(s[r])))
                && (a[o++] = s[r])
          }
          return n && this._enabled(!1, !0), a
        }),
        (d.recent = function () {
          return this._recent
        }),
        (d._contains = function (t) {
          for (let e = t.timeline; e;) {
            if (e === this) return !0
            e = e.timeline
          }
          return !1
        }),
        (d.shiftChildren = function (t, e, i) {
          i = i || 0
          for (var s, r = this._first, n = this._labels; r;) r._startTime >= i && (r._startTime += t), (r = r._next)
          if (e) for (s in n) n[s] >= i && (n[s] += t)
          return this._uncache(!0)
        }),
        (d._kill = function (t, e) {
          if (!t && !e) return this._enabled(!1, !1)
          for (
            var i = e ? this.getTweensOf(e) : this.getChildren(!0, !0, !1),
              s = i.length,
              r = !1;
            --s > -1;

          ) i[s]._kill(t, e) && (r = !0)
          return r
        }),
        (d.clear = function (t) {
          const e = this.getChildren(!1, !0, !0)
          let i = e.length
          for (this._time = this._totalTime = 0; --i > -1;) e[i]._enabled(!1, !1)
          return t !== !1 && (this._labels = {}), this._uncache(!0)
        }),
        (d.invalidate = function () {
          for (let e = this._first; e;) e.invalidate(), (e = e._next)
          return t.prototype.invalidate.call(this)
        }),
        (d._enabled = function (t, i) {
          if (t === this._gc) for (let s = this._first; s;) s._enabled(t, !0), (s = s._next)
          return e.prototype._enabled.call(this, t, i)
        }),
        (d.totalTime = function () {
          this._forcingPlayhead = !0
          const e = t.prototype.totalTime.apply(this, arguments)
          return (this._forcingPlayhead = !1), e
        }),
        (d.duration = function (t) {
          return arguments.length
            ? (this.duration() !== 0
                  && t !== 0
                  && this.timeScale(this._duration / t),
            this)
            : (this._dirty && this.totalDuration(), this._duration)
        }),
        (d.totalDuration = function (t) {
          if (!arguments.length) {
            if (this._dirty) {
              for (var e, i, s = 0, r = this._last, n = 999999999999; r;) {
                (e = r._prev),
                r._dirty && r.totalDuration(),
                r._startTime > n && this._sortChildren && !r._paused
                  ? this.add(r, r._startTime - r._delay)
                  : (n = r._startTime),
                r._startTime < 0
                      && !r._paused
                      && ((s -= r._startTime),
                      this._timeline.smoothChildTiming
                        && (this._startTime += r._startTime / this._timeScale),
                      this.shiftChildren(-r._startTime, !1, -9999999999),
                      (n = 0)),
                (i = r._startTime + r._totalDuration / r._timeScale),
                i > s && (s = i),
                (r = e)
              }
              (this._duration = this._totalDuration = s), (this._dirty = !1)
            }
            return this._totalDuration
          }
          return (
            this.totalDuration() !== 0
                && t !== 0
                && this.timeScale(this._totalDuration / t),
            this
          )
        }),
        (d.paused = function (e) {
          if (!e) {
            for (let i = this._first, s = this._time; i;) {
              i._startTime === s
                  && i.data === 'isPause'
                  && (i._rawPrevTime = 0),
              (i = i._next)
            }
          }
          return t.prototype.paused.apply(this, arguments)
        }),
        (d.usesFrames = function () {
          for (var e = this._timeline; e._timeline;) e = e._timeline
          return e === t._rootFramesTimeline
        }),
        (d.rawTime = function () {
          return this._paused
            ? this._totalTime
            : (this._timeline.rawTime() - this._startTime) * this._timeScale
        }),
        s
      )
    },
    !0,
  ),
  _gsScope._gsDefine(
    'TimelineMax',
    ['TimelineLite', 'TweenLite', 'easing.Ease'],
    (t, e, i) => {
      const s = function (e) {
        t.call(this, e),
        (this._repeat = this.vars.repeat || 0),
        (this._repeatDelay = this.vars.repeatDelay || 0),
        (this._cycle = 0),
        (this._yoyo = this.vars.yoyo === !0),
        (this._dirty = !0)
      }
      const r = 1e-10
      const n = e._internals
      const a = n.lazyTweens
      const o = n.lazyRender
      const l = new i(null, null, 1, 0)
      const h = (s.prototype = new t())
      return (
        (h.constructor = s),
        (h.kill()._gc = !1),
        (s.version = '1.18.0'),
        (h.invalidate = function () {
          return (
            (this._yoyo = this.vars.yoyo === !0),
            (this._repeat = this.vars.repeat || 0),
            (this._repeatDelay = this.vars.repeatDelay || 0),
            this._uncache(!0),
            t.prototype.invalidate.call(this)
          )
        }),
        (h.addCallback = function (t, i, s, r) {
          return this.add(e.delayedCall(0, t, s, r), i)
        }),
        (h.removeCallback = function (t, e) {
          if (t) {
            if (e == null) this._kill(null, t)
            else {
              for (
                let i = this.getTweensOf(t, !1),
                  s = i.length,
                  r = this._parseTimeOrLabel(e);
                --s > -1;

              ) i[s]._startTime === r && i[s]._enabled(!1, !1)
            }
          }
          return this
        }),
        (h.removePause = function (e) {
          return this.removeCallback(t._internals.pauseCallback, e)
        }),
        (h.tweenTo = function (t, i) {
          i = i || {}
          let s
          let r
          let n
          const a = {
            ease: l,
            useFrames: this.usesFrames(),
            immediateRender: !1,
          }
          for (r in i) a[r] = i[r]
          return (
            (a.time = this._parseTimeOrLabel(t)),
            (s = Math.abs(Number(a.time) - this._time) / this._timeScale
                || 0.001),
            (n = new e(this, s, a)),
            (a.onStart = function () {
              n.target.paused(!0),
              n.vars.time !== n.target.time()
                    && s === n.duration()
                    && n.duration(
                      Math.abs(n.vars.time - n.target.time())
                        / n.target._timeScale,
                    ),
              i.onStart && n._callback('onStart')
            }),
            n
          )
        }),
        (h.tweenFromTo = function (t, e, i) {
          (i = i || {}),
          (t = this._parseTimeOrLabel(t)),
          (i.startAt = {
            onComplete: this.seek,
            onCompleteParams: [t],
            callbackScope: this,
          }),
          (i.immediateRender = i.immediateRender !== !1)
          const s = this.tweenTo(e, i)
          return s.duration(
            Math.abs(s.vars.time - t) / this._timeScale || 0.001,
          )
        }),
        (h.render = function (t, e, i) {
          this._gc && this._enabled(!0, !1)
          let s
          let n
          let l
          let h
          let _
          let u
          let c
          const f = this._dirty ? this.totalDuration() : this._totalDuration
          const p = this._duration
          let m = this._time
          const d = this._totalTime
          const g = this._startTime
          const v = this._timeScale
          const y = this._rawPrevTime
          const T = this._paused
          const x = this._cycle
          if (t >= f) {
            this._locked
                || ((this._totalTime = f), (this._cycle = this._repeat)),
            this._reversed
                  || this._hasPausedChild()
                  || ((n = !0),
                  (h = 'onComplete'),
                  (_ = !!this._timeline.autoRemoveChildren),
                  this._duration === 0
                    && (t === 0 || y < 0 || y === r)
                    && y !== t
                    && this._first
                    && ((_ = !0), y > r && (h = 'onReverseComplete'))),
            (this._rawPrevTime = this._duration || !e || t || this._rawPrevTime === t ? t : r),
            this._yoyo && (1 & this._cycle) !== 0
              ? (this._time = t = 0)
              : ((this._time = p), (t = p + 1e-4))
          } else if (t < 1e-7) {
            if (
              (this._locked || (this._totalTime = this._cycle = 0),
              (this._time = 0),
              (m !== 0
                  || (p === 0
                    && y !== r
                    && (y > 0 || (t < 0 && y >= 0))
                    && !this._locked))
                  && ((h = 'onReverseComplete'), (n = this._reversed)),
              t < 0)
            ) {
              (this._active = !1),
              this._timeline.autoRemoveChildren && this._reversed
                ? ((_ = n = !0), (h = 'onReverseComplete'))
                : y >= 0 && this._first && (_ = !0),
              (this._rawPrevTime = t)
            } else {
              if (
                ((this._rawPrevTime = p || !e || t || this._rawPrevTime === t ? t : r),
                t === 0 && n)
              ) for (s = this._first; s && s._startTime === 0;) s._duration || (n = !1), (s = s._next);
              (t = 0), this._initted || (_ = !0)
            }
          } else if (
            (p === 0 && y < 0 && (_ = !0),
            (this._time = this._rawPrevTime = t),
            this._locked
                || ((this._totalTime = t),
                this._repeat !== 0
                  && ((u = p + this._repeatDelay),
                  (this._cycle = (this._totalTime / u) >> 0),
                  this._cycle !== 0
                    && this._cycle === this._totalTime / u
                    && this._cycle--,
                  (this._time = this._totalTime - this._cycle * u),
                  this._yoyo
                    && (1 & this._cycle) !== 0
                    && (this._time = p - this._time),
                  this._time > p
                    ? ((this._time = p), (t = p + 1e-4))
                    : this._time < 0
                      ? (this._time = t = 0)
                      : (t = this._time))),
            this._hasPause && !this._forcingPlayhead && !e)
          ) {
            if (((t = this._time), t >= m)) {
              for (s = this._first; s && t >= s._startTime && !c;) {
                s._duration
                    || s.data !== 'isPause'
                    || s.ratio
                    || (s._startTime === 0 && this._rawPrevTime === 0)
                    || (c = s),
                (s = s._next)
              }
            } else {
              for (s = this._last; s && s._startTime >= t && !c;) {
                s._duration
                    || (s.data === 'isPause' && s._rawPrevTime > 0 && (c = s)),
                (s = s._prev)
              }
            }
            c
                && ((this._time = t = c._startTime),
                (this._totalTime = t + this._cycle * (this._totalDuration + this._repeatDelay)))
          }
          if (this._cycle !== x && !this._locked) {
            let w = this._yoyo && (1 & x) !== 0
            const b = w === (this._yoyo && (1 & this._cycle) !== 0)
            const P = this._totalTime
            const k = this._cycle
            const S = this._rawPrevTime
            const R = this._time
            if (
              ((this._totalTime = x * p),
              x > this._cycle ? (w = !w) : (this._totalTime += p),
              (this._time = m),
              (this._rawPrevTime = p === 0 ? y - 1e-4 : y),
              (this._cycle = x),
              (this._locked = !0),
              (m = w ? 0 : p),
              this.render(m, e, p === 0),
              e
                  || this._gc
                  || (this.vars.onRepeat && this._callback('onRepeat')),
              b && ((m = w ? p + 1e-4 : -1e-4), this.render(m, !0, !1)),
              (this._locked = !1),
              this._paused && !T)
            ) return;
            (this._time = R),
            (this._totalTime = P),
            (this._cycle = k),
            (this._rawPrevTime = S)
          }
          if (!((this._time !== m && this._first) || i || _ || c)) {
            return (
              d !== this._totalTime
                  && this._onUpdate
                  && (e || this._callback('onUpdate')),
              void 0
            )
          }
          if (
            (this._initted || (this._initted = !0),
            this._active
                || (!this._paused
                  && this._totalTime !== d
                  && t > 0
                  && (this._active = !0)),
            d === 0
                && this.vars.onStart
                && this._totalTime !== 0
                && (e || this._callback('onStart')),
            this._time >= m)
          ) {
            for (s = this._first; s && ((l = s._next), !this._paused || T);) {
              (s._active
                  || (s._startTime <= this._time && !s._paused && !s._gc))
                  && (c === s && this.pause(),
                  s._reversed
                    ? s.render(
                      (s._dirty ? s.totalDuration() : s._totalDuration)
                          - (t - s._startTime) * s._timeScale,
                      e,
                      i,
                    )
                    : s.render((t - s._startTime) * s._timeScale, e, i)),
              (s = l)
            }
          } else {
            for (s = this._last; s && ((l = s._prev), !this._paused || T);) {
              if (s._active || (m >= s._startTime && !s._paused && !s._gc)) {
                if (c === s) {
                  for (c = s._prev; c && c.endTime() > this._time;) {
                    c.render(
                      c._reversed
                        ? c.totalDuration()
                              - (t - c._startTime) * c._timeScale
                        : (t - c._startTime) * c._timeScale,
                      e,
                      i,
                    ),
                    (c = c._prev)
                  }
                  (c = null), this.pause()
                }
                s._reversed
                  ? s.render(
                    (s._dirty ? s.totalDuration() : s._totalDuration)
                          - (t - s._startTime) * s._timeScale,
                    e,
                    i,
                  )
                  : s.render((t - s._startTime) * s._timeScale, e, i)
              }
              s = l
            }
          }
          this._onUpdate
              && (e || (a.length && o(), this._callback('onUpdate'))),
          h
                && (this._locked
                  || this._gc
                  || ((g === this._startTime || v !== this._timeScale)
                    && (this._time === 0 || f >= this.totalDuration())
                    && (n
                      && (a.length && o(),
                      this._timeline.autoRemoveChildren
                        && this._enabled(!1, !1),
                      (this._active = !1)),
                    !e && this.vars[h] && this._callback(h))))
        }),
        (h.getActive = function (t, e, i) {
          t == null && (t = !0), e == null && (e = !0), i == null && (i = !1)
          let s
          let r
          const n = []
          const a = this.getChildren(t, e, i)
          let o = 0
          const l = a.length
          for (s = 0; l > s; s++) (r = a[s]), r.isActive() && (n[o++] = r)
          return n
        }),
        (h.getLabelAfter = function (t) {
          t || (t !== 0 && (t = this._time))
          let e
          const i = this.getLabelsArray()
          const s = i.length
          for (e = 0; s > e; e++) if (i[e].time > t) return i[e].name
          return null
        }),
        (h.getLabelBefore = function (t) {
          t == null && (t = this._time)
          for (let e = this.getLabelsArray(), i = e.length; --i > -1;) if (t > e[i].time) return e[i].name
          return null
        }),
        (h.getLabelsArray = function () {
          let t
          const e = []
          let i = 0
          for (t in this._labels) e[i++] = { time: this._labels[t], name: t }
          return (
            e.sort((t, e) => t.time - e.time),
            e
          )
        }),
        (h.progress = function (t, e) {
          return arguments.length
            ? this.totalTime(
              this.duration()
                    * (this._yoyo && (1 & this._cycle) !== 0 ? 1 - t : t)
                    + this._cycle * (this._duration + this._repeatDelay),
              e,
            )
            : this._time / this.duration()
        }),
        (h.totalProgress = function (t, e) {
          return arguments.length
            ? this.totalTime(this.totalDuration() * t, e)
            : this._totalTime / this.totalDuration()
        }),
        (h.totalDuration = function (e) {
          return arguments.length
            ? this._repeat === -1
              ? this
              : this.duration(
                (e - this._repeat * this._repeatDelay) / (this._repeat + 1),
              )
            : (this._dirty
                  && (t.prototype.totalDuration.call(this),
                  (this._totalDuration = this._repeat === -1
                    ? 999999999999
                    : this._duration * (this._repeat + 1)
                        + this._repeatDelay * this._repeat)),
            this._totalDuration)
        }),
        (h.time = function (t, e) {
          return arguments.length
            ? (this._dirty && this.totalDuration(),
            t > this._duration && (t = this._duration),
            this._yoyo && (1 & this._cycle) !== 0
              ? (t = this._duration
                      - t
                      + this._cycle * (this._duration + this._repeatDelay))
              : this._repeat !== 0
                    && (t += this._cycle * (this._duration + this._repeatDelay)),
            this.totalTime(t, e))
            : this._time
        }),
        (h.repeat = function (t) {
          return arguments.length
            ? ((this._repeat = t), this._uncache(!0))
            : this._repeat
        }),
        (h.repeatDelay = function (t) {
          return arguments.length
            ? ((this._repeatDelay = t), this._uncache(!0))
            : this._repeatDelay
        }),
        (h.yoyo = function (t) {
          return arguments.length ? ((this._yoyo = t), this) : this._yoyo
        }),
        (h.currentLabel = function (t) {
          return arguments.length
            ? this.seek(t, !0)
            : this.getLabelBefore(this._time + 1e-8)
        }),
        s
      )
    },
    !0,
  ),
  (function () {
    const t = 180 / Math.PI
    const e = []
    const i = []
    const s = []
    const r = {}
    const n = _gsScope._gsDefine.globals
    const a = function (t, e, i, s) {
      (this.a = t),
      (this.b = e),
      (this.c = i),
      (this.d = s),
      (this.da = s - t),
      (this.ca = i - t),
      (this.ba = e - t)
    }
    const o = ',x,y,z,left,top,right,bottom,marginTop,marginLeft,marginRight,marginBottom,paddingLeft,paddingTop,paddingRight,paddingBottom,backgroundPosition,backgroundPosition_y,'
    const l = function (t, e, i, s) {
      const r = { a: t }
      const n = {}
      const a = {}
      const o = { c: s }
      const l = (t + e) / 2
      const h = (e + i) / 2
      const _ = (i + s) / 2
      const u = (l + h) / 2
      const c = (h + _) / 2
      const f = (c - u) / 8
      return (
        (r.b = l + (t - l) / 4),
        (n.b = u + f),
        (r.c = n.a = (r.b + n.b) / 2),
        (n.c = a.a = (u + c) / 2),
        (a.b = c - f),
        (o.b = _ + (s - _) / 4),
        (a.c = o.a = (a.b + o.b) / 2),
        [r, n, a, o]
      )
    }
    const h = function (t, r, n, a, o) {
      let h
      let _
      let u
      let c
      let f
      let p
      let m
      let d
      let g
      let v
      let y
      let T
      let x
      const w = t.length - 1
      let b = 0
      let P = t[0].a
      for (h = 0; w > h; h++) {
        (f = t[b]),
        (_ = f.a),
        (u = f.d),
        (c = t[b + 1].d),
        o
          ? ((y = e[h]),
          (T = i[h]),
          (x = (0.25 * (T + y) * r) / (a ? 0.5 : s[h] || 0.5)),
          (p = u - (u - _) * (a ? 0.5 * r : y !== 0 ? x / y : 0)),
          (m = u + (c - u) * (a ? 0.5 * r : T !== 0 ? x / T : 0)),
          (d = u - (p + (((m - p) * ((3 * y) / (y + T) + 0.5)) / 4 || 0))))
          : ((p = u - 0.5 * (u - _) * r),
          (m = u + 0.5 * (c - u) * r),
          (d = u - (p + m) / 2)),
        (p += d),
        (m += d),
        (f.c = g = p),
        (f.b = h !== 0 ? P : (P = f.a + 0.6 * (f.c - f.a))),
        (f.da = u - _),
        (f.ca = g - _),
        (f.ba = P - _),
        n
          ? ((v = l(_, P, g, u)),
          t.splice(b, 1, v[0], v[1], v[2], v[3]),
          (b += 4))
          : b++,
        (P = m)
      }
      (f = t[b]),
      (f.b = P),
      (f.c = P + 0.4 * (f.d - P)),
      (f.da = f.d - f.a),
      (f.ca = f.c - f.a),
      (f.ba = P - f.a),
      n
              && ((v = l(f.a, P, f.c, f.d)),
              t.splice(b, 1, v[0], v[1], v[2], v[3]))
    }
    const _ = function (t, s, r, n) {
      let o
      let l
      let h
      let _
      let u
      let c
      const f = []
      if (n) {
        for (t = [n].concat(t), l = t.length; --l > -1;) {
          typeof (c = t[l][s]) === 'string'
                && c.charAt(1) === '='
                && (t[l][s] = n[s] + Number(c.charAt(0) + c.substr(2)))
        }
      }
      if (((o = t.length - 2), o < 0)) return (f[0] = new a(t[0][s], 0, 0, t[o < -1 ? 0 : 1][s])), f
      for (l = 0; o > l; l++) {
        (h = t[l][s]),
        (_ = t[l + 1][s]),
        (f[l] = new a(h, 0, 0, _)),
        r
                && ((u = t[l + 2][s]),
                (e[l] = (e[l] || 0) + (_ - h) * (_ - h)),
                (i[l] = (i[l] || 0) + (u - _) * (u - _)))
      }
      return (f[l] = new a(t[l][s], 0, 0, t[l + 1][s])), f
    }
    const u = function (t, n, a, l, u, c) {
      let f
      let p
      let m
      let d
      let g
      let v
      let y
      let T
      const x = {}
      const w = []
      const b = c || t[0];
      (u = typeof u === 'string' ? `,${u},` : o), n == null && (n = 1)
      for (p in t[0]) w.push(p)
      if (t.length > 1) {
        for (T = t[t.length - 1], y = !0, f = w.length; --f > -1;) {
          if (((p = w[f]), Math.abs(b[p] - T[p]) > 0.05)) {
            y = !1
            break
          }
        }
        y
              && ((t = t.concat()),
              c && t.unshift(c),
              t.push(t[1]),
              (c = t[t.length - 3]))
      }
      for (e.length = i.length = s.length = 0, f = w.length; --f > -1;) {
        (p = w[f]),
        (r[p] = u.indexOf(`,${p},`) !== -1),
        (x[p] = _(t, p, r[p], c))
      }
      for (f = e.length; --f > -1;) (e[f] = Math.sqrt(e[f])), (i[f] = Math.sqrt(i[f]))
      if (!l) {
        for (f = w.length; --f > -1;) {
          if (r[p]) {
            for (m = x[w[f]], v = m.length - 1, d = 0; v > d; d++) {
              (g = m[d + 1].da / i[d] + m[d].da / e[d]),
              (s[d] = (s[d] || 0) + g * g)
            }
          }
        }
        for (f = s.length; --f > -1;) s[f] = Math.sqrt(s[f])
      }
      for (f = w.length, d = a ? 4 : 1; --f > -1;) {
        (p = w[f]),
        (m = x[p]),
        h(m, n, a, l, r[p]),
        y && (m.splice(0, d), m.splice(m.length - d, d))
      }
      return x
    }
    const c = function (t, e, i) {
      e = e || 'soft'
      let s
      let r
      let n
      let o
      let l
      let h
      let _
      let u
      let c
      let f
      let p
      const m = {}
      const d = e === 'cubic' ? 3 : 2
      const g = e === 'soft'
      const v = []
      if ((g && i && (t = [i].concat(t)), t == null || d + 1 > t.length)) throw 'invalid Bezier data'
      for (c in t[0]) v.push(c)
      for (h = v.length; --h > -1;) {
        for (
          c = v[h], m[c] = l = [], f = 0, u = t.length, _ = 0;
          u > _;
          _++
        ) {
          (s = i == null
            ? t[_][c]
            : typeof (p = t[_][c]) === 'string' && p.charAt(1) === '='
              ? i[c] + Number(p.charAt(0) + p.substr(2))
              : Number(p)),
          g && _ > 1 && u - 1 > _ && (l[f++] = (s + l[f - 2]) / 2),
          (l[f++] = s)
        }
        for (u = f - d + 1, f = 0, _ = 0; u > _; _ += d) {
          (s = l[_]),
          (r = l[_ + 1]),
          (n = l[_ + 2]),
          (o = d === 2 ? 0 : l[_ + 3]),
          (l[f++] = p = d === 3
            ? new a(s, r, n, o)
            : new a(s, (2 * r + s) / 3, (2 * r + n) / 3, n))
        }
        l.length = f
      }
      return m
    }
    const f = function (t, e, i) {
      for (
        var s, r, n, a, o, l, h, _, u, c, f, p = 1 / i, m = t.length;
        --m > -1;

      ) {
        for (
          c = t[m],
          n = c.a,
          a = c.d - n,
          o = c.c - n,
          l = c.b - n,
          s = r = 0,
          _ = 1;
          i >= _;
          _++
        ) {
          (h = p * _),
          (u = 1 - h),
          (s = r - (r = (h * h * a + 3 * u * (h * o + u * l)) * h)),
          (f = m * i + _ - 1),
          (e[f] = (e[f] || 0) + s * s)
        }
      }
    }
    const p = function (t, e) {
      e = e >> 0 || 6
      let i
      let s
      let r
      let n
      const a = []
      const o = []
      let l = 0
      let h = 0
      const _ = e - 1
      const u = []
      let c = []
      for (i in t) f(t[i], a, e)
      for (r = a.length, s = 0; r > s; s++) {
        (l += Math.sqrt(a[s])),
        (n = s % e),
        (c[n] = l),
        n === _
                && ((h += l),
                (n = (s / e) >> 0),
                (u[n] = c),
                (o[n] = h),
                (l = 0),
                (c = []))
      }
      return { length: h, lengths: o, segments: u }
    }
    const m = _gsScope._gsDefine.plugin({
      propName: 'bezier',
      priority: -1,
      version: '1.3.4',
      API: 2,
      global: !0,
      init(t, e, i) {
        (this._target = t),
        e instanceof Array && (e = { values: e }),
        (this._func = {}),
        (this._round = {}),
        (this._props = []),
        (this._timeRes = e.timeResolution == null ? 6 : parseInt(e.timeResolution, 10))
        let s
        let r
        let n
        let a
        let o
        const l = e.values || []
        const h = {}
        const _ = l[0]
        let f = e.autoRotate || i.vars.orientToBezier
        this._autoRotate = f
          ? f instanceof Array
            ? f
            : [['x', 'y', 'rotation', f === !0 ? 0 : Number(f) || 0]]
          : null
        for (s in _) this._props.push(s)
        for (n = this._props.length; --n > -1;) {
          (s = this._props[n]),
          this._overwriteProps.push(s),
          (r = this._func[s] = typeof t[s] === 'function'),
          (h[s] = r
            ? t[
              s.indexOf('set')
                      || typeof t[`get${s.substr(3)}`] !== 'function'
                ? s
                : `get${s.substr(3)}`
            ]()
            : parseFloat(t[s])),
          o || (h[s] !== l[0][s] && (o = h))
        }
        if (
          ((this._beziers = e.type !== 'cubic'
                && e.type !== 'quadratic'
                && e.type !== 'soft'
            ? u(
              l,
              isNaN(e.curviness) ? 1 : e.curviness,
              !1,
              e.type === 'thruBasic',
              e.correlate,
              o,
            )
            : c(l, e.type, h)),
          (this._segCount = this._beziers[s].length),
          this._timeRes)
        ) {
          const m = p(this._beziers, this._timeRes);
          (this._length = m.length),
          (this._lengths = m.lengths),
          (this._segments = m.segments),
          (this._l1 = this._li = this._s1 = this._si = 0),
          (this._l2 = this._lengths[0]),
          (this._curSeg = this._segments[0]),
          (this._s2 = this._curSeg[0]),
          (this._prec = 1 / this._curSeg.length)
        }
        if ((f = this._autoRotate)) {
          for (
            this._initialRotations = [],
            f[0] instanceof Array || (this._autoRotate = f = [f]),
            n = f.length;
            --n > -1;

          ) {
            for (a = 0; a < 3; a++) {
              (s = f[n][a]),
              (this._func[s] = typeof t[s] === 'function'
                ? t[
                  s.indexOf('set')
                            || typeof t[`get${s.substr(3)}`] !== 'function'
                    ? s
                    : `get${s.substr(3)}`
                ]
                : !1)
            }
            (s = f[n][2]),
            (this._initialRotations[n] = this._func[s]
              ? this._func[s].call(this._target)
              : this._target[s])
          }
        }
        return (this._startRatio = i.vars.runBackwards ? 1 : 0), !0
      },
      set(e) {
        let i
        let s
        let r
        let n
        let a
        let o
        let l
        let h
        let _
        let u
        const c = this._segCount
        const f = this._func
        const p = this._target
        const m = e !== this._startRatio
        if (this._timeRes) {
          if (
            ((_ = this._lengths),
            (u = this._curSeg),
            (e *= this._length),
            (r = this._li),
            e > this._l2 && c - 1 > r)
          ) {
            for (h = c - 1; h > r && e >= (this._l2 = _[++r]););
            (this._l1 = _[r - 1]),
            (this._li = r),
            (this._curSeg = u = this._segments[r]),
            (this._s2 = u[(this._s1 = this._si = 0)])
          } else if (this._l1 > e && r > 0) {
            for (; r > 0 && (this._l1 = _[--r]) >= e;);
            r === 0 && this._l1 > e ? (this._l1 = 0) : r++,
            (this._l2 = _[r]),
            (this._li = r),
            (this._curSeg = u = this._segments[r]),
            (this._s1 = u[(this._si = u.length - 1) - 1] || 0),
            (this._s2 = u[this._si])
          }
          if (
            ((i = r),
            (e -= this._l1),
            (r = this._si),
            e > this._s2 && u.length - 1 > r)
          ) {
            for (h = u.length - 1; h > r && e >= (this._s2 = u[++r]););
            (this._s1 = u[r - 1]), (this._si = r)
          } else if (this._s1 > e && r > 0) {
            for (; r > 0 && (this._s1 = u[--r]) >= e;);
            r === 0 && this._s1 > e ? (this._s1 = 0) : r++,
            (this._s2 = u[r]),
            (this._si = r)
          }
          o = (r + (e - this._s1) / (this._s2 - this._s1)) * this._prec
        } else {
          (i = e < 0 ? 0 : e >= 1 ? c - 1 : (c * e) >> 0),
          (o = (e - i * (1 / c)) * c)
        }
        for (s = 1 - o, r = this._props.length; --r > -1;) {
          (n = this._props[r]),
          (a = this._beziers[n][i]),
          (l = (o * o * a.da + 3 * s * (o * a.ca + s * a.ba)) * o + a.a),
          this._round[n] && (l = Math.round(l)),
          f[n] ? p[n](l) : (p[n] = l)
        }
        if (this._autoRotate) {
          let d
          let g
          let v
          let y
          let T
          let x
          let w
          const b = this._autoRotate
          for (r = b.length; --r > -1;) {
            (n = b[r][2]),
            (x = b[r][3] || 0),
            (w = b[r][4] === !0 ? 1 : t),
            (a = this._beziers[b[r][0]]),
            (d = this._beziers[b[r][1]]),
            a
                    && d
                    && ((a = a[i]),
                    (d = d[i]),
                    (g = a.a + (a.b - a.a) * o),
                    (y = a.b + (a.c - a.b) * o),
                    (g += (y - g) * o),
                    (y += (a.c + (a.d - a.c) * o - y) * o),
                    (v = d.a + (d.b - d.a) * o),
                    (T = d.b + (d.c - d.b) * o),
                    (v += (T - v) * o),
                    (T += (d.c + (d.d - d.c) * o - T) * o),
                    (l = m
                      ? Math.atan2(T - v, y - g) * w + x
                      : this._initialRotations[r]),
                    f[n] ? p[n](l) : (p[n] = l))
          }
        }
      },
    })
    const d = m.prototype;
    (m.bezierThrough = u),
    (m.cubicToQuadratic = l),
    (m._autoCSS = !0),
    (m.quadraticToCubic = function (t, e, i) {
      return new a(t, (2 * e + t) / 3, (2 * e + i) / 3, i)
    }),
    (m._cssRegister = function () {
      const t = n.CSSPlugin
      if (t) {
        const e = t._internals
        const i = e._parseToProxy
        const s = e._setPluginRatio
        const r = e.CSSPropTween
        e._registerComplexSpecialProp('bezier', {
          parser(t, e, n, a, o, l) {
            e instanceof Array && (e = { values: e }), (l = new m())
            let h
            let _
            let u
            const c = e.values
            const f = c.length - 1
            const p = []
            const d = {}
            if (f < 0) return o
            for (h = 0; f >= h; h++) (u = i(t, c[h], a, o, l, f !== h)), (p[h] = u.end)
            for (_ in e) d[_] = e[_]
            return (
              (d.values = p),
              (o = new r(t, 'bezier', 0, 0, u.pt, 2)),
              (o.data = u),
              (o.plugin = l),
              (o.setRatio = s),
              d.autoRotate === 0 && (d.autoRotate = !0),
              !d.autoRotate
                    || d.autoRotate instanceof Array
                    || ((h = d.autoRotate === !0 ? 0 : Number(d.autoRotate)),
                    (d.autoRotate = u.end.left != null
                      ? [['left', 'top', 'rotation', h, !1]]
                      : u.end.x != null
                        ? [['x', 'y', 'rotation', h, !1]]
                        : !1)),
              d.autoRotate
                    && (a._transform || a._enableTransforms(!1),
                    (u.autoRotate = a._target._gsTransform)),
              l._onInitTween(u.proxy, d, a._tween),
              o
            )
          },
        })
      }
    }),
    (d._roundProps = function (t, e) {
      for (let i = this._overwriteProps, s = i.length; --s > -1;) (t[i[s]] || t.bezier || t.bezierThrough) && (this._round[i[s]] = e)
    }),
    (d._kill = function (t) {
      let e
      let i
      const s = this._props
      for (e in this._beziers) {
        if (e in t) {
          for (
            delete this._beziers[e], delete this._func[e], i = s.length;
            --i > -1;

          ) s[i] === e && s.splice(i, 1)
        }
      }
      return this._super._kill.call(this, t)
    })
  }()),
  _gsScope._gsDefine(
    'plugins.CSSPlugin',
    ['plugins.TweenPlugin', 'TweenLite'],
    (t, e) => {
      let i
      let s
      let r
      let n
      var a = function () {
        t.call(this, 'css'),
        (this._overwriteProps.length = 0),
        (this.setRatio = a.prototype.setRatio)
      }
      const o = _gsScope._gsDefine.globals
      const l = {}
      let h = (a.prototype = new t('css'));
      (h.constructor = a),
      (a.version = '1.18.0'),
      (a.API = 2),
      (a.defaultTransformPerspective = 0),
      (a.defaultSkewType = 'compensated'),
      (a.defaultSmoothOrigin = !0),
      (h = 'px'),
      (a.suffixMap = {
        top: h,
        right: h,
        bottom: h,
        left: h,
        width: h,
        height: h,
        fontSize: h,
        padding: h,
        margin: h,
        perspective: h,
        lineHeight: '',
      })
      let _
      let u
      let c
      let f
      let p
      let m
      const d = /(?:\d|\-\d|\.\d|\-\.\d)+/g
      const g = /(?:\d|\-\d|\.\d|\-\.\d|\+=\d|\-=\d|\+=.\d|\-=\.\d)+/g
      const v = /(?:\+=|\-=|\-|\b)[\d\-\.]+[a-zA-Z0-9]*(?:%|\b)/gi
      const y = /(?![+-]?\d*\.?\d+|[+-]|e[+-]\d+)[^0-9]/g
      const T = /(?:\d|\-|\+|=|#|\.)*/g
      const x = /opacity *= *([^)]*)/i
      const w = /opacity:([^;]*)/i
      const b = /alpha\(opacity *=.+?\)/i
      const P = /^(rgb|hsl)/
      const k = /([A-Z])/g
      const S = /-([a-z])/gi
      const R = /(^(?:url\(\"|url\())|(?:(\"\))$|\)$)/gi
      const O = function (t, e) {
        return e.toUpperCase()
      }
      const A = /(?:Left|Right|Width)/i
      const C = /(M11|M12|M21|M22)=[\d\-\.e]+/gi
      const D = /progid\:DXImageTransform\.Microsoft\.Matrix\(.+?\)/i
      const M = /,(?=[^\)]*(?:\(|$))/gi
      const z = Math.PI / 180
      const F = 180 / Math.PI
      let I = {}
      const E = document
      const N = function (t) {
        return E.createElementNS
          ? E.createElementNS('http://www.w3.org/1999/xhtml', t)
          : E.createElement(t)
      }
      const L = N('div')
      const X = N('img')
      const B = (a._internals = { _specialProps: l })
      const j = navigator.userAgent
      const Y = (function () {
        const t = j.indexOf('Android')
        const e = N('a')
        return (
          (c = j.indexOf('Safari') !== -1
                && j.indexOf('Chrome') === -1
                && (t === -1 || Number(j.substr(t + 8, 1)) > 3)),
          (p = c && Number(j.substr(j.indexOf('Version/') + 8, 1)) < 6),
          (f = j.indexOf('Firefox') !== -1),
          (/MSIE ([0-9]{1,}[\.0-9]{0,})/.exec(j)
                || /Trident\/.*rv:([0-9]{1,}[\.0-9]{0,})/.exec(j))
                && (m = parseFloat(RegExp.$1)),
          e
            ? ((e.style.cssText = 'top:1px;opacity:.55;'),
            /^0.55/.test(e.style.opacity))
            : !1
        )
      }())
      const U = function (t) {
        return x.test(
          typeof t === 'string'
            ? t
            : (t.currentStyle ? t.currentStyle.filter : t.style.filter)
                    || '',
        )
          ? parseFloat(RegExp.$1) / 100
          : 1
      }
      const q = function (t) {
        window.console && console.log(t)
      }
      let V = ''
      let G = ''
      const W = function (t, e) {
        e = e || L
        let i
        let s
        const r = e.style
        if (void 0 !== r[t]) return t
        for (
          t = t.charAt(0).toUpperCase() + t.substr(1),
          i = ['O', 'Moz', 'ms', 'Ms', 'Webkit'],
          s = 5;
          --s > -1 && void 0 === r[i[s] + t];

        );
        return s >= 0
          ? ((G = s === 3 ? 'ms' : i[s]),
          (V = `-${G.toLowerCase()}-`),
          G + t)
          : null
      }
      const Z = E.defaultView ? E.defaultView.getComputedStyle : function () {}
      const Q = (a.getStyle = function (t, e, i, s, r) {
        let n
        return Y || e !== 'opacity'
          ? (!s && t.style[e]
            ? (n = t.style[e])
            : (i = i || Z(t))
              ? (n = i[e]
                      || i.getPropertyValue(e)
                      || i.getPropertyValue(e.replace(k, '-$1').toLowerCase()))
              : t.currentStyle && (n = t.currentStyle[e]),
          r == null
                || (n && n !== 'none' && n !== 'auto' && n !== 'auto auto')
            ? n
            : r)
          : U(t)
      })
      var $ = (B.convertToPixels = function (t, i, s, r, n) {
        if (r === 'px' || !r) return s
        if (r === 'auto' || !s) return 0
        let o
        let l
        let h
        const _ = A.test(i)
        let u = t
        const c = L.style
        const f = s < 0
        if ((f && (s = -s), r === '%' && i.indexOf('border') !== -1)) o = (s / 100) * (_ ? t.clientWidth : t.clientHeight)
        else {
          if (
            ((c.cssText = `border:0 solid red;position:${
              Q(t, 'position')
            };line-height:0;`),
            r !== '%'
                  && u.appendChild
                  && r.charAt(0) !== 'v'
                  && r !== 'rem')
          ) c[_ ? 'borderLeftWidth' : 'borderTopWidth'] = s + r
          else {
            if (
              ((u = t.parentNode || E.body),
              (l = u._gsCache),
              (h = e.ticker.frame),
              l && _ && l.time === h)
            ) return (l.width * s) / 100
            c[_ ? 'width' : 'height'] = s + r
          }
          u.appendChild(L),
          (o = parseFloat(L[_ ? 'offsetWidth' : 'offsetHeight'])),
          u.removeChild(L),
          _
                  && r === '%'
                  && a.cacheWidths !== !1
                  && ((l = u._gsCache = u._gsCache || {}),
                  (l.time = h),
                  (l.width = 100 * (o / s))),
          o !== 0 || n || (o = $(t, i, s, r, !0))
        }
        return f ? -o : o
      })
      const H = (B.calculateOffset = function (t, e, i) {
        if (Q(t, 'position', i) !== 'absolute') return 0
        const s = e === 'left' ? 'Left' : 'Top'
        const r = Q(t, `margin${s}`, i)
        return (
          t[`offset${s}`] - ($(t, e, parseFloat(r), r.replace(T, '')) || 0)
        )
      })
      const K = function (t, e) {
        let i
        let s
        let r
        const n = {}
        if ((e = e || Z(t, null))) {
          if ((i = e.length)) {
            for (; --i > -1;) {
              (r = e[i]),
              (r.indexOf('-transform') === -1 || ke === r)
                      && (n[r.replace(S, O)] = e.getPropertyValue(r))
            }
          } else for (i in e) (i.indexOf('Transform') === -1 || Pe === i) && (n[i] = e[i])
        } else if ((e = t.currentStyle || t.style)) {
          for (i in e) {
            typeof i === 'string'
                  && void 0 === n[i]
                  && (n[i.replace(S, O)] = e[i])
          }
        }
        return (
          Y || (n.opacity = U(t)),
          (s = Ne(t, e, !1)),
          (n.rotation = s.rotation),
          (n.skewX = s.skewX),
          (n.scaleX = s.scaleX),
          (n.scaleY = s.scaleY),
          (n.x = s.x),
          (n.y = s.y),
          Re
                && ((n.z = s.z),
                (n.rotationX = s.rotationX),
                (n.rotationY = s.rotationY),
                (n.scaleZ = s.scaleZ)),
          n.filters && delete n.filters,
          n
        )
      }
      const J = function (t, e, i, s, r) {
        let n
        let a
        let o
        const l = {}
        const h = t.style
        for (a in i) {
          a !== 'cssText'
                && a !== 'length'
                && isNaN(a)
                && (e[a] !== (n = i[a]) || (r && r[a]))
                && a.indexOf('Origin') === -1
                && (typeof n === 'number' || typeof n === 'string')
                && ((l[a] = n !== 'auto' || (a !== 'left' && a !== 'top')
                  ? (n !== '' && n !== 'auto' && n !== 'none')
                      || typeof e[a] !== 'string'
                      || e[a].replace(y, '') === ''
                    ? n
                    : 0
                  : H(t, a)),
                void 0 !== h[a] && (o = new pe(h, a, h[a], o)))
        }
        if (s) for (a in s) a !== 'className' && (l[a] = s[a])
        return { difs: l, firstMPT: o }
      }
      const te = { width: ['Left', 'Right'], height: ['Top', 'Bottom'] }
      const ee = ['marginLeft', 'marginRight', 'marginTop', 'marginBottom']
      const ie = function (t, e, i) {
        let s = parseFloat(e === 'width' ? t.offsetWidth : t.offsetHeight)
        const r = te[e]
        let n = r.length
        for (i = i || Z(t, null); --n > -1;) {
          (s -= parseFloat(Q(t, `padding${r[n]}`, i, !0)) || 0),
          (s -= parseFloat(Q(t, `border${r[n]}Width`, i, !0)) || 0)
        }
        return s
      }
      const se = function (t, e) {
        if (t === 'contain' || t === 'auto' || t === 'auto auto') return `${t} `;
        (t == null || t === '') && (t = '0 0')
        const i = t.split(' ')
        let s = t.indexOf('left') !== -1
          ? '0%'
          : t.indexOf('right') !== -1
            ? '100%'
            : i[0]
        let r = t.indexOf('top') !== -1
          ? '0%'
          : t.indexOf('bottom') !== -1
            ? '100%'
            : i[1]
        return (
          r == null
            ? (r = s === 'center' ? '50%' : '0')
            : r === 'center' && (r = '50%'),
          (s === 'center'
                || (isNaN(parseFloat(s)) && (`${s}`).indexOf('=') === -1))
                && (s = '50%'),
          (t = `${s} ${r}${i.length > 2 ? ` ${i[2]}` : ''}`),
          e
                && ((e.oxp = s.indexOf('%') !== -1),
                (e.oyp = r.indexOf('%') !== -1),
                (e.oxr = s.charAt(1) === '='),
                (e.oyr = r.charAt(1) === '='),
                (e.ox = parseFloat(s.replace(y, ''))),
                (e.oy = parseFloat(r.replace(y, ''))),
                (e.v = t)),
          e || t
        )
      }
      const re = function (t, e) {
        return typeof t === 'string' && t.charAt(1) === '='
          ? parseInt(`${t.charAt(0)}1`, 10) * parseFloat(t.substr(2))
          : parseFloat(t) - parseFloat(e)
      }
      const ne = function (t, e) {
        return t == null
          ? e
          : typeof t === 'string' && t.charAt(1) === '='
            ? parseInt(`${t.charAt(0)}1`, 10) * parseFloat(t.substr(2)) + e
            : parseFloat(t)
      }
      const ae = function (t, e, i, s) {
        let r
        let n
        let a
        let o
        let l
        const h = 1e-6
        return (
          t == null
            ? (o = e)
            : typeof t === 'number'
              ? (o = t)
              : ((r = 360),
              (n = t.split('_')),
              (l = t.charAt(1) === '='),
              (a = (l
                ? parseInt(`${t.charAt(0)}1`, 10)
                        * parseFloat(n[0].substr(2))
                : parseFloat(n[0]))
                      * (t.indexOf('rad') === -1 ? 1 : F)
                    - (l ? 0 : e)),
              n.length
                    && (s && (s[i] = e + a),
                    t.indexOf('short') !== -1
                      && ((a %= r),
                      a !== a % (r / 2) && (a = a < 0 ? a + r : a - r)),
                    t.indexOf('_cw') !== -1 && a < 0
                      ? (a = ((a + 9999999999 * r) % r) - (0 | (a / r)) * r)
                      : t.indexOf('ccw') !== -1
                        && a > 0
                        && (a = ((a - 9999999999 * r) % r) - (0 | (a / r)) * r)),
              (o = e + a)),
          h > o && o > -h && (o = 0),
          o
        )
      }
      const oe = {
        aqua: [0, 255, 255],
        lime: [0, 255, 0],
        silver: [192, 192, 192],
        black: [0, 0, 0],
        maroon: [128, 0, 0],
        teal: [0, 128, 128],
        blue: [0, 0, 255],
        navy: [0, 0, 128],
        white: [255, 255, 255],
        fuchsia: [255, 0, 255],
        olive: [128, 128, 0],
        yellow: [255, 255, 0],
        orange: [255, 165, 0],
        gray: [128, 128, 128],
        purple: [128, 0, 128],
        green: [0, 128, 0],
        red: [255, 0, 0],
        pink: [255, 192, 203],
        cyan: [0, 255, 255],
        transparent: [255, 255, 255, 0],
      }
      const le = function (t, e, i) {
        return (
          (t = t < 0 ? t + 1 : t > 1 ? t - 1 : t),
          0
                | (255
                  * (6 * t < 1
                    ? e + 6 * (i - e) * t
                    : t < 0.5
                      ? i
                      : 3 * t < 2
                        ? e + 6 * (i - e) * (2 / 3 - t)
                        : e)
                  + 0.5)
        )
      }
      const he = (a.parseColor = function (t, e) {
        let i; let s; let r; let n; let a; let o; let l; let h; let _; let u; let
          c
        if (t) {
          if (typeof t === 'number') i = [t >> 16, 255 & (t >> 8), 255 & t]
          else {
            if (
              (t.charAt(t.length - 1) === ','
                    && (t = t.substr(0, t.length - 1)),
              oe[t])
            ) i = oe[t]
            else if (t.charAt(0) === '#') {
              t.length === 4
                    && ((s = t.charAt(1)),
                    (r = t.charAt(2)),
                    (n = t.charAt(3)),
                    (t = `#${s}${s}${r}${r}${n}${n}`)),
              (t = parseInt(t.substr(1), 16)),
              (i = [t >> 16, 255 & (t >> 8), 255 & t])
            } else if (t.substr(0, 3) === 'hsl') {
              if (((i = c = t.match(d)), e)) {
                if (t.indexOf('=') !== -1) return t.match(g)
              } else {
                (a = (Number(i[0]) % 360) / 360),
                (o = Number(i[1]) / 100),
                (l = Number(i[2]) / 100),
                (r = l <= 0.5 ? l * (o + 1) : l + o - l * o),
                (s = 2 * l - r),
                i.length > 3 && (i[3] = Number(t[3])),
                (i[0] = le(a + 1 / 3, s, r)),
                (i[1] = le(a, s, r)),
                (i[2] = le(a - 1 / 3, s, r))
              }
            } else i = t.match(d) || oe.transparent;
            (i[0] = Number(i[0])),
            (i[1] = Number(i[1])),
            (i[2] = Number(i[2])),
            i.length > 3 && (i[3] = Number(i[3]))
          }
        } else i = oe.black
        return (
          e
                && !c
                && ((s = i[0] / 255),
                (r = i[1] / 255),
                (n = i[2] / 255),
                (h = Math.max(s, r, n)),
                (_ = Math.min(s, r, n)),
                (l = (h + _) / 2),
                h === _
                  ? (a = o = 0)
                  : ((u = h - _),
                  (o = l > 0.5 ? u / (2 - h - _) : u / (h + _)),
                  (a = h === s
                    ? (r - n) / u + (n > r ? 6 : 0)
                    : h === r
                      ? (n - s) / u + 2
                      : (s - r) / u + 4),
                  (a *= 60)),
                (i[0] = 0 | (a + 0.5)),
                (i[1] = 0 | (100 * o + 0.5)),
                (i[2] = 0 | (100 * l + 0.5))),
          i
        )
      })
      const _e = function (t, e) {
        let i
        let s
        let r
        const n = t.match(ue) || []
        let a = 0
        let o = n.length ? '' : t
        for (i = 0; n.length > i; i++) {
          (s = n[i]),
          (r = t.substr(a, t.indexOf(s, a) - a)),
          (a += r.length + s.length),
          (s = he(s, e)),
          s.length === 3 && s.push(1),
          (o
                  += `${r
                  + (e
                    ? `hsla(${s[0]},${s[1]}%,${s[2]}%,${s[3]}`
                    : `rgba(${s.join(',')}`)
            })`)
        }
        return o
      }
      var ue = '(?:\\b(?:(?:rgb|rgba|hsl|hsla)\\(.+?\\))|\\B#.+?\\b'
      for (h in oe) ue += `|${h}\\b`;
      (ue = RegExp(`${ue})`, 'gi')),
      (a.colorStringFilter = function (t) {
        let e
        const i = t[0] + t[1];
        (ue.lastIndex = 0),
        ue.test(i)
                && ((e = i.indexOf('hsl(') !== -1 || i.indexOf('hsla(') !== -1),
                (t[0] = _e(t[0], e)),
                (t[1] = _e(t[1], e)))
      }),
      e.defaultStringFilter
            || (e.defaultStringFilter = a.colorStringFilter)
      const ce = function (t, e, i, s) {
        if (t == null) {
          return function (t) {
            return t
          }
        }
        let r
        const n = e ? (t.match(ue) || [''])[0] : ''
        const a = t.split(n).join('').match(v) || []
        const o = t.substr(0, t.indexOf(a[0]))
        const l = t.charAt(t.length - 1) === ')' ? ')' : ''
        const h = t.indexOf(' ') !== -1 ? ' ' : ','
        const _ = a.length
        const u = _ > 0 ? a[0].replace(d, '') : ''
        return _
          ? (r = e
            ? function (t) {
              let e; let c; let f; let
                p
              if (typeof t === 'number') t += u
              else if (s && M.test(t)) {
                for (
                  p = t.replace(M, '|').split('|'), f = 0;
                  p.length > f;
                  f++
                ) p[f] = r(p[f])
                return p.join(',')
              }
              if (
                ((e = (t.match(ue) || [n])[0]),
                (c = t.split(e).join('').match(v) || []),
                (f = c.length),
                _ > f--)
              ) for (; _ > ++f;) c[f] = i ? c[0 | ((f - 1) / 2)] : a[f]
              return (
                o
                        + c.join(h)
                        + h
                        + e
                        + l
                        + (t.indexOf('inset') !== -1 ? ' inset' : '')
              )
            }
            : function (t) {
              let e; let n; let
                c
              if (typeof t === 'number') t += u
              else if (s && M.test(t)) {
                for (
                  n = t.replace(M, '|').split('|'), c = 0;
                  n.length > c;
                  c++
                ) n[c] = r(n[c])
                return n.join(',')
              }
              if (((e = t.match(v) || []), (c = e.length), _ > c--)) for (; _ > ++c;) e[c] = i ? e[0 | ((c - 1) / 2)] : a[c]
              return o + e.join(h) + l
            })
          : function (t) {
            return t
          }
      }
      const fe = function (t) {
        return (
          (t = t.split(',')),
          function (e, i, s, r, n, a, o) {
            let l
            const h = (`${i}`).split(' ')
            for (o = {}, l = 0; l < 4; l++) o[t[l]] = h[l] = h[l] || h[((l - 1) / 2) >> 0]
            return r.parse(e, o, n, a)
          }
        )
      }
      var pe = ((B._setPluginRatio = function (t) {
        this.plugin.setRatio(t)
        for (
          var e,
            i,
            s,
            r,
            n = this.data,
            a = n.proxy,
            o = n.firstMPT,
            l = 1e-6;
          o;

        ) {
          (e = a[o.v]),
          o.r ? (e = Math.round(e)) : l > e && e > -l && (e = 0),
          (o.t[o.p] = e),
          (o = o._next)
        }
        if (
          (n.autoRotate && (n.autoRotate.rotation = a.rotation), t === 1)
        ) {
          for (o = n.firstMPT; o;) {
            if (((i = o.t), i.type)) {
              if (i.type === 1) {
                for (r = i.xs0 + i.s + i.xs1, s = 1; i.l > s; s++) r += i[`xn${s}`] + i[`xs${s + 1}`]
                i.e = r
              }
            } else i.e = i.s + i.xs0
            o = o._next
          }
        }
      }),
      function (t, e, i, s, r) {
        (this.t = t),
        (this.p = e),
        (this.v = i),
        (this.r = r),
        s && ((s._prev = this), (this._next = s))
      })
      var me = ((B._parseToProxy = function (t, e, i, s, r, n) {
        let a
        let o
        let l
        let h
        let _
        const u = s
        const c = {}
        const f = {}
        const p = i._transform
        const m = I
        for (
          i._transform = null,
          I = e,
          s = _ = i.parse(t, e, s, r),
          I = m,
          n
                    && ((i._transform = p),
                    u && ((u._prev = null), u._prev && (u._prev._next = null)));
          s && s !== u;

        ) {
          if (
            s.type <= 1
                  && ((o = s.p),
                  (f[o] = s.s + s.c),
                  (c[o] = s.s),
                  n || ((h = new pe(s, 's', o, h, s.r)), (s.c = 0)),
                  s.type === 1)
          ) {
            for (a = s.l; --a > 0;) {
              (l = `xn${a}`),
              (o = `${s.p}_${l}`),
              (f[o] = s.data[l]),
              (c[o] = s[l]),
              n || (h = new pe(s, l, o, h, s.rxp[l]))
            }
          }
          s = s._next
        }
        return {
          proxy: c, end: f, firstMPT: h, pt: _,
        }
      }),
      (B.CSSPropTween = function (t, e, s, r, a, o, l, h, _, u, c) {
        (this.t = t),
        (this.p = e),
        (this.s = s),
        (this.c = r),
        (this.n = l || e),
        t instanceof me || n.push(this.n),
        (this.r = h),
        (this.type = o || 0),
        _ && ((this.pr = _), (i = !0)),
        (this.b = void 0 === u ? s : u),
        (this.e = void 0 === c ? s + r : c),
        a && ((this._next = a), (a._prev = this))
      }))
      const de = function (t, e, i, s, r, n) {
        const a = new me(t, e, i, s - i, r, -1, n)
        return (a.b = i), (a.e = a.xs0 = s), a
      }
      const ge = (a.parseComplex = function (t, e, i, s, r, n, a, o, l, h) {
        (i = i || n || ''),
        (a = new me(t, e, 0, 0, a, h ? 2 : 1, null, !1, o, i, s)),
        (s += '')
        let u
        let c
        let f
        let p
        let m
        let v
        let y
        let T
        let x
        let w
        let b
        let P
        let k
        let S = i.split(', ').join(',').split(' ')
        let R = s.split(', ').join(',').split(' ')
        let O = S.length
        const A = _ !== !1
        for (
          (s.indexOf(',') !== -1 || i.indexOf(',') !== -1)
                && ((S = S.join(' ').replace(M, ', ').split(' ')),
                (R = R.join(' ').replace(M, ', ').split(' ')),
                (O = S.length)),
          O !== R.length && ((S = (n || '').split(' ')), (O = S.length)),
          a.plugin = l,
          a.setRatio = h,
          ue.lastIndex = 0,
          u = 0;
          O > u;
          u++
        ) {
          if (((p = S[u]), (m = R[u]), (T = parseFloat(p)), T || T === 0)) {
            a.appendXtra(
              '',
              T,
              re(m, T),
              m.replace(g, ''),
              A && m.indexOf('px') !== -1,
              !0,
            )
          } else if (r && ue.test(p)) {
            (P = m.charAt(m.length - 1) === ',' ? '),' : ')'),
            (k = m.indexOf('hsl') !== -1 && Y),
            (p = he(p, k)),
            (m = he(m, k)),
            (x = p.length + m.length > 6),
            x && !Y && m[3] === 0
              ? ((a[`xs${a.l}`] += a.l ? ' transparent' : 'transparent'),
              (a.e = a.e.split(R[u]).join('transparent')))
              : (Y || (x = !1),
              k
                ? a
                  .appendXtra(
                    x ? 'hsla(' : 'hsl(',
                    p[0],
                    re(m[0], p[0]),
                    ',',
                    !1,
                    !0,
                  )
                  .appendXtra('', p[1], re(m[1], p[1]), '%,', !1)
                  .appendXtra(
                    '',
                    p[2],
                    re(m[2], p[2]),
                    x ? '%,' : `%${P}`,
                    !1,
                  )
                : a
                  .appendXtra(
                    x ? 'rgba(' : 'rgb(',
                    p[0],
                    m[0] - p[0],
                    ',',
                    !0,
                    !0,
                  )
                  .appendXtra('', p[1], m[1] - p[1], ',', !0)
                  .appendXtra('', p[2], m[2] - p[2], x ? ',' : P, !0),
              x
                        && ((p = p.length < 4 ? 1 : p[3]),
                        a.appendXtra(
                          '',
                          p,
                          (m.length < 4 ? 1 : m[3]) - p,
                          P,
                          !1,
                        ))),
            (ue.lastIndex = 0)
          } else if ((v = p.match(d))) {
            if (((y = m.match(g)), !y || y.length !== v.length)) return a
            for (f = 0, c = 0; v.length > c; c++) {
              (b = v[c]),
              (w = p.indexOf(b, f)),
              a.appendXtra(
                p.substr(f, w - f),
                Number(b),
                re(y[c], b),
                '',
                A && p.substr(w + b.length, 2) === 'px',
                c === 0,
              ),
              (f = w + b.length)
            }
            a[`xs${a.l}`] += p.substr(f)
          } else a[`xs${a.l}`] += a.l ? ` ${p}` : p
        }
        if (s.indexOf('=') !== -1 && a.data) {
          for (P = a.xs0 + a.data.s, u = 1; a.l > u; u++) P += a[`xs${u}`] + a.data[`xn${u}`]
          a.e = P + a[`xs${u}`]
        }
        return a.l || ((a.type = -1), (a.xs0 = a.e)), a.xfirst || a
      })
      let ve = 9
      for (h = me.prototype, h.l = h.pr = 0; --ve > 0;) (h[`xn${ve}`] = 0), (h[`xs${ve}`] = '');
      (h.xs0 = ''),
      (h._next = h._prev = h.xfirst = h.data = h.plugin = h.setRatio = h.rxp = null),
      (h.appendXtra = function (t, e, i, s, r, n) {
        const a = this
        const o = a.l
        return (
          (a[`xs${o}`] += n && o ? ` ${t}` : t || ''),
          i || o === 0 || a.plugin
            ? (a.l++,
            (a.type = a.setRatio ? 2 : 1),
            (a[`xs${a.l}`] = s || ''),
            o > 0
              ? ((a.data[`xn${o}`] = e + i),
              (a.rxp[`xn${o}`] = r),
              (a[`xn${o}`] = e),
              a.plugin
                        || ((a.xfirst = new me(
                          a,
                          `xn${o}`,
                          e,
                          i,
                          a.xfirst || a,
                          0,
                          a.n,
                          r,
                          a.pr,
                        )),
                        (a.xfirst.xs0 = 0)),
              a)
              : ((a.data = { s: e + i }),
              (a.rxp = {}),
              (a.s = e),
              (a.c = i),
              (a.r = r),
              a))
            : ((a[`xs${o}`] += e + (s || '')), a)
        )
      })
      const ye = function (t, e) {
        (e = e || {}),
        (this.p = e.prefix ? W(t) || t : t),
        (l[t] = l[this.p] = this),
        (this.format = e.formatter
                || ce(e.defaultValue, e.color, e.collapsible, e.multi)),
        e.parser && (this.parse = e.parser),
        (this.clrs = e.color),
        (this.multi = e.multi),
        (this.keyword = e.keyword),
        (this.dflt = e.defaultValue),
        (this.pr = e.priority || 0)
      }
      const Te = (B._registerComplexSpecialProp = function (t, e, i) {
        typeof e !== 'object' && (e = { parser: i })
        let s
        let r
        const n = t.split(',')
        const a = e.defaultValue
        for (i = i || [a], s = 0; n.length > s; s++) {
          (e.prefix = s === 0 && e.prefix),
          (e.defaultValue = i[s] || a),
          (r = new ye(n[s], e))
        }
      })
      const xe = function (t) {
        if (!l[t]) {
          const e = `${t.charAt(0).toUpperCase() + t.substr(1)}Plugin`
          Te(t, {
            parser(t, i, s, r, n, a, h) {
              const _ = o.com.greensock.plugins[e]
              return _
                ? (_._cssRegister(), l[s].parse(t, i, s, r, n, a, h))
                : (q(`Error: ${e} js file not loaded.`), n)
            },
          })
        }
      };
      (h = ye.prototype),
      (h.parseComplex = function (t, e, i, s, r, n) {
        let a
        let o
        let l
        let h
        let _
        let u
        const c = this.keyword
        if (
          (this.multi
                && (M.test(i) || M.test(e)
                  ? ((o = e.replace(M, '|').split('|')),
                  (l = i.replace(M, '|').split('|')))
                  : c && ((o = [e]), (l = [i]))),
          l)
        ) {
          for (
            h = l.length > o.length ? l.length : o.length, a = 0;
            h > a;
            a++
          ) {
            (e = o[a] = o[a] || this.dflt),
            (i = l[a] = l[a] || this.dflt),
            c
                    && ((_ = e.indexOf(c)),
                    (u = i.indexOf(c)),
                    _ !== u
                      && (u === -1
                        ? (o[a] = o[a].split(c).join(''))
                        : _ === -1 && (o[a] += ` ${c}`)))
          }
          (e = o.join(', ')), (i = l.join(', '))
        }
        return ge(t, this.p, e, i, this.clrs, this.dflt, s, this.pr, r, n)
      }),
      (h.parse = function (t, e, i, s, n, a) {
        return this.parseComplex(
          t.style,
          this.format(Q(t, this.p, r, !1, this.dflt)),
          this.format(e),
          n,
          a,
        )
      }),
      (a.registerSpecialProp = function (t, e, i) {
        Te(t, {
          parser(t, s, r, n, a, o) {
            const l = new me(t, r, 0, 0, a, 2, r, !1, i)
            return (l.plugin = o), (l.setRatio = e(t, s, n._tween, r)), l
          },
          priority: i,
        })
      }),
      (a.useSVGTransformAttr = c || f)
      let we
      const be = 'scaleX,scaleY,scaleZ,x,y,z,skewX,skewY,rotation,rotationX,rotationY,perspective,xPercent,yPercent'.split(
        ',',
      )
      var Pe = W('transform')
      var ke = `${V}transform`
      const Se = W('transformOrigin')
      var Re = W('perspective') !== null
      const Oe = (B.Transform = function () {
        (this.perspective = parseFloat(a.defaultTransformPerspective) || 0),
        (this.force3D = a.defaultForce3D !== !1 && Re
          ? a.defaultForce3D || 'auto'
          : !1)
      })
      const Ae = window.SVGElement
      const Ce = function (t, e, i) {
        let s
        const r = E.createElementNS('http://www.w3.org/2000/svg', t)
        const n = /([a-z])([A-Z])/g
        for (s in i) r.setAttributeNS(null, s.replace(n, '$1-$2').toLowerCase(), i[s])
        return e.appendChild(r), r
      }
      const De = E.documentElement
      const Me = (function () {
        let t
        let e
        let i
        let s = m || (/Android/i.test(j) && !window.chrome)
        return (
          E.createElementNS
                && !s
                && ((t = Ce('svg', De)),
                (e = Ce('rect', t, { width: 100, height: 50, x: 100 })),
                (i = e.getBoundingClientRect().width),
                (e.style[Se] = '50% 50%'),
                (e.style[Pe] = 'scaleX(0.5)'),
                (s = i === e.getBoundingClientRect().width && !(f && Re)),
                De.removeChild(t)),
          s
        )
      }())
      const ze = function (t, e, i, s, r) {
        let n
        let o
        let l
        let h
        let _
        let u
        let c
        let f
        let p
        let m
        let d
        let g
        let v
        let y
        const T = t._gsTransform
        const x = Ee(t, !0)
        T && ((v = T.xOrigin), (y = T.yOrigin)),
        (!s || (n = s.split(' ')).length < 2)
                && ((c = t.getBBox()),
                (e = se(e).split(' ')),
                (n = [
                  (e[0].indexOf('%') !== -1
                    ? (parseFloat(e[0]) / 100) * c.width
                    : parseFloat(e[0])) + c.x,
                  (e[1].indexOf('%') !== -1
                    ? (parseFloat(e[1]) / 100) * c.height
                    : parseFloat(e[1])) + c.y,
                ])),
        (i.xOrigin = h = parseFloat(n[0])),
        (i.yOrigin = _ = parseFloat(n[1])),
        s
                && x !== Ie
                && ((u = x[0]),
                (c = x[1]),
                (f = x[2]),
                (p = x[3]),
                (m = x[4]),
                (d = x[5]),
                (g = u * p - c * f),
                (o = h * (p / g) + _ * (-f / g) + (f * d - p * m) / g),
                (l = h * (-c / g) + _ * (u / g) - (u * d - c * m) / g),
                (h = i.xOrigin = n[0] = o),
                (_ = i.yOrigin = n[1] = l)),
        T
                && (r || (r !== !1 && a.defaultSmoothOrigin !== !1)
                  ? ((o = h - v),
                  (l = _ - y),
                  (T.xOffset += o * x[0] + l * x[2] - o),
                  (T.yOffset += o * x[1] + l * x[3] - l))
                  : (T.xOffset = T.yOffset = 0)),
        t.setAttribute('data-svg-origin', n.join(' '))
      }
      const Fe = function (t) {
        return !!(
          Ae
              && typeof t.getBBox === 'function'
              && t.getCTM
              && (!t.parentNode || (t.parentNode.getBBox && t.parentNode.getCTM))
        )
      }
      var Ie = [1, 0, 0, 1, 0, 0]
      var Ee = function (t, e) {
        let i
        let s
        let r
        let n
        let a
        const o = t._gsTransform || new Oe()
        const l = 1e5
        if (
          (Pe
            ? (s = Q(t, ke, null, !0))
            : t.currentStyle
                  && ((s = t.currentStyle.filter.match(C)),
                  (s = s && s.length === 4
                    ? [
                      s[0].substr(4),
                      Number(s[2].substr(4)),
                      Number(s[1].substr(4)),
                      s[3].substr(4),
                      o.x || 0,
                      o.y || 0,
                    ].join(',')
                    : '')),
          (i = !s || s === 'none' || s === 'matrix(1, 0, 0, 1, 0, 0)'),
          (o.svg || (t.getBBox && Fe(t)))
                && (i
                  && (`${t.style[Pe]}`).indexOf('matrix') !== -1
                  && ((s = t.style[Pe]), (i = 0)),
                (r = t.getAttribute('transform')),
                i
                  && r
                  && (r.indexOf('matrix') !== -1
                    ? ((s = r), (i = 0))
                    : r.indexOf('translate') !== -1
                      && ((s = `matrix(1,0,0,1,${
                        r.match(/(?:\-|\b)[\d\-\.e]+\b/gi).join(',')
                      })`),
                      (i = 0)))),
          i)
        ) return Ie
        for (
          r = (s || '').match(/(?:\-|\b)[\d\-\.e]+\b/gi) || [],
          ve = r.length;
          --ve > -1;

        ) {
          (n = Number(r[ve])),
          (r[ve] = (a = n - (n |= 0))
            ? (0 | (a * l + (a < 0 ? -0.5 : 0.5))) / l + n
            : n)
        }
        return e && r.length > 6
          ? [r[0], r[1], r[4], r[5], r[12], r[13]]
          : r
      }
      var Ne = (B.getTransform = function (t, i, s, n) {
        if (t._gsTransform && s && !n) return t._gsTransform
        let o
        let l
        let h
        let _
        let u
        let c
        const f = s ? t._gsTransform || new Oe() : new Oe()
        const p = f.scaleX < 0
        const m = 2e-5
        const d = 1e5
        const g = Re
          ? parseFloat(Q(t, Se, i, !1, '0 0 0').split(' ')[2])
                  || f.zOrigin
                  || 0
          : 0
        const v = parseFloat(a.defaultTransformPerspective) || 0
        if (
          ((f.svg = !(!t.getBBox || !Fe(t))),
          f.svg
                && (ze(
                  t,
                  `${Q(t, Se, r, !1, '50% 50%')}`,
                  f,
                  t.getAttribute('data-svg-origin'),
                ),
                (we = a.useSVGTransformAttr || Me)),
          (o = Ee(t)),
          o !== Ie)
        ) {
          if (o.length === 16) {
            let y
            let T
            let x
            let w
            let b
            let P = o[0]
            let k = o[1]
            let S = o[2]
            const R = o[3]
            let O = o[4]
            let A = o[5]
            let C = o[6]
            const D = o[7]
            let M = o[8]
            let z = o[9]
            let I = o[10]
            let E = o[12]
            let N = o[13]
            let L = o[14]
            let X = o[11]
            let B = Math.atan2(C, I)
            f.zOrigin
                  && ((L = -f.zOrigin),
                  (E = M * L - o[12]),
                  (N = z * L - o[13]),
                  (L = I * L + f.zOrigin - o[14])),
            (f.rotationX = B * F),
            B
                    && ((w = Math.cos(-B)),
                    (b = Math.sin(-B)),
                    (y = O * w + M * b),
                    (T = A * w + z * b),
                    (x = C * w + I * b),
                    (M = O * -b + M * w),
                    (z = A * -b + z * w),
                    (I = C * -b + I * w),
                    (X = D * -b + X * w),
                    (O = y),
                    (A = T),
                    (C = x)),
            (B = Math.atan2(M, I)),
            (f.rotationY = B * F),
            B
                    && ((w = Math.cos(-B)),
                    (b = Math.sin(-B)),
                    (y = P * w - M * b),
                    (T = k * w - z * b),
                    (x = S * w - I * b),
                    (z = k * b + z * w),
                    (I = S * b + I * w),
                    (X = R * b + X * w),
                    (P = y),
                    (k = T),
                    (S = x)),
            (B = Math.atan2(k, P)),
            (f.rotation = B * F),
            B
                    && ((w = Math.cos(-B)),
                    (b = Math.sin(-B)),
                    (P = P * w + O * b),
                    (T = k * w + A * b),
                    (A = k * -b + A * w),
                    (C = S * -b + C * w),
                    (k = T)),
            f.rotationX
                    && Math.abs(f.rotationX) + Math.abs(f.rotation) > 359.9
                    && ((f.rotationX = f.rotation = 0), (f.rotationY += 180)),
            (f.scaleX = (0 | (Math.sqrt(P * P + k * k) * d + 0.5)) / d),
            (f.scaleY = (0 | (Math.sqrt(A * A + z * z) * d + 0.5)) / d),
            (f.scaleZ = (0 | (Math.sqrt(C * C + I * I) * d + 0.5)) / d),
            (f.skewX = 0),
            (f.perspective = X ? 1 / (X < 0 ? -X : X) : 0),
            (f.x = E),
            (f.y = N),
            (f.z = L),
            f.svg
                    && ((f.x -= f.xOrigin - (f.xOrigin * P - f.yOrigin * O)),
                    (f.y -= f.yOrigin - (f.yOrigin * k - f.xOrigin * A)))
          } else if (
            !(
              (Re
                    && !n
                    && o.length
                    && f.x === o[4]
                    && f.y === o[5]
                    && (f.rotationX || f.rotationY))
                  || (void 0 !== f.x && Q(t, 'display', i) === 'none')
            )
          ) {
            const j = o.length >= 6
            const Y = j ? o[0] : 1
            const U = o[1] || 0
            const q = o[2] || 0
            const V = j ? o[3] : 1;
            (f.x = o[4] || 0),
            (f.y = o[5] || 0),
            (h = Math.sqrt(Y * Y + U * U)),
            (_ = Math.sqrt(V * V + q * q)),
            (u = Y || U ? Math.atan2(U, Y) * F : f.rotation || 0),
            (c = q || V ? Math.atan2(q, V) * F + u : f.skewX || 0),
            Math.abs(c) > 90
                    && Math.abs(c) < 270
                    && (p
                      ? ((h *= -1),
                      (c += u <= 0 ? 180 : -180),
                      (u += u <= 0 ? 180 : -180))
                      : ((_ *= -1), (c += c <= 0 ? 180 : -180))),
            (f.scaleX = h),
            (f.scaleY = _),
            (f.rotation = u),
            (f.skewX = c),
            Re
                    && ((f.rotationX = f.rotationY = f.z = 0),
                    (f.perspective = v),
                    (f.scaleZ = 1)),
            f.svg
                    && ((f.x -= f.xOrigin - (f.xOrigin * Y + f.yOrigin * q)),
                    (f.y -= f.yOrigin - (f.xOrigin * U + f.yOrigin * V)))
          }
          f.zOrigin = g
          for (l in f) m > f[l] && f[l] > -m && (f[l] = 0)
        }
        return (
          s
                && ((t._gsTransform = f),
                f.svg
                  && (we && t.style[Pe]
                    ? e.delayedCall(0.001, () => {
                      je(t.style, Pe)
                    })
                    : !we
                      && t.getAttribute('transform')
                      && e.delayedCall(0.001, () => {
                        t.removeAttribute('transform')
                      }))),
          f
        )
      })
      const Le = function (t) {
        let e
        let i
        const s = this.data
        const r = -s.rotation * z
        const n = r + s.skewX * z
        const a = 1e5
        const o = (0 | (Math.cos(r) * s.scaleX * a)) / a
        let l = (0 | (Math.sin(r) * s.scaleX * a)) / a
        let h = (0 | (Math.sin(n) * -s.scaleY * a)) / a
        const _ = (0 | (Math.cos(n) * s.scaleY * a)) / a
        const u = this.t.style
        const c = this.t.currentStyle
        if (c) {
          (i = l), (l = -h), (h = -i), (e = c.filter), (u.filter = '')
          let f
          let p
          const d = this.t.offsetWidth
          const g = this.t.offsetHeight
          const v = c.position !== 'absolute'
          let y = `progid:DXImageTransform.Microsoft.Matrix(M11=${
            o
          }, M12=${
            l
          }, M21=${
            h
          }, M22=${
            _}`
          let w = s.x + (d * s.xPercent) / 100
          let b = s.y + (g * s.yPercent) / 100
          if (
            (s.ox != null
                  && ((f = (s.oxp ? 0.01 * d * s.ox : s.ox) - d / 2),
                  (p = (s.oyp ? 0.01 * g * s.oy : s.oy) - g / 2),
                  (w += f - (f * o + p * l)),
                  (b += p - (f * h + p * _))),
            v
              ? ((f = d / 2),
              (p = g / 2),
              (y
                      += `, Dx=${
                  f - (f * o + p * l) + w
                }, Dy=${
                  p - (f * h + p * _) + b
                })`))
              : (y += ", sizingMethod='auto expand')"),
            (u.filter = e.indexOf('DXImageTransform.Microsoft.Matrix(') !== -1
              ? e.replace(D, y)
              : `${y} ${e}`),
            (t === 0 || t === 1)
                  && o === 1
                  && l === 0
                  && h === 0
                  && _ === 1
                  && ((v && y.indexOf('Dx=0, Dy=0') === -1)
                    || (x.test(e) && parseFloat(RegExp.$1) !== 100)
                    || (e.indexOf('gradient(' && e.indexOf('Alpha')) === -1
                      && u.removeAttribute('filter'))),
            !v)
          ) {
            let P
            let k
            let S
            const R = m < 8 ? 1 : -1
            for (
              f = s.ieOffsetX || 0,
              p = s.ieOffsetY || 0,
              s.ieOffsetX = Math.round(
                (d - ((o < 0 ? -o : o) * d + (l < 0 ? -l : l) * g)) / 2
                        + w,
              ),
              s.ieOffsetY = Math.round(
                (g - ((_ < 0 ? -_ : _) * g + (h < 0 ? -h : h) * d)) / 2
                        + b,
              ),
              ve = 0;
              ve < 4;
              ve++
            ) {
              (k = ee[ve]),
              (P = c[k]),
              (i = P.indexOf('px') !== -1
                ? parseFloat(P)
                : $(this.t, k, parseFloat(P), P.replace(T, '')) || 0),
              (S = i !== s[k]
                ? ve < 2
                  ? -s.ieOffsetX
                  : -s.ieOffsetY
                : ve < 2
                  ? f - s.ieOffsetX
                  : p - s.ieOffsetY),
              (u[k] = `${s[k] = Math.round(
                i - S * (ve === 0 || ve === 2 ? 1 : R),
              )}px`)
            }
          }
        }
      }
      const Xe = (B.set3DTransformRatio = B.setTransformRatio = function (t) {
        let e
        let i
        let s
        let r
        let n
        let a
        let o
        let l
        let h
        let _
        let u
        let c
        let p
        let m
        let d
        let g
        let v
        let y
        let T
        let x
        let w
        let b
        let P
        const k = this.data
        const S = this.t.style
        let R = k.rotation
        const O = k.rotationX
        const A = k.rotationY
        let C = k.scaleX
        let D = k.scaleY
        let M = k.scaleZ
        let F = k.x
        let I = k.y
        let E = k.z
        const N = k.svg
        let L = k.perspective
        const X = k.force3D
        if (
          !(
            ((((t !== 1 && t !== 0)
                      || X !== 'auto'
                      || (this.tween._totalTime !== this.tween._totalDuration
                        && this.tween._totalTime))
                      && X)
                      || E
                      || L
                      || A
                      || O)
                    && (!we || !N)
                    && Re
          )
        ) {
          return (
            R || k.skewX || N
              ? ((R *= z),
              (b = k.skewX * z),
              (P = 1e5),
              (e = Math.cos(R) * C),
              (r = Math.sin(R) * C),
              (i = Math.sin(R - b) * -D),
              (n = Math.cos(R - b) * D),
              b
                          && k.skewType === 'simple'
                          && ((v = Math.tan(b)),
                          (v = Math.sqrt(1 + v * v)),
                          (i *= v),
                          (n *= v),
                          k.skewY && ((e *= v), (r *= v))),
              N
                          && ((F
                            += k.xOrigin
                            - (k.xOrigin * e + k.yOrigin * i)
                            + k.xOffset),
                          (I
                            += k.yOrigin
                            - (k.xOrigin * r + k.yOrigin * n)
                            + k.yOffset),
                          we
                            && (k.xPercent || k.yPercent)
                            && ((m = this.t.getBBox()),
                            (F += 0.01 * k.xPercent * m.width),
                            (I += 0.01 * k.yPercent * m.height)),
                          (m = 1e-6),
                          m > F && F > -m && (F = 0),
                          m > I && I > -m && (I = 0)),
              (T = `${(0 | (e * P)) / P
              },${
                (0 | (r * P)) / P
              },${
                (0 | (i * P)) / P
              },${
                (0 | (n * P)) / P
              },${
                F
              },${
                I
              })`),
              N && we
                ? this.t.setAttribute('transform', `matrix(${T}`)
                : (S[Pe] = (k.xPercent || k.yPercent
                  ? `translate(${
                    k.xPercent
                  }%,${
                    k.yPercent
                  }%) matrix(`
                  : 'matrix(') + T))
              : (S[Pe] = `${(k.xPercent || k.yPercent
                ? `translate(${
                  k.xPercent
                }%,${
                  k.yPercent
                }%) matrix(`
                : 'matrix(')
                          + C
              },0,0,${
                D
              },${
                F
              },${
                I
              })`),
            void 0
          )
        }
        if (
          (f
                    && ((m = 1e-4),
                    m > C && C > -m && (C = M = 2e-5),
                    m > D && D > -m && (D = M = 2e-5),
                    !L || k.z || k.rotationX || k.rotationY || (L = 0)),
          R || k.skewX)
        ) {
          (R *= z),
          (d = e = Math.cos(R)),
          (g = r = Math.sin(R)),
          k.skewX
                      && ((R -= k.skewX * z),
                      (d = Math.cos(R)),
                      (g = Math.sin(R)),
                      k.skewType === 'simple'
                        && ((v = Math.tan(k.skewX * z)),
                        (v = Math.sqrt(1 + v * v)),
                        (d *= v),
                        (g *= v),
                        k.skewY && ((e *= v), (r *= v)))),
          (i = -g),
          (n = d)
        } else {
          if (!(A || O || M !== 1 || L || N)) {
            return (
              (S[Pe] = `${(k.xPercent || k.yPercent
                ? `translate(${
                  k.xPercent
                }%,${
                  k.yPercent
                }%) translate3d(`
                : 'translate3d(')
                        + F
              }px,${
                I
              }px,${
                E
              }px)${
                C !== 1 || D !== 1
                  ? ` scale(${C},${D})`
                  : ''}`),
              void 0
            )
          }
          (e = n = 1), (i = r = 0)
        }
        (h = 1),
        (s = a = o = l = _ = u = 0),
        (c = L ? -1 / L : 0),
        (p = k.zOrigin),
        (m = 1e-6),
        (x = ','),
        (w = '0'),
        (R = A * z),
        R
                    && ((d = Math.cos(R)),
                    (g = Math.sin(R)),
                    (o = -g),
                    (_ = c * -g),
                    (s = e * g),
                    (a = r * g),
                    (h = d),
                    (c *= d),
                    (e *= d),
                    (r *= d)),
        (R = O * z),
        R
                    && ((d = Math.cos(R)),
                    (g = Math.sin(R)),
                    (v = i * d + s * g),
                    (y = n * d + a * g),
                    (l = h * g),
                    (u = c * g),
                    (s = i * -g + s * d),
                    (a = n * -g + a * d),
                    (h *= d),
                    (c *= d),
                    (i = v),
                    (n = y)),
        M !== 1 && ((s *= M), (a *= M), (h *= M), (c *= M)),
        D !== 1 && ((i *= D), (n *= D), (l *= D), (u *= D)),
        C !== 1 && ((e *= C), (r *= C), (o *= C), (_ *= C)),
        (p || N)
                    && (p && ((F += s * -p), (I += a * -p), (E += h * -p + p)),
                    N
                      && ((F
                        += k.xOrigin
                        - (k.xOrigin * e + k.yOrigin * i)
                        + k.xOffset),
                      (I
                        += k.yOrigin
                        - (k.xOrigin * r + k.yOrigin * n)
                        + k.yOffset)),
                    m > F && F > -m && (F = w),
                    m > I && I > -m && (I = w),
                    m > E && E > -m && (E = 0)),
        (T = k.xPercent || k.yPercent
          ? `translate(${
            k.xPercent
          }%,${
            k.yPercent
          }%) matrix3d(`
          : 'matrix3d('),
        (T
                    += (m > e && e > -m ? w : e)
                    + x
                    + (m > r && r > -m ? w : r)
                    + x
                    + (m > o && o > -m ? w : o)),
        (T
                    += x
                    + (m > _ && _ > -m ? w : _)
                    + x
                    + (m > i && i > -m ? w : i)
                    + x
                    + (m > n && n > -m ? w : n)),
        O || A
          ? ((T
                        += x
                        + (m > l && l > -m ? w : l)
                        + x
                        + (m > u && u > -m ? w : u)
                        + x
                        + (m > s && s > -m ? w : s)),
          (T
                        += x
                        + (m > a && a > -m ? w : a)
                        + x
                        + (m > h && h > -m ? w : h)
                        + x
                        + (m > c && c > -m ? w : c)
                        + x))
          : (T += ',0,0,0,0,1,0,'),
        (T += `${F + x + I + x + E + x + (L ? 1 + -E / L : 1)})`),
        (S[Pe] = T)
      });
      (h = Oe.prototype),
      (h.x = h.y = h.z = h.skewX = h.skewY = h.rotation = h.rotationX = h.rotationY = h.zOrigin = h.xPercent = h.yPercent = h.xOffset = h.yOffset = 0),
      (h.scaleX = h.scaleY = h.scaleZ = 1),
      Te(
        'transform,scale,scaleX,scaleY,scaleZ,x,y,z,rotation,rotationX,rotationY,rotationZ,skewX,skewY,shortRotation,shortRotationX,shortRotationY,shortRotationZ,transformOrigin,svgOrigin,transformPerspective,directionalRotation,parseTransform,force3D,skewType,xPercent,yPercent,smoothOrigin',
        {
          parser(t, e, i, s, n, o, l) {
            if (s._lastParsedTransform === l) return n
            s._lastParsedTransform = l
            let h
            let _
            let u
            let c
            let f
            let p
            let m
            let d
            let g
            let v
            const y = t._gsTransform
            const T = t.style
            const x = 1e-6
            let w = be.length
            const b = l
            const P = {}
            const k = 'transformOrigin'
            if (
              (l.display
                ? ((c = Q(t, 'display')),
                (T.display = 'block'),
                (h = Ne(t, r, !0, l.parseTransform)),
                (T.display = c))
                : (h = Ne(t, r, !0, l.parseTransform)),
              (s._transform = h),
              typeof b.transform === 'string' && Pe)
            ) {
              (c = L.style),
              (c[Pe] = b.transform),
              (c.display = 'block'),
              (c.position = 'absolute'),
              E.body.appendChild(L),
              (_ = Ne(L, null, !1)),
              E.body.removeChild(L),
              _.perspective || (_.perspective = h.perspective),
              b.xPercent != null
                      && (_.xPercent = ne(b.xPercent, h.xPercent)),
              b.yPercent != null
                      && (_.yPercent = ne(b.yPercent, h.yPercent))
            } else if (typeof b === 'object') {
              if (
                ((_ = {
                  scaleX: ne(
                    b.scaleX != null ? b.scaleX : b.scale,
                    h.scaleX,
                  ),
                  scaleY: ne(
                    b.scaleY != null ? b.scaleY : b.scale,
                    h.scaleY,
                  ),
                  scaleZ: ne(b.scaleZ, h.scaleZ),
                  x: ne(b.x, h.x),
                  y: ne(b.y, h.y),
                  z: ne(b.z, h.z),
                  xPercent: ne(b.xPercent, h.xPercent),
                  yPercent: ne(b.yPercent, h.yPercent),
                  perspective: ne(b.transformPerspective, h.perspective),
                }),
                (d = b.directionalRotation),
                d != null)
              ) {
                if (typeof d === 'object') for (c in d) b[c] = d[c]
                else b.rotation = d
              }
              typeof b.x === 'string'
                    && b.x.indexOf('%') !== -1
                    && ((_.x = 0), (_.xPercent = ne(b.x, h.xPercent))),
              typeof b.y === 'string'
                      && b.y.indexOf('%') !== -1
                      && ((_.y = 0), (_.yPercent = ne(b.y, h.yPercent))),
              (_.rotation = ae(
                'rotation' in b
                  ? b.rotation
                  : 'shortRotation' in b
                    ? `${b.shortRotation}_short`
                    : 'rotationZ' in b
                      ? b.rotationZ
                      : h.rotation,
                h.rotation,
                'rotation',
                P,
              )),
              Re
                      && ((_.rotationX = ae(
                        'rotationX' in b
                          ? b.rotationX
                          : 'shortRotationX' in b
                            ? `${b.shortRotationX}_short`
                            : h.rotationX || 0,
                        h.rotationX,
                        'rotationX',
                        P,
                      )),
                      (_.rotationY = ae(
                        'rotationY' in b
                          ? b.rotationY
                          : 'shortRotationY' in b
                            ? `${b.shortRotationY}_short`
                            : h.rotationY || 0,
                        h.rotationY,
                        'rotationY',
                        P,
                      ))),
              (_.skewX = b.skewX == null ? h.skewX : ae(b.skewX, h.skewX)),
              (_.skewY = b.skewY == null ? h.skewY : ae(b.skewY, h.skewY)),
              (u = _.skewY - h.skewY)
                      && ((_.skewX += u), (_.rotation += u))
            }
            for (
              Re
                    && b.force3D != null
                    && ((h.force3D = b.force3D), (m = !0)),
              h.skewType = b.skewType || h.skewType || a.defaultSkewType,
              p = h.force3D
                      || h.z
                      || h.rotationX
                      || h.rotationY
                      || _.z
                      || _.rotationX
                      || _.rotationY
                      || _.perspective,
              p || b.scale == null || (_.scaleZ = 1);
              --w > -1;

            ) {
              (i = be[w]),
              (f = _[i] - h[i]),
              (f > x || -x > f || b[i] != null || I[i] != null)
                      && ((m = !0),
                      (n = new me(h, i, h[i], f, n)),
                      i in P && (n.e = P[i]),
                      (n.xs0 = 0),
                      (n.plugin = o),
                      s._overwriteProps.push(n.n))
            }
            return (
              (f = b.transformOrigin),
              h.svg
                    && (f || b.svgOrigin)
                    && ((g = h.xOffset),
                    (v = h.yOffset),
                    ze(t, se(f), _, b.svgOrigin, b.smoothOrigin),
                    (n = de(
                      h,
                      'xOrigin',
                      (y ? h : _).xOrigin,
                      _.xOrigin,
                      n,
                      k,
                    )),
                    (n = de(
                      h,
                      'yOrigin',
                      (y ? h : _).yOrigin,
                      _.yOrigin,
                      n,
                      k,
                    )),
                    (g !== h.xOffset || v !== h.yOffset)
                      && ((n = de(
                        h,
                        'xOffset',
                        y ? g : h.xOffset,
                        h.xOffset,
                        n,
                        k,
                      )),
                      (n = de(
                        h,
                        'yOffset',
                        y ? v : h.yOffset,
                        h.yOffset,
                        n,
                        k,
                      ))),
                    (f = we ? null : '0px 0px')),
              (f || (Re && p && h.zOrigin))
                    && (Pe
                      ? ((m = !0),
                      (i = Se),
                      (f = `${f || Q(t, i, r, !1, '50% 50%')}`),
                      (n = new me(T, i, 0, 0, n, -1, k)),
                      (n.b = T[i]),
                      (n.plugin = o),
                      Re
                        ? ((c = h.zOrigin),
                        (f = f.split(' ')),
                        (h.zOrigin = (f.length > 2 && (c === 0 || f[2] !== '0px')
                          ? parseFloat(f[2])
                          : c) || 0),
                        (n.xs0 = n.e = `${f[0]} ${f[1] || '50%'} 0px`),
                        (n = new me(h, 'zOrigin', 0, 0, n, -1, n.n)),
                        (n.b = c),
                        (n.xs0 = n.e = h.zOrigin))
                        : (n.xs0 = n.e = f))
                      : se(`${f}`, h)),
              m
                    && (s._transformType = (h.svg && we) || (!p && this._transformType !== 3)
                      ? 2
                      : 3),
              n
            )
          },
          prefix: !0,
        },
      ),
      Te('boxShadow', {
        defaultValue: '0px 0px 0px 0px #999',
        prefix: !0,
        color: !0,
        multi: !0,
        keyword: 'inset',
      }),
      Te('borderRadius', {
        defaultValue: '0px',
        parser(t, e, i, n, a) {
          e = this.format(e)
          let o
          let l
          let h
          let _
          let u
          let c
          let f
          let p
          let m
          let d
          let g
          let v
          let y
          let T
          let x
          let w
          const b = [
            'borderTopLeftRadius',
            'borderTopRightRadius',
            'borderBottomRightRadius',
            'borderBottomLeftRadius',
          ]
          const P = t.style
          for (
            m = parseFloat(t.offsetWidth),
            d = parseFloat(t.offsetHeight),
            o = e.split(' '),
            l = 0;
            b.length > l;
            l++
          ) {
            this.p.indexOf('border') && (b[l] = W(b[l])),
            (u = _ = Q(t, b[l], r, !1, '0px')),
            u.indexOf(' ') !== -1
                    && ((_ = u.split(' ')), (u = _[0]), (_ = _[1])),
            (c = h = o[l]),
            (f = parseFloat(u)),
            (v = u.substr((`${f}`).length)),
            (y = c.charAt(1) === '='),
            y
              ? ((p = parseInt(`${c.charAt(0)}1`, 10)),
              (c = c.substr(2)),
              (p *= parseFloat(c)),
              (g = c.substr((`${p}`).length - (p < 0 ? 1 : 0)) || ''))
              : ((p = parseFloat(c)), (g = c.substr((`${p}`).length))),
            g === '' && (g = s[i] || v),
            g !== v
                    && ((T = $(t, 'borderLeft', f, v)),
                    (x = $(t, 'borderTop', f, v)),
                    g === '%'
                      ? ((u = `${100 * (T / m)}%`), (_ = `${100 * (x / d)}%`))
                      : g === 'em'
                        ? ((w = $(t, 'borderLeft', 1, 'em')),
                        (u = `${T / w}em`),
                        (_ = `${x / w}em`))
                        : ((u = `${T}px`), (_ = `${x}px`)),
                    y
                      && ((c = parseFloat(u) + p + g),
                      (h = parseFloat(_) + p + g))),
            (a = ge(P, b[l], `${u} ${_}`, `${c} ${h}`, !1, '0px', a))
          }
          return a
        },
        prefix: !0,
        formatter: ce('0px 0px 0px 0px', !1, !0),
      }),
      Te('backgroundPosition', {
        defaultValue: '0 0',
        parser(t, e, i, s, n, a) {
          let o
          let l
          let h
          let _
          let u
          let c
          const f = 'background-position'
          const p = r || Z(t, null)
          let d = this.format(
            (p
              ? m
                ? `${p.getPropertyValue(`${f}-x`)
                } ${
                  p.getPropertyValue(`${f}-y`)}`
                : p.getPropertyValue(f)
              : `${t.currentStyle.backgroundPositionX
              } ${
                t.currentStyle.backgroundPositionY}`) || '0 0',
          )
          const g = this.format(e)
          if (
            (d.indexOf('%') !== -1) != (g.indexOf('%') !== -1)
                && ((c = Q(t, 'backgroundImage').replace(R, '')),
                c && c !== 'none')
          ) {
            for (
              o = d.split(' '),
              l = g.split(' '),
              X.setAttribute('src', c),
              h = 2;
              --h > -1;

            ) {
              (d = o[h]),
              (_ = d.indexOf('%') !== -1),
              _ !== (l[h].indexOf('%') !== -1)
                      && ((u = h === 0
                        ? t.offsetWidth - X.width
                        : t.offsetHeight - X.height),
                      (o[h] = _
                        ? `${(parseFloat(d) / 100) * u}px`
                        : `${100 * (parseFloat(d) / u)}%`))
            }
            d = o.join(' ')
          }
          return this.parseComplex(t.style, d, g, n, a)
        },
        formatter: se,
      }),
      Te('backgroundSize', { defaultValue: '0 0', formatter: se }),
      Te('perspective', { defaultValue: '0px', prefix: !0 }),
      Te('perspectiveOrigin', { defaultValue: '50% 50%', prefix: !0 }),
      Te('transformStyle', { prefix: !0 }),
      Te('backfaceVisibility', { prefix: !0 }),
      Te('userSelect', { prefix: !0 }),
      Te('margin', {
        parser: fe('marginTop,marginRight,marginBottom,marginLeft'),
      }),
      Te('padding', {
        parser: fe('paddingTop,paddingRight,paddingBottom,paddingLeft'),
      }),
      Te('clip', {
        defaultValue: 'rect(0px,0px,0px,0px)',
        parser(t, e, i, s, n, a) {
          let o; let l; let
            h
          return (
            m < 9
              ? ((l = t.currentStyle),
              (h = m < 8 ? ' ' : ','),
              (o = `rect(${
                l.clipTop
              }${h
              }${l.clipRight
              }${h
              }${l.clipBottom
              }${h
              }${l.clipLeft
              })`),
              (e = this.format(e).split(',').join(h)))
              : ((o = this.format(Q(t, this.p, r, !1, this.dflt))),
              (e = this.format(e))),
            this.parseComplex(t.style, o, e, n, a)
          )
        },
      }),
      Te('textShadow', {
        defaultValue: '0px 0px 0px #999',
        color: !0,
        multi: !0,
      }),
      Te('autoRound,strictUnits', {
        parser(t, e, i, s, r) {
          return r
        },
      }),
      Te('border', {
        defaultValue: '0px solid #000',
        parser(t, e, i, s, n, a) {
          return this.parseComplex(
            t.style,
            this.format(
              `${Q(t, 'borderTopWidth', r, !1, '0px')
              } ${
                Q(t, 'borderTopStyle', r, !1, 'solid')
              } ${
                Q(t, 'borderTopColor', r, !1, '#000')}`,
            ),
            this.format(e),
            n,
            a,
          )
        },
        color: !0,
        formatter(t) {
          const e = t.split(' ')
          return (
            `${e[0]
            } ${
              e[1] || 'solid'
            } ${
              (t.match(ue) || ['#000'])[0]}`
          )
        },
      }),
      Te('borderWidth', {
        parser: fe(
          'borderTopWidth,borderRightWidth,borderBottomWidth,borderLeftWidth',
        ),
      }),
      Te('float,cssFloat,styleFloat', {
        parser(t, e, i, s, r) {
          const n = t.style
          const a = 'cssFloat' in n ? 'cssFloat' : 'styleFloat'
          return new me(n, a, 0, 0, r, -1, i, !1, 0, n[a], e)
        },
      })
      const Be = function (t) {
        let e
        const i = this.t
        let s = i.filter || Q(this.data, 'filter') || ''
        const r = 0 | (this.s + this.c * t)
        r === 100
            && (s.indexOf('atrix(') === -1
            && s.indexOf('radient(') === -1
            && s.indexOf('oader(') === -1
              ? (i.removeAttribute('filter'), (e = !Q(this.data, 'filter')))
              : ((i.filter = s.replace(b, '')), (e = !0))),
        e
              || (this.xn1 && (i.filter = s = s || `alpha(opacity=${r})`),
              s.indexOf('pacity') === -1
                ? (r === 0 && this.xn1)
                  || (i.filter = `${s} alpha(opacity=${r})`)
                : (i.filter = s.replace(x, `opacity=${r}`)))
      }
      Te('opacity,alpha,autoAlpha', {
        defaultValue: '1',
        parser(t, e, i, s, n, a) {
          let o = parseFloat(Q(t, 'opacity', r, !1, '1'))
          const l = t.style
          const h = i === 'autoAlpha'
          return (
            typeof e === 'string'
                && e.charAt(1) === '='
                && (e = (e.charAt(0) === '-' ? -1 : 1) * parseFloat(e.substr(2)) + o),
            h
                && o === 1
                && Q(t, 'visibility', r) === 'hidden'
                && e !== 0
                && (o = 0),
            Y
              ? (n = new me(l, 'opacity', o, e - o, n))
              : ((n = new me(l, 'opacity', 100 * o, 100 * (e - o), n)),
              (n.xn1 = h ? 1 : 0),
              (l.zoom = 1),
              (n.type = 2),
              (n.b = `alpha(opacity=${n.s})`),
              (n.e = `alpha(opacity=${n.s + n.c})`),
              (n.data = t),
              (n.plugin = a),
              (n.setRatio = Be)),
            h
                && ((n = new me(
                  l,
                  'visibility',
                  0,
                  0,
                  n,
                  -1,
                  null,
                  !1,
                  0,
                  o !== 0 ? 'inherit' : 'hidden',
                  e === 0 ? 'hidden' : 'inherit',
                )),
                (n.xs0 = 'inherit'),
                s._overwriteProps.push(n.n),
                s._overwriteProps.push(i)),
            n
          )
        },
      })
      var je = function (t, e) {
        e
              && (t.removeProperty
                ? ((e.substr(0, 2) === 'ms' || e.substr(0, 6) === 'webkit')
                    && (e = `-${e}`),
                t.removeProperty(e.replace(k, '-$1').toLowerCase()))
                : t.removeAttribute(e))
      }
      const Ye = function (t) {
        if (((this.t._gsClassPT = this), t === 1 || t === 0)) {
          this.t.setAttribute('class', t === 0 ? this.b : this.e)
          for (let e = this.data, i = this.t.style; e;) e.v ? (i[e.p] = e.v) : je(i, e.p), (e = e._next)
          t === 1
                && this.t._gsClassPT === this
                && (this.t._gsClassPT = null)
        } else {
          this.t.getAttribute('class') !== this.e
                && this.t.setAttribute('class', this.e)
        }
      }
      Te('className', {
        parser(t, e, s, n, a, o, l) {
          let h
          let _
          let u
          let c
          let f
          const p = t.getAttribute('class') || ''
          const m = t.style.cssText
          if (
            ((a = n._classNamePT = new me(t, s, 0, 0, a, 2)),
            (a.setRatio = Ye),
            (a.pr = -11),
            (i = !0),
            (a.b = p),
            (_ = K(t, r)),
            (u = t._gsClassPT))
          ) {
            for (c = {}, f = u.data; f;) (c[f.p] = 1), (f = f._next)
            u.setRatio(1)
          }
          return (
            (t._gsClassPT = a),
            (a.e = e.charAt(1) !== '='
              ? e
              : p.replace(RegExp(`\\s*\\b${e.substr(2)}\\b`), '')
                    + (e.charAt(0) === '+' ? ` ${e.substr(2)}` : '')),
            t.setAttribute('class', a.e),
            (h = J(t, _, K(t), l, c)),
            t.setAttribute('class', p),
            (a.data = h.firstMPT),
            (t.style.cssText = m),
            (a = a.xfirst = n.parse(t, h.difs, a, o))
          )
        },
      })
      const Ue = function (t) {
        if (
          (t === 1 || t === 0)
            && this.data._totalTime === this.data._totalDuration
            && this.data.data !== 'isFromStart'
        ) {
          let e
          let i
          let s
          let r
          let n
          const a = this.t.style
          const o = l.transform.parse
          if (this.e === 'all') (a.cssText = ''), (r = !0)
          else {
            for (
              e = this.e.split(' ').join('').split(','), s = e.length;
              --s > -1;

            ) {
              (i = e[s]),
              l[i]
                    && (l[i].parse === o
                      ? (r = !0)
                      : (i = i === 'transformOrigin' ? Se : l[i].p)),
              je(a, i)
            }
          }
          r
              && (je(a, Pe),
              (n = this.t._gsTransform),
              n
                && (n.svg && this.t.removeAttribute('data-svg-origin'),
                delete this.t._gsTransform))
        }
      }
      for (
        Te('clearProps', {
          parser(t, e, s, r, n) {
            return (
              (n = new me(t, s, 0, 0, n, 2)),
              (n.setRatio = Ue),
              (n.e = e),
              (n.pr = -10),
              (n.data = r._tween),
              (i = !0),
              n
            )
          },
        }),
        h = 'bezier,throwProps,physicsProps,physics2D'.split(','),
        ve = h.length;
        ve--;

      ) xe(h[ve]);
      (h = a.prototype),
      (h._firstPT = h._lastParsedTransform = h._transform = null),
      (h._onInitTween = function (t, e, o) {
        if (!t.nodeType) return !1;
        (this._target = t),
        (this._tween = o),
        (this._vars = e),
        (_ = e.autoRound),
        (i = !1),
        (s = e.suffixMap || a.suffixMap),
        (r = Z(t, '')),
        (n = this._overwriteProps)
        let h
        let f
        let m
        let d
        let g
        let v
        let y
        let T
        let x
        const b = t.style
        if (
          (u
                && b.zIndex === ''
                && ((h = Q(t, 'zIndex', r)),
                (h === 'auto' || h === '') && this._addLazySet(b, 'zIndex', 0)),
          typeof e === 'string'
                && ((d = b.cssText),
                (h = K(t, r)),
                (b.cssText = `${d};${e}`),
                (h = J(t, h, K(t)).difs),
                !Y && w.test(e) && (h.opacity = parseFloat(RegExp.$1)),
                (e = h),
                (b.cssText = d)),
          (this._firstPT = f = e.className
            ? l.className.parse(
              t,
              e.className,
              'className',
              this,
              null,
              null,
              e,
            )
            : this.parse(t, e, null)),
          this._transformType)
        ) {
          for (
            x = this._transformType === 3,
            Pe
              ? c
                      && ((u = !0),
                      b.zIndex === ''
                        && ((y = Q(t, 'zIndex', r)),
                        (y === 'auto' || y === '')
                          && this._addLazySet(b, 'zIndex', 0)),
                      p
                        && this._addLazySet(
                          b,
                          'WebkitBackfaceVisibility',
                          this._vars.WebkitBackfaceVisibility
                            || (x ? 'visible' : 'hidden'),
                        ))
              : (b.zoom = 1),
            m = f;
            m && m._next;

          ) m = m._next;
          (T = new me(t, 'transform', 0, 0, null, 2)),
          this._linkCSSP(T, null, m),
          (T.setRatio = Pe ? Xe : Le),
          (T.data = this._transform || Ne(t, r, !0)),
          (T.tween = o),
          (T.pr = -1),
          n.pop()
        }
        if (i) {
          for (; f;) {
            for (v = f._next, m = d; m && m.pr > f.pr;) m = m._next;
            (f._prev = m ? m._prev : g) ? (f._prev._next = f) : (d = f),
            (f._next = m) ? (m._prev = f) : (g = f),
            (f = v)
          }
          this._firstPT = d
        }
        return !0
      }),
      (h.parse = function (t, e, i, n) {
        let a
        let o
        let h
        let u
        let c
        let f
        let p
        let m
        let d
        let g
        const v = t.style
        for (a in e) {
          (f = e[a]),
          (o = l[a]),
          o
            ? (i = o.parse(t, f, a, this, i, n, e))
            : ((c = `${Q(t, a, r)}`),
            (d = typeof f === 'string'),
            a === 'color'
                    || a === 'fill'
                    || a === 'stroke'
                    || a.indexOf('Color') !== -1
                    || (d && P.test(f))
              ? (d
                          || ((f = he(f)),
                          (f = `${(f.length > 3 ? 'rgba(' : 'rgb(')
                            + f.join(',')
                          })`)),
              (i = ge(v, a, c, f, !0, 'transparent', i, 0, n)))
              : !d || (f.indexOf(' ') === -1 && f.indexOf(',') === -1)
                ? ((h = parseFloat(c)),
                (p = h || h === 0 ? c.substr((`${h}`).length) : ''),
                (c === '' || c === 'auto')
                          && (a === 'width' || a === 'height'
                            ? ((h = ie(t, a, r)), (p = 'px'))
                            : a === 'left' || a === 'top'
                              ? ((h = H(t, a, r)), (p = 'px'))
                              : ((h = a !== 'opacity' ? 0 : 1), (p = ''))),
                (g = d && f.charAt(1) === '='),
                g
                  ? ((u = parseInt(`${f.charAt(0)}1`, 10)),
                  (f = f.substr(2)),
                  (u *= parseFloat(f)),
                  (m = f.replace(T, '')))
                  : ((u = parseFloat(f)),
                  (m = d ? f.replace(T, '') : '')),
                m === '' && (m = a in s ? s[a] : p),
                (f = u || u === 0 ? (g ? u + h : u) + m : e[a]),
                p !== m
                          && m !== ''
                          && (u || u === 0)
                          && h
                          && ((h = $(t, a, h, p)),
                          m === '%'
                            ? ((h /= $(t, a, 100, '%') / 100),
                            e.strictUnits !== !0 && (c = `${h}%`))
                            : m === 'em' || m === 'rem'
                              ? (h /= $(t, a, 1, m))
                              : m !== 'px' && ((u = $(t, a, u, m)), (m = 'px')),
                          g && (u || u === 0) && (f = u + h + m)),
                g && (u += h),
                (!h && h !== 0) || (!u && u !== 0)
                  ? void 0 !== v[a]
                            && (f || (`${f}` != 'NaN' && f != null))
                    ? ((i = new me(
                      v,
                      a,
                      u || h || 0,
                      0,
                      i,
                      -1,
                      a,
                      !1,
                      0,
                      c,
                      f,
                    )),
                    (i.xs0 = f !== 'none'
                                || (a !== 'display' && a.indexOf('Style') === -1)
                      ? f
                      : c))
                    : q(`invalid ${a} tween value: ${e[a]}`)
                  : ((i = new me(
                    v,
                    a,
                    h,
                    u - h,
                    i,
                    0,
                    a,
                    _ !== !1 && (m === 'px' || a === 'zIndex'),
                    0,
                    c,
                    f,
                  )),
                  (i.xs0 = m)))
                : (i = ge(v, a, c, f, !0, null, i, 0, n))),
          n && i && !i.plugin && (i.plugin = n)
        }
        return i
      }),
      (h.setRatio = function (t) {
        let e
        let i
        let s
        let r = this._firstPT
        const n = 1e-6
        if (
          t !== 1
              || (this._tween._time !== this._tween._duration
                && this._tween._time !== 0)
        ) {
          if (
            t
                || (this._tween._time !== this._tween._duration
                  && this._tween._time !== 0)
                || this._tween._rawPrevTime === -1e-6
          ) {
            for (; r;) {
              if (
                ((e = r.c * t + r.s),
                r.r ? (e = Math.round(e)) : n > e && e > -n && (e = 0),
                r.type)
              ) {
                if (r.type === 1) {
                  if (((s = r.l), s === 2)) r.t[r.p] = r.xs0 + e + r.xs1 + r.xn1 + r.xs2
                  else if (s === 3) r.t[r.p] = r.xs0 + e + r.xs1 + r.xn1 + r.xs2 + r.xn2 + r.xs3
                  else if (s === 4) {
                    r.t[r.p] = r.xs0
                          + e
                          + r.xs1
                          + r.xn1
                          + r.xs2
                          + r.xn2
                          + r.xs3
                          + r.xn3
                          + r.xs4
                  } else if (s === 5) {
                    r.t[r.p] = r.xs0
                          + e
                          + r.xs1
                          + r.xn1
                          + r.xs2
                          + r.xn2
                          + r.xs3
                          + r.xn3
                          + r.xs4
                          + r.xn4
                          + r.xs5
                  } else {
                    for (i = r.xs0 + e + r.xs1, s = 1; r.l > s; s++) i += r[`xn${s}`] + r[`xs${s + 1}`]
                    r.t[r.p] = i
                  }
                } else {
                  r.type === -1
                    ? (r.t[r.p] = r.xs0)
                    : r.setRatio && r.setRatio(t)
                }
              } else r.t[r.p] = e + r.xs0
              r = r._next
            }
          } else {
            for (; r;) {
              r.type !== 2 ? (r.t[r.p] = r.b) : r.setRatio(t),
              (r = r._next)
            }
          }
        } else {
          for (; r;) {
            if (r.type !== 2) {
              if (r.r && r.type !== -1) {
                if (((e = Math.round(r.s + r.c)), r.type)) {
                  if (r.type === 1) {
                    for (
                      s = r.l, i = r.xs0 + e + r.xs1, s = 1;
                      r.l > s;
                      s++
                    ) i += r[`xn${s}`] + r[`xs${s + 1}`]
                    r.t[r.p] = i
                  }
                } else r.t[r.p] = e + r.xs0
              } else r.t[r.p] = r.e
            } else r.setRatio(t)
            r = r._next
          }
        }
      }),
      (h._enableTransforms = function (t) {
        (this._transform = this._transform || Ne(this._target, r, !0)),
        (this._transformType = (this._transform.svg && we) || (!t && this._transformType !== 3)
          ? 2
          : 3)
      })
      const qe = function () {
        (this.t[this.p] = this.e),
        this.data._linkCSSP(this, this._next, null, !0)
      };
      (h._addLazySet = function (t, e, i) {
        const s = (this._firstPT = new me(t, e, 0, 0, this._firstPT, 2));
        (s.e = i), (s.setRatio = qe), (s.data = this)
      }),
      (h._linkCSSP = function (t, e, i, s) {
        return (
          t
                && (e && (e._prev = t),
                t._next && (t._next._prev = t._prev),
                t._prev
                  ? (t._prev._next = t._next)
                  : this._firstPT === t
                    && ((this._firstPT = t._next), (s = !0)),
                i
                  ? (i._next = t)
                  : s || this._firstPT !== null || (this._firstPT = t),
                (t._next = e),
                (t._prev = i)),
          t
        )
      }),
      (h._kill = function (e) {
        let i
        let s
        let r
        let n = e
        if (e.autoAlpha || e.alpha) {
          n = {}
          for (s in e) n[s] = e[s];
          (n.opacity = 1), n.autoAlpha && (n.visibility = 1)
        }
        return (
          e.className
                && (i = this._classNamePT)
                && ((r = i.xfirst),
                r && r._prev
                  ? this._linkCSSP(r._prev, i._next, r._prev._prev)
                  : r === this._firstPT && (this._firstPT = i._next),
                i._next && this._linkCSSP(i._next, i._next._next, r._prev),
                (this._classNamePT = null)),
          t.prototype._kill.call(this, n)
        )
      })
      var Ve = function (t, e, i) {
        let s; let r; let n; let
          a
        if (t.slice) for (r = t.length; --r > -1;) Ve(t[r], e, i)
        else {
          for (s = t.childNodes, r = s.length; --r > -1;) {
            (n = s[r]),
            (a = n.type),
            n.style && (e.push(K(n)), i && i.push(n)),
            (a !== 1 && a !== 9 && a !== 11)
                  || !n.childNodes.length
                  || Ve(n, e, i)
          }
        }
      }
      return (
        (a.cascadeTo = function (t, i, s) {
          let r
          let n
          let a
          let o
          const l = e.to(t, i, s)
          const h = [l]
          const _ = []
          const u = []
          const c = []
          const f = e._internals.reservedProps
          for (
            t = l._targets || l.target,
            Ve(t, _, c),
            l.render(i, !0, !0),
            Ve(t, u),
            l.render(0, !0, !0),
            l._enabled(!0),
            r = c.length;
            --r > -1;

          ) {
            if (((n = J(c[r], _[r], u[r])), n.firstMPT)) {
              n = n.difs
              for (a in s) f[a] && (n[a] = s[a])
              o = {}
              for (a in n) o[a] = _[r][a]
              h.push(e.fromTo(c[r], i, o, n))
            }
          }
          return h
        }),
        t.activate([a]),
        a
      )
    },
    !0,
  ),
  (function () {
    const t = _gsScope._gsDefine.plugin({
      propName: 'roundProps',
      version: '1.5',
      priority: -1,
      API: 2,
      init(t, e, i) {
        return (this._tween = i), !0
      },
    })
    const e = function (t) {
      for (; t;) t.f || t.blob || (t.r = 1), (t = t._next)
    }
    const i = t.prototype;
    (i._onInitAllProps = function () {
      for (
        var t,
          i,
          s,
          r = this._tween,
          n = r.vars.roundProps.join
            ? r.vars.roundProps
            : r.vars.roundProps.split(','),
          a = n.length,
          o = {},
          l = r._propLookup.roundProps;
        --a > -1;

      ) o[n[a]] = 1
      for (a = n.length; --a > -1;) {
        for (t = n[a], i = r._firstPT; i;) {
          (s = i._next),
          i.pg
            ? i.t._roundProps(o, !0)
            : i.n === t
                  && (i.f === 2 && i.t
                    ? e(i.t._firstPT)
                    : (this._add(i.t, t, i.s, i.c),
                    s && (s._prev = i._prev),
                    i._prev
                      ? (i._prev._next = s)
                      : r._firstPT === i && (r._firstPT = s),
                    (i._next = i._prev = null),
                    (r._propLookup[t] = l))),
          (i = s)
        }
      }
      return !1
    }),
    (i._add = function (t, e, i, s) {
      this._addTween(t, e, i, i + s, e, !0), this._overwriteProps.push(e)
    })
  }()),
  (function () {
    _gsScope._gsDefine.plugin({
      propName: 'attr',
      API: 2,
      version: '0.5.0',
      init(t, e) {
        let i
        if (typeof t.setAttribute !== 'function') return !1
        for (i in e) {
          this._addTween(
            t,
            'setAttribute',
            `${t.getAttribute(i)}`,
            `${e[i]}`,
            i,
            !1,
            i,
          ),
          this._overwriteProps.push(i)
        }
        return !0
      },
    })
  }()),
  (_gsScope._gsDefine.plugin({
    propName: 'directionalRotation',
    version: '0.2.1',
    API: 2,
    init(t, e) {
      typeof e !== 'object' && (e = { rotation: e }), (this.finals = {})
      let i
      let s
      let r
      let n
      let a
      let o
      const l = e.useRadians === !0 ? 2 * Math.PI : 360
      const h = 1e-6
      for (i in e) {
        i !== 'useRadians'
            && ((o = (`${e[i]}`).split('_')),
            (s = o[0]),
            (r = parseFloat(
              typeof t[i] !== 'function'
                ? t[i]
                : t[
                  i.indexOf('set')
                    || typeof t[`get${i.substr(3)}`] !== 'function'
                    ? i
                    : `get${i.substr(3)}`
                ](),
            )),
            (n = this.finals[i] = typeof s === 'string' && s.charAt(1) === '='
              ? r + parseInt(`${s.charAt(0)}1`, 10) * Number(s.substr(2))
              : Number(s) || 0),
            (a = n - r),
            o.length
              && ((s = o.join('_')),
              s.indexOf('short') !== -1
                && ((a %= l), a !== a % (l / 2) && (a = a < 0 ? a + l : a - l)),
              s.indexOf('_cw') !== -1 && a < 0
                ? (a = ((a + 9999999999 * l) % l) - (0 | (a / l)) * l)
                : s.indexOf('ccw') !== -1
                  && a > 0
                  && (a = ((a - 9999999999 * l) % l) - (0 | (a / l)) * l)),
            (a > h || -h > a)
              && (this._addTween(t, i, r, r + a, i),
              this._overwriteProps.push(i)))
      }
      return !0
    },
    set(t) {
      let e
      if (t !== 1) this._super.setRatio.call(this, t)
      else {
        for (e = this._firstPT; e;) {
          e.f ? e.t[e.p](this.finals[e.p]) : (e.t[e.p] = this.finals[e.p]),
          (e = e._next)
        }
      }
    },
  })._autoCSS = !0),
  _gsScope._gsDefine(
    'easing.Back',
    ['easing.Ease'],
    t => {
      let e
      let i
      let s
      const r = _gsScope.GreenSockGlobals || _gsScope
      const n = r.com.greensock
      const a = 2 * Math.PI
      const o = Math.PI / 2
      const l = n._class
      const h = function (e, i) {
        const s = l(`easing.${e}`, () => {}, !0)
        const r = (s.prototype = new t())
        return (r.constructor = s), (r.getRatio = i), s
      }
      const _ = t.register || function () {}
      const u = function (t, e, i, s) {
        const r = l(
          `easing.${t}`,
          { easeOut: new e(), easeIn: new i(), easeInOut: new s() },
          !0,
        )
        return _(r, t), r
      }
      const c = function (t, e, i) {
        (this.t = t),
        (this.v = e),
        i
                && ((this.next = i),
                (i.prev = this),
                (this.c = i.v - e),
                (this.gap = i.t - t))
      }
      const f = function (e, i) {
        const s = l(
          `easing.${e}`,
          function (t) {
            (this._p1 = t || t === 0 ? t : 1.70158),
            (this._p2 = 1.525 * this._p1)
          },
          !0,
        )
        const r = (s.prototype = new t())
        return (
          (r.constructor = s),
          (r.getRatio = i),
          (r.config = function (t) {
            return new s(t)
          }),
          s
        )
      }
      const p = u(
        'Back',
        f('BackOut', function (t) {
          return (t -= 1) * t * ((this._p1 + 1) * t + this._p1) + 1
        }),
        f('BackIn', function (t) {
          return t * t * ((this._p1 + 1) * t - this._p1)
        }),
        f('BackInOut', function (t) {
          return (t *= 2) < 1
            ? 0.5 * t * t * ((this._p2 + 1) * t - this._p2)
            : 0.5 * ((t -= 2) * t * ((this._p2 + 1) * t + this._p2) + 2)
        }),
      )
      const m = l(
        'easing.SlowMo',
        function (t, e, i) {
          (e = e || e === 0 ? e : 0.7),
          t == null ? (t = 0.7) : t > 1 && (t = 1),
          (this._p = t !== 1 ? e : 0),
          (this._p1 = (1 - t) / 2),
          (this._p2 = t),
          (this._p3 = this._p1 + this._p2),
          (this._calcEnd = i === !0)
        },
        !0,
      )
      let d = (m.prototype = new t())
      return (
        (d.constructor = m),
        (d.getRatio = function (t) {
          const e = t + (0.5 - t) * this._p
          return this._p1 > t
            ? this._calcEnd
              ? 1 - (t = 1 - t / this._p1) * t
              : e - (t = 1 - t / this._p1) * t * t * t * e
            : t > this._p3
              ? this._calcEnd
                ? 1 - (t = (t - this._p3) / this._p1) * t
                : e + (t - e) * (t = (t - this._p3) / this._p1) * t * t * t
              : this._calcEnd
                ? 1
                : e
        }),
        (m.ease = new m(0.7, 0.7)),
        (d.config = m.config = function (t, e, i) {
          return new m(t, e, i)
        }),
        (e = l(
          'easing.SteppedEase',
          function (t) {
            (t = t || 1), (this._p1 = 1 / t), (this._p2 = t + 1)
          },
          !0,
        )),
        (d = e.prototype = new t()),
        (d.constructor = e),
        (d.getRatio = function (t) {
          return (
            t < 0 ? (t = 0) : t >= 1 && (t = 0.999999999),
            ((this._p2 * t) >> 0) * this._p1
          )
        }),
        (d.config = e.config = function (t) {
          return new e(t)
        }),
        (i = l(
          'easing.RoughEase',
          function (e) {
            e = e || {}
            for (
              var i,
                s,
                r,
                n,
                a,
                o,
                l = e.taper || 'none',
                h = [],
                _ = 0,
                u = 0 | (e.points || 20),
                f = u,
                p = e.randomize !== !1,
                m = e.clamp === !0,
                d = e.template instanceof t ? e.template : null,
                g = typeof e.strength === 'number' ? 0.4 * e.strength : 0.4;
              --f > -1;

            ) {
              (i = p ? Math.random() : (1 / u) * f),
              (s = d ? d.getRatio(i) : i),
              l === 'none'
                ? (r = g)
                : l === 'out'
                  ? ((n = 1 - i), (r = n * n * g))
                  : l === 'in'
                    ? (r = i * i * g)
                    : i < 0.5
                      ? ((n = 2 * i), (r = 0.5 * n * n * g))
                      : ((n = 2 * (1 - i)), (r = 0.5 * n * n * g)),
              p
                ? (s += Math.random() * r - 0.5 * r)
                : f % 2
                  ? (s += 0.5 * r)
                  : (s -= 0.5 * r),
              m && (s > 1 ? (s = 1) : s < 0 && (s = 0)),
              (h[_++] = { x: i, y: s })
            }
            for (
              h.sort((t, e) => t.x - e.x),
              o = new c(1, 1, null),
              f = u;
              --f > -1;

            ) (a = h[f]), (o = new c(a.x, a.y, o))
            this._prev = new c(0, 0, o.t !== 0 ? o : o.next)
          },
          !0,
        )),
        (d = i.prototype = new t()),
        (d.constructor = i),
        (d.getRatio = function (t) {
          let e = this._prev
          if (t > e.t) {
            for (; e.next && t >= e.t;) e = e.next
            e = e.prev
          } else for (; e.prev && e.t >= t;) e = e.prev
          return (this._prev = e), e.v + ((t - e.t) / e.gap) * e.c
        }),
        (d.config = function (t) {
          return new i(t)
        }),
        (i.ease = new i()),
        u(
          'Bounce',
          h('BounceOut', t => (1 / 2.75 > t
            ? 7.5625 * t * t
            : 2 / 2.75 > t
              ? 7.5625 * (t -= 1.5 / 2.75) * t + 0.75
              : 2.5 / 2.75 > t
                ? 7.5625 * (t -= 2.25 / 2.75) * t + 0.9375
                : 7.5625 * (t -= 2.625 / 2.75) * t + 0.984375)),
          h('BounceIn', t => (1 / 2.75 > (t = 1 - t)
            ? 1 - 7.5625 * t * t
            : 2 / 2.75 > t
              ? 1 - (7.5625 * (t -= 1.5 / 2.75) * t + 0.75)
              : 2.5 / 2.75 > t
                ? 1 - (7.5625 * (t -= 2.25 / 2.75) * t + 0.9375)
                : 1 - (7.5625 * (t -= 2.625 / 2.75) * t + 0.984375))),
          h('BounceInOut', t => {
            const e = t < 0.5
            return (
              (t = e ? 1 - 2 * t : 2 * t - 1),
              (t = 1 / 2.75 > t
                ? 7.5625 * t * t
                : 2 / 2.75 > t
                  ? 7.5625 * (t -= 1.5 / 2.75) * t + 0.75
                  : 2.5 / 2.75 > t
                    ? 7.5625 * (t -= 2.25 / 2.75) * t + 0.9375
                    : 7.5625 * (t -= 2.625 / 2.75) * t + 0.984375),
              e ? 0.5 * (1 - t) : 0.5 * t + 0.5
            )
          }),
        ),
        u(
          'Circ',
          h('CircOut', t => Math.sqrt(1 - (t -= 1) * t)),
          h('CircIn', t => -(Math.sqrt(1 - t * t) - 1)),
          h('CircInOut', t => ((t *= 2) < 1
            ? -0.5 * (Math.sqrt(1 - t * t) - 1)
            : 0.5 * (Math.sqrt(1 - (t -= 2) * t) + 1))),
        ),
        (s = function (e, i, s) {
          const r = l(
            `easing.${e}`,
            function (t, e) {
              (this._p1 = t >= 1 ? t : 1),
              (this._p2 = (e || s) / (t < 1 ? t : 1)),
              (this._p3 = (this._p2 / a) * (Math.asin(1 / this._p1) || 0)),
              (this._p2 = a / this._p2)
            },
            !0,
          )
          const n = (r.prototype = new t())
          return (
            (n.constructor = r),
            (n.getRatio = i),
            (n.config = function (t, e) {
              return new r(t, e)
            }),
            r
          )
        }),
        u(
          'Elastic',
          s(
            'ElasticOut',
            function (t) {
              return (
                this._p1
                    * Math.pow(2, -10 * t)
                    * Math.sin((t - this._p3) * this._p2)
                  + 1
              )
            },
            0.3,
          ),
          s(
            'ElasticIn',
            function (t) {
              return -(
                this._p1
                  * Math.pow(2, 10 * (t -= 1))
                  * Math.sin((t - this._p3) * this._p2)
              )
            },
            0.3,
          ),
          s(
            'ElasticInOut',
            function (t) {
              return (t *= 2) < 1
                ? -0.5
                      * this._p1
                      * Math.pow(2, 10 * (t -= 1))
                      * Math.sin((t - this._p3) * this._p2)
                : 0.5
                      * this._p1
                      * Math.pow(2, -10 * (t -= 1))
                      * Math.sin((t - this._p3) * this._p2)
                      + 1
            },
            0.45,
          ),
        ),
        u(
          'Expo',
          h('ExpoOut', t => 1 - Math.pow(2, -10 * t)),
          h('ExpoIn', t => Math.pow(2, 10 * (t - 1)) - 0.001),
          h('ExpoInOut', t => ((t *= 2) < 1
            ? 0.5 * Math.pow(2, 10 * (t - 1))
            : 0.5 * (2 - Math.pow(2, -10 * (t - 1))))),
        ),
        u(
          'Sine',
          h('SineOut', t => Math.sin(t * o)),
          h('SineIn', t => -Math.cos(t * o) + 1),
          h('SineInOut', t => -0.5 * (Math.cos(Math.PI * t) - 1)),
        ),
        l(
          'easing.EaseLookup',
          {
            find(e) {
              return t.map[e]
            },
          },
          !0,
        ),
        _(r.SlowMo, 'SlowMo', 'ease,'),
        _(i, 'RoughEase', 'ease,'),
        _(e, 'SteppedEase', 'ease,'),
        p
      )
    },
    !0,
  )
}),
_gsScope._gsDefine && _gsScope._gsQueue.pop()(),
(function (t, e) {
  const i = (t.GreenSockGlobals = t.GreenSockGlobals || t)
  if (!i.TweenLite) {
    let s
    let r
    let n
    let a
    let o
    const l = function (t) {
      let e
      const s = t.split('.')
      let r = i
      for (e = 0; s.length > e; e++) r[s[e]] = r = r[s[e]] || {}
      return r
    }
    const h = l('com.greensock')
    const _ = 1e-10
    const u = function (t) {
      let e
      const i = []
      const s = t.length
      for (e = 0; e !== s; i.push(t[e++]));
      return i
    }
    const c = function () {}
    const f = (function () {
      const t = Object.prototype.toString
      const e = t.call([])
      return function (i) {
        return (
          i != null
              && (i instanceof Array
                || (typeof i === 'object' && !!i.push && t.call(i) === e))
        )
      }
    }())
    const p = {}
    var m = function (s, r, n, a) {
      (this.sc = p[s] ? p[s].sc : []),
      (p[s] = this),
      (this.gsClass = null),
      (this.func = n)
      const o = [];
      (this.check = function (h) {
        for (var _, u, c, f, d, g = r.length, v = g; --g > -1;) {
          (_ = p[r[g]] || new m(r[g], [])).gsClass
            ? ((o[g] = _.gsClass), v--)
            : h && _.sc.push(this)
        }
        if (v === 0 && n) {
          for (
            u = (`com.greensock.${s}`).split('.'),
            c = u.pop(),
            f = l(u.join('.'))[c] = this.gsClass = n.apply(n, o),
            a
                    && ((i[c] = f),
                    (d = typeof module !== 'undefined' && module.exports),
                    !d && typeof define === 'function' && define.amd
                      ? define(
                        (t.GreenSockAMDPath ? `${t.GreenSockAMDPath}/` : '')
                            + s.split('.').pop(),
                        [],
                        () => f,
                      )
                      : s === e && d && (module.exports = f)),
            g = 0;
            this.sc.length > g;
            g++
          ) this.sc[g].check()
        }
      }),
      this.check(!0)
    }
    const d = (t._gsDefine = function (t, e, i, s) {
      return new m(t, e, i, s)
    })
    const g = (h._class = function (t, e, i) {
      return (
        (e = e || function () {}),
        d(
          t,
          [],
          () => e,
          i,
        ),
        e
      )
    })
    d.globals = i
    const v = [0, 0, 1, 1]
    const y = []
    const T = g(
      'easing.Ease',
      function (t, e, i, s) {
        (this._func = t),
        (this._type = i || 0),
        (this._power = s || 0),
        (this._params = e ? v.concat(e) : v)
      },
      !0,
    )
    const x = (T.map = {})
    const w = (T.register = function (t, e, i, s) {
      for (
        var r,
          n,
          a,
          o,
          l = e.split(','),
          _ = l.length,
          u = (i || 'easeIn,easeOut,easeInOut').split(',');
        --_ > -1;

      ) {
        for (
          n = l[_],
          r = s ? g(`easing.${n}`, null, !0) : h.easing[n] || {},
          a = u.length;
          --a > -1;

        ) {
          (o = u[a]),
          (x[`${n}.${o}`] = x[o + n] = r[o] = t.getRatio ? t : t[o] || new t())
        }
      }
    })
    for (
      n = T.prototype,
      n._calcEnd = !1,
      n.getRatio = function (t) {
        if (this._func) {
          return (
            (this._params[0] = t), this._func.apply(null, this._params)
          )
        }
        const e = this._type
        const i = this._power
        let s = e === 1 ? 1 - t : e === 2 ? t : t < 0.5 ? 2 * t : 2 * (1 - t)
        return (
          i === 1
            ? (s *= s)
            : i === 2
              ? (s *= s * s)
              : i === 3
                ? (s *= s * s * s)
                : i === 4 && (s *= s * s * s * s),
          e === 1 ? 1 - s : e === 2 ? s : t < 0.5 ? s / 2 : 1 - s / 2
        )
      },
      s = ['Linear', 'Quad', 'Cubic', 'Quart', 'Quint,Strong'],
      r = s.length;
      --r > -1;

    ) {
      (n = `${s[r]},Power${r}`),
      w(new T(null, null, 1, r), n, 'easeOut', !0),
      w(
        new T(null, null, 2, r),
        n,
        `easeIn${r === 0 ? ',easeNone' : ''}`,
      ),
      w(new T(null, null, 3, r), n, 'easeInOut')
    }
    (x.linear = h.easing.Linear.easeIn), (x.swing = h.easing.Quad.easeInOut)
    const b = g('events.EventDispatcher', function (t) {
      (this._listeners = {}), (this._eventTarget = t || this)
    });
    (n = b.prototype),
    (n.addEventListener = function (t, e, i, s, r) {
      r = r || 0
      let n
      let l
      let h = this._listeners[t]
      let _ = 0
      for (
        h == null && (this._listeners[t] = h = []), l = h.length;
        --l > -1;

      ) {
        (n = h[l]),
        n.c === e && n.s === i
          ? h.splice(l, 1)
          : _ === 0 && r > n.pr && (_ = l + 1)
      }
      h.splice(_, 0, {
        c: e, s: i, up: s, pr: r,
      }),
      this !== a || o || a.wake()
    }),
    (n.removeEventListener = function (t, e) {
      let i
      const s = this._listeners[t]
      if (s) { for (i = s.length; --i > -1;) if (s[i].c === e) return s.splice(i, 1), void 0 }
    }),
    (n.dispatchEvent = function (t) {
      let e
      let i
      let s
      const r = this._listeners[t]
      if (r) {
        for (e = r.length, i = this._eventTarget; --e > -1;) {
          (s = r[e]),
          s
                  && (s.up
                    ? s.c.call(s.s || i, { type: t, target: i })
                    : s.c.call(s.s || i))
        }
      }
    })
    let P = t.requestAnimationFrame
    let k = t.cancelAnimationFrame
    const S = Date.now
          || function () {
            return new Date().getTime()
          }
    let R = S()
    for (s = ['ms', 'moz', 'webkit', 'o'], r = s.length; --r > -1 && !P;) {
      (P = t[`${s[r]}RequestAnimationFrame`]),
      (k = t[`${s[r]}CancelAnimationFrame`]
            || t[`${s[r]}CancelRequestAnimationFrame`])
    }
    g('Ticker', function (t, e) {
      let i
      let s
      let r
      let n
      let l
      const h = this
      let u = S()
      let f = e !== !1 && P
      let p = 500
      let m = 33
      const d = 'tick'
      var g = function (t) {
        let e
        let a
        const o = S() - R
        o > p && (u += o - m),
        (R += o),
        (h.time = (R - u) / 1e3),
        (e = h.time - l),
        (!i || e > 0 || t === !0)
                && (h.frame++, (l += e + (e >= n ? 0.004 : n - e)), (a = !0)),
        t !== !0 && (r = s(g)),
        a && h.dispatchEvent(d)
      }
      b.call(h),
      (h.time = h.frame = 0),
      (h.tick = function () {
        g(!0)
      }),
      (h.lagSmoothing = function (t, e) {
        (p = t || 1 / _), (m = Math.min(e, p, 0))
      }),
      (h.sleep = function () {
        r != null
              && (f && k ? k(r) : clearTimeout(r),
              (s = c),
              (r = null),
              h === a && (o = !1))
      }),
      (h.wake = function () {
        r !== null ? h.sleep() : h.frame > 10 && (R = S() - p + 5),
        (s = i === 0
          ? c
          : f && P
            ? P
            : function (t) {
              return setTimeout(t, 0 | (1e3 * (l - h.time) + 1))
            }),
        h === a && (o = !0),
        g(2)
      }),
      (h.fps = function (t) {
        return arguments.length
          ? ((i = t),
          (n = 1 / (i || 60)),
          (l = this.time + n),
          h.wake(),
          void 0)
          : i
      }),
      (h.useRAF = function (t) {
        return arguments.length
          ? (h.sleep(), (f = t), h.fps(i), void 0)
          : f
      }),
      h.fps(t),
      setTimeout(() => {
        f && h.frame < 5 && h.useRAF(!1)
      }, 1500)
    }),
    (n = h.Ticker.prototype = new h.events.EventDispatcher()),
    (n.constructor = h.Ticker)
    const O = g('core.Animation', function (t, e) {
      if (
        ((this.vars = e = e || {}),
        (this._duration = this._totalDuration = t || 0),
        (this._delay = Number(e.delay) || 0),
        (this._timeScale = 1),
        (this._active = e.immediateRender === !0),
        (this.data = e.data),
        (this._reversed = e.reversed === !0),
        W)
      ) {
        o || a.wake()
        const i = this.vars.useFrames ? G : W
        i.add(this, i._time), this.vars.paused && this.paused(!0)
      }
    });
    (a = O.ticker = new h.Ticker()),
    (n = O.prototype),
    (n._dirty = n._gc = n._initted = n._paused = !1),
    (n._totalTime = n._time = 0),
    (n._rawPrevTime = -1),
    (n._next = n._last = n._onUpdate = n._timeline = n.timeline = null),
    (n._paused = !1)
    var A = function () {
      o && S() - R > 2e3 && a.wake(), setTimeout(A, 2e3)
    }
    A(),
    (n.play = function (t, e) {
      return t != null && this.seek(t, e), this.reversed(!1).paused(!1)
    }),
    (n.pause = function (t, e) {
      return t != null && this.seek(t, e), this.paused(!0)
    }),
    (n.resume = function (t, e) {
      return t != null && this.seek(t, e), this.paused(!1)
    }),
    (n.seek = function (t, e) {
      return this.totalTime(Number(t), e !== !1)
    }),
    (n.restart = function (t, e) {
      return this.reversed(!1)
        .paused(!1)
        .totalTime(t ? -this._delay : 0, e !== !1, !0)
    }),
    (n.reverse = function (t, e) {
      return (
        t != null && this.seek(t || this.totalDuration(), e),
        this.reversed(!0).paused(!1)
      )
    }),
    (n.render = function () {}),
    (n.invalidate = function () {
      return (
        (this._time = this._totalTime = 0),
        (this._initted = this._gc = !1),
        (this._rawPrevTime = -1),
        (this._gc || !this.timeline) && this._enabled(!0),
        this
      )
    }),
    (n.isActive = function () {
      let t
      const e = this._timeline
      const i = this._startTime
      return (
        !e
            || (!this._gc
              && !this._paused
              && e.isActive()
              && (t = e.rawTime()) >= i
              && i + this.totalDuration() / this._timeScale > t)
      )
    }),
    (n._enabled = function (t, e) {
      return (
        o || a.wake(),
        (this._gc = !t),
        (this._active = this.isActive()),
        e !== !0
              && (t && !this.timeline
                ? this._timeline.add(this, this._startTime - this._delay)
                : !t && this.timeline && this._timeline._remove(this, !0)),
        !1
      )
    }),
    (n._kill = function () {
      return this._enabled(!1, !1)
    }),
    (n.kill = function (t, e) {
      return this._kill(t, e), this
    }),
    (n._uncache = function (t) {
      for (let e = t ? this : this.timeline; e;) { (e._dirty = !0), (e = e.timeline) }
      return this
    }),
    (n._swapSelfInParams = function (t) {
      for (var e = t.length, i = t.concat(); --e > -1;) t[e] === '{self}' && (i[e] = this)
      return i
    }),
    (n._callback = function (t) {
      const e = this.vars
      e[t].apply(
        e[`${t}Scope`] || e.callbackScope || this,
        e[`${t}Params`] || y,
      )
    }),
    (n.eventCallback = function (t, e, i, s) {
      if ((t || '').substr(0, 2) === 'on') {
        const r = this.vars
        if (arguments.length === 1) return r[t]
        e == null
          ? delete r[t]
          : ((r[t] = e),
          (r[`${t}Params`] = f(i) && i.join('').indexOf('{self}') !== -1
            ? this._swapSelfInParams(i)
            : i),
          (r[`${t}Scope`] = s)),
        t === 'onUpdate' && (this._onUpdate = e)
      }
      return this
    }),
    (n.delay = function (t) {
      return arguments.length
        ? (this._timeline.smoothChildTiming
                && this.startTime(this._startTime + t - this._delay),
        (this._delay = t),
        this)
        : this._delay
    }),
    (n.duration = function (t) {
      return arguments.length
        ? ((this._duration = this._totalDuration = t),
        this._uncache(!0),
        this._timeline.smoothChildTiming
                && this._time > 0
                && this._time < this._duration
                && t !== 0
                && this.totalTime(this._totalTime * (t / this._duration), !0),
        this)
        : ((this._dirty = !1), this._duration)
    }),
    (n.totalDuration = function (t) {
      return (
        (this._dirty = !1),
        arguments.length ? this.duration(t) : this._totalDuration
      )
    }),
    (n.time = function (t, e) {
      return arguments.length
        ? (this._dirty && this.totalDuration(),
        this.totalTime(t > this._duration ? this._duration : t, e))
        : this._time
    }),
    (n.totalTime = function (t, e, i) {
      if ((o || a.wake(), !arguments.length)) return this._totalTime
      if (this._timeline) {
        if (
          (t < 0 && !i && (t += this.totalDuration()),
          this._timeline.smoothChildTiming)
        ) {
          this._dirty && this.totalDuration()
          const s = this._totalDuration
          let r = this._timeline
          if (
            (t > s && !i && (t = s),
            (this._startTime = (this._paused ? this._pauseTime : r._time)
                  - (this._reversed ? s - t : t) / this._timeScale),
            r._dirty || this._uncache(!1),
            r._timeline)
          ) {
            for (; r._timeline;) {
              r._timeline._time
                    !== (r._startTime + r._totalTime) / r._timeScale
                    && r.totalTime(r._totalTime, !0),
              (r = r._timeline)
            }
          }
        }
        this._gc && this._enabled(!0, !1),
        (this._totalTime !== t || this._duration === 0)
                && (F.length && Q(), this.render(t, e, !1), F.length && Q())
      }
      return this
    }),
    (n.progress = n.totalProgress = function (t, e) {
      const i = this.duration()
      return arguments.length
        ? this.totalTime(i * t, e)
        : i
          ? this._time / i
          : this.ratio
    }),
    (n.startTime = function (t) {
      return arguments.length
        ? (t !== this._startTime
                && ((this._startTime = t),
                this.timeline
                  && this.timeline._sortChildren
                  && this.timeline.add(this, t - this._delay)),
        this)
        : this._startTime
    }),
    (n.endTime = function (t) {
      return (
        this._startTime
            + (t != 0 ? this.totalDuration() : this.duration()) / this._timeScale
      )
    }),
    (n.timeScale = function (t) {
      if (!arguments.length) return this._timeScale
      if (
        ((t = t || _), this._timeline && this._timeline.smoothChildTiming)
      ) {
        const e = this._pauseTime
        const i = e || e === 0 ? e : this._timeline.totalTime()
        this._startTime = i - ((i - this._startTime) * this._timeScale) / t
      }
      return (this._timeScale = t), this._uncache(!1)
    }),
    (n.reversed = function (t) {
      return arguments.length
        ? (t != this._reversed
                && ((this._reversed = t),
                this.totalTime(
                  this._timeline && !this._timeline.smoothChildTiming
                    ? this.totalDuration() - this._totalTime
                    : this._totalTime,
                  !0,
                )),
        this)
        : this._reversed
    }),
    (n.paused = function (t) {
      if (!arguments.length) return this._paused
      let e
      let i
      const s = this._timeline
      return (
        t != this._paused
              && s
              && (o || t || a.wake(),
              (e = s.rawTime()),
              (i = e - this._pauseTime),
              !t
                && s.smoothChildTiming
                && ((this._startTime += i), this._uncache(!1)),
              (this._pauseTime = t ? e : null),
              (this._paused = t),
              (this._active = this.isActive()),
              !t
                && i !== 0
                && this._initted
                && this.duration()
                && ((e = s.smoothChildTiming
                  ? this._totalTime
                  : (e - this._startTime) / this._timeScale),
                this.render(e, e === this._totalTime, !0))),
        this._gc && !t && this._enabled(!0, !1),
        this
      )
    })
    const C = g('core.SimpleTimeline', function (t) {
      O.call(this, 0, t),
      (this.autoRemoveChildren = this.smoothChildTiming = !0)
    });
    (n = C.prototype = new O()),
    (n.constructor = C),
    (n.kill()._gc = !1),
    (n._first = n._last = n._recent = null),
    (n._sortChildren = !1),
    (n.add = n.insert = function (t, e) {
      let i; let
        s
      if (
        ((t._startTime = Number(e || 0) + t._delay),
        t._paused
                && this !== t._timeline
                && (t._pauseTime = t._startTime
                  + (this.rawTime() - t._startTime) / t._timeScale),
        t.timeline && t.timeline._remove(t, !0),
        (t.timeline = t._timeline = this),
        t._gc && t._enabled(!0, !0),
        (i = this._last),
        this._sortChildren)
      ) { for (s = t._startTime; i && i._startTime > s;) i = i._prev }
      return (
        i
          ? ((t._next = i._next), (i._next = t))
          : ((t._next = this._first), (this._first = t)),
        t._next ? (t._next._prev = t) : (this._last = t),
        (t._prev = i),
        (this._recent = t),
        this._timeline && this._uncache(!0),
        this
      )
    }),
    (n._remove = function (t, e) {
      return (
        t.timeline === this
              && (e || t._enabled(!1, !0),
              t._prev
                ? (t._prev._next = t._next)
                : this._first === t && (this._first = t._next),
              t._next
                ? (t._next._prev = t._prev)
                : this._last === t && (this._last = t._prev),
              (t._next = t._prev = t.timeline = null),
              t === this._recent && (this._recent = this._last),
              this._timeline && this._uncache(!0)),
        this
      )
    }),
    (n.render = function (t, e, i) {
      let s
      let r = this._first
      for (this._totalTime = this._time = this._rawPrevTime = t; r;) {
        (s = r._next),
        (r._active || (t >= r._startTime && !r._paused))
                && (r._reversed
                  ? r.render(
                    (r._dirty ? r.totalDuration() : r._totalDuration)
                        - (t - r._startTime) * r._timeScale,
                    e,
                    i,
                  )
                  : r.render((t - r._startTime) * r._timeScale, e, i)),
        (r = s)
      }
    }),
    (n.rawTime = function () {
      return o || a.wake(), this._totalTime
    })
    var D = g(
      'TweenLite',
      function (e, i, s) {
        if (
          (O.call(this, i, s),
          (this.render = D.prototype.render),
          e == null)
        ) { throw 'Cannot tween a null target.' }
        this.target = e = typeof e !== 'string' ? e : D.selector(e) || e
        let r
        let n
        let a
        const o = e.jquery
                || (e.length
                  && e !== t
                  && e[0]
                  && (e[0] === t || (e[0].nodeType && e[0].style && !e.nodeType)))
        let l = this.vars.overwrite
        if (
          ((this._overwrite = l = l == null
            ? V[D.defaultOverwrite]
            : typeof l === 'number'
              ? l >> 0
              : V[l]),
          (o || e instanceof Array || (e.push && f(e)))
                && typeof e[0] !== 'number')
        ) {
          for (
            this._targets = a = u(e),
            this._propLookup = [],
            this._siblings = [],
            r = 0;
            a.length > r;
            r++
          ) {
            (n = a[r]),
            n
              ? typeof n !== 'string'
                ? n.length
                        && n !== t
                        && n[0]
                        && (n[0] === t
                          || (n[0].nodeType && n[0].style && !n.nodeType))
                  ? (a.splice(r--, 1),
                  (this._targets = a = a.concat(u(n))))
                  : ((this._siblings[r] = $(n, this, !1)),
                  l === 1
                            && this._siblings[r].length > 1
                            && K(n, this, null, 1, this._siblings[r]))
                : ((n = a[r--] = D.selector(n)),
                typeof n === 'string' && a.splice(r + 1, 1))
              : a.splice(r--, 1)
          }
        } else {
          (this._propLookup = {}),
          (this._siblings = $(e, this, !1)),
          l === 1
                  && this._siblings.length > 1
                  && K(e, this, null, 1, this._siblings)
        }
        (this.vars.immediateRender
              || (i === 0
                && this._delay === 0
                && this.vars.immediateRender !== !1))
              && ((this._time = -_), this.render(-this._delay))
      },
      !0,
    )
    const M = function (e) {
      return (
        e
            && e.length
            && e !== t
            && e[0]
            && (e[0] === t || (e[0].nodeType && e[0].style && !e.nodeType))
      )
    }
    const z = function (t, e) {
      let i
      const s = {}
      for (i in t) {
        q[i]
              || (i in e
                && i !== 'transform'
                && i !== 'x'
                && i !== 'y'
                && i !== 'width'
                && i !== 'height'
                && i !== 'className'
                && i !== 'border')
              || !(!j[i] || (j[i] && j[i]._autoCSS))
              || ((s[i] = t[i]), delete t[i])
      }
      t.css = s
    };
    (n = D.prototype = new O()),
    (n.constructor = D),
    (n.kill()._gc = !1),
    (n.ratio = 0),
    (n._firstPT = n._targets = n._overwrittenProps = n._startAt = null),
    (n._notifyPluginsOfEnabled = n._lazy = !1),
    (D.version = '1.18.0'),
    (D.defaultEase = n._ease = new T(null, null, 1, 1)),
    (D.defaultOverwrite = 'auto'),
    (D.ticker = a),
    (D.autoSleep = 120),
    (D.lagSmoothing = function (t, e) {
      a.lagSmoothing(t, e)
    }),
    (D.selector = t.$
          || t.jQuery
          || function (e) {
            const i = t.$ || t.jQuery
            return i
              ? ((D.selector = i), i(e))
              : typeof document === 'undefined'
                ? e
                : document.querySelectorAll
                  ? document.querySelectorAll(e)
                  : document.getElementById(e.charAt(0) === '#' ? e.substr(1) : e)
          })
    var F = []
    let I = {}
    const E = /(?:(-|-=|\+=)?\d*\.?\d*(?:e[\-+]?\d+)?)[0-9]/gi
    const N = function (t) {
      for (var e, i = this._firstPT, s = 1e-6; i;) {
        (e = i.blob ? (t ? this.join('') : this.start) : i.c * t + i.s),
        i.r ? (e = Math.round(e)) : s > e && e > -s && (e = 0),
        i.f ? (i.fp ? i.t[i.p](i.fp, e) : i.t[i.p](e)) : (i.t[i.p] = e),
        (i = i._next)
      }
    }
    const L = function (t, e, i, s) {
      let r
      let n
      let a
      let o
      let l
      let h
      let _
      const u = [t, e]
      let c = 0
      let f = ''
      let p = 0
      for (
        u.start = t,
        i && (i(u), (t = u[0]), (e = u[1])),
        u.length = 0,
        r = t.match(E) || [],
        n = e.match(E) || [],
        s && ((s._next = null), (s.blob = 1), (u._firstPT = s)),
        l = n.length,
        o = 0;
        l > o;
        o++
      ) {
        (_ = n[o]),
        (h = e.substr(c, e.indexOf(_, c) - c)),
        (f += h || !o ? h : ','),
        (c += h.length),
        p ? (p = (p + 1) % 5) : h.substr(-5) === 'rgba(' && (p = 1),
        _ === r[o] || o >= r.length
          ? (f += _)
          : (f && (u.push(f), (f = '')),
          (a = parseFloat(r[o])),
          u.push(a),
          (u._firstPT = {
            _next: u._firstPT,
            t: u,
            p: u.length - 1,
            s: a,
            c:
                      (_.charAt(1) === '='
                        ? parseInt(`${_.charAt(0)}1`, 10)
                          * parseFloat(_.substr(2))
                        : parseFloat(_) - a) || 0,
            f: 0,
            r: p && p < 4,
          })),
        (c += _.length)
      }
      return (f += e.substr(c)), f && u.push(f), (u.setRatio = N), u
    }
    const X = function (t, e, i, s, r, n, a, o) {
      let l
      let h
      let _ = i === 'get' ? t[e] : i
      const u = typeof t[e]
      const c = typeof s === 'string' && s.charAt(1) === '='
      let f = {
        t,
        p: e,
        s: _,
        f: u === 'function',
        pg: 0,
        n: r || e,
        r: n,
        pr: 0,
        c: c
          ? parseInt(`${s.charAt(0)}1`, 10) * parseFloat(s.substr(2))
          : parseFloat(s) - _ || 0,
      }
      return (
        u !== 'number'
              && (u === 'function'
                && i === 'get'
                && ((h = e.indexOf('set')
                  || typeof t[`get${e.substr(3)}`] !== 'function'
                  ? e
                  : `get${e.substr(3)}`),
                (f.s = _ = a ? t[h](a) : t[h]())),
              typeof _ === 'string' && (a || isNaN(_))
                ? ((f.fp = a),
                (l = L(_, s, o || D.defaultStringFilter, f)),
                (f = {
                  t: l,
                  p: 'setRatio',
                  s: 0,
                  c: 1,
                  f: 2,
                  pg: 0,
                  n: r || e,
                  pr: 0,
                }))
                : c || (f.c = parseFloat(s) - parseFloat(_) || 0)),
        f.c
          ? ((f._next = this._firstPT) && (f._next._prev = f),
          (this._firstPT = f),
          f)
          : void 0
      )
    }
    const B = (D._internals = {
      isArray: f,
      isSelector: M,
      lazyTweens: F,
      blobDif: L,
    })
    var j = (D._plugins = {})
    const Y = (B.tweenLookup = {})
    let U = 0
    var q = (B.reservedProps = {
      ease: 1,
      delay: 1,
      overwrite: 1,
      onComplete: 1,
      onCompleteParams: 1,
      onCompleteScope: 1,
      useFrames: 1,
      runBackwards: 1,
      startAt: 1,
      onUpdate: 1,
      onUpdateParams: 1,
      onUpdateScope: 1,
      onStart: 1,
      onStartParams: 1,
      onStartScope: 1,
      onReverseComplete: 1,
      onReverseCompleteParams: 1,
      onReverseCompleteScope: 1,
      onRepeat: 1,
      onRepeatParams: 1,
      onRepeatScope: 1,
      easeParams: 1,
      yoyo: 1,
      immediateRender: 1,
      repeat: 1,
      repeatDelay: 1,
      data: 1,
      paused: 1,
      reversed: 1,
      autoCSS: 1,
      lazy: 1,
      onOverwrite: 1,
      callbackScope: 1,
      stringFilter: 1,
    })
    var V = {
      none: 0,
      all: 1,
      auto: 2,
      concurrent: 3,
      allOnStart: 4,
      preexisting: 5,
      true: 1,
      false: 0,
    }
    var G = (O._rootFramesTimeline = new C())
    var W = (O._rootTimeline = new C())
    let Z = 30
    var Q = (B.lazyRender = function () {
      let t
      let e = F.length
      for (I = {}; --e > -1;) {
        (t = F[e]),
        t
                && t._lazy !== !1
                && (t.render(t._lazy[0], t._lazy[1], !0), (t._lazy = !1))
      }
      F.length = 0
    });
    (W._startTime = a.time),
    (G._startTime = a.frame),
    (W._active = G._active = !0),
    setTimeout(Q, 1),
    (O._updateRoot = D.render = function () {
      let t; let e; let
        i
      if (
        (F.length && Q(),
        W.render((a.time - W._startTime) * W._timeScale, !1, !1),
        G.render((a.frame - G._startTime) * G._timeScale, !1, !1),
        F.length && Q(),
        a.frame >= Z)
      ) {
        Z = a.frame + (parseInt(D.autoSleep, 10) || 120)
        for (i in Y) {
          for (e = Y[i].tweens, t = e.length; --t > -1;) { e[t]._gc && e.splice(t, 1) }
          e.length === 0 && delete Y[i]
        }
        if (
          ((i = W._first),
          (!i || i._paused)
                  && D.autoSleep
                  && !G._first
                  && a._listeners.tick.length === 1)
        ) {
          for (; i && i._paused;) i = i._next
          i || a.sleep()
        }
      }
    }),
    a.addEventListener('tick', O._updateRoot)
    var $ = function (t, e, i) {
      let s
      let r
      let n = t._gsTweenID
      if (
        (Y[n || (t._gsTweenID = n = `t${U++}`)]
              || (Y[n] = { target: t, tweens: [] }),
        e && ((s = Y[n].tweens), (s[(r = s.length)] = e), i))
      ) { for (; --r > -1;) s[r] === e && s.splice(r, 1) }
      return Y[n].tweens
    }
    const H = function (t, e, i, s) {
      let r
      let n
      let a = t.vars.onOverwrite
      return (
        a && (r = a(t, e, i, s)),
        (a = D.onOverwrite),
        a && (n = a(t, e, i, s)),
        r !== !1 && n !== !1
      )
    }
    var K = function (t, e, i, s, r) {
      let n; let a; let o; let
        l
      if (s === 1 || s >= 4) {
        for (l = r.length, n = 0; l > n; n++) {
          if ((o = r[n]) !== e) o._gc || (o._kill(null, t, e) && (a = !0))
          else if (s === 5) break
        }
        return a
      }
      let h
      const u = e._startTime + _
      const c = []
      let f = 0
      const p = e._duration === 0
      for (n = r.length; --n > -1;) {
        (o = r[n]) === e
              || o._gc
              || o._paused
              || (o._timeline !== e._timeline
                ? ((h = h || J(e, 0, p)), J(o, h, p) === 0 && (c[f++] = o))
                : u >= o._startTime
                  && o._startTime + o.totalDuration() / o._timeScale > u
                  && (((p || !o._initted) && u - o._startTime <= 2e-10)
                    || (c[f++] = o)))
      }
      for (n = f; --n > -1;) {
        if (
          ((o = c[n]),
          s === 2 && o._kill(i, t, e) && (a = !0),
          s !== 2 || (!o._firstPT && o._initted))
        ) {
          if (s !== 2 && !H(o, e)) continue
          o._enabled(!1, !1) && (a = !0)
        }
      }
      return a
    }
    var J = function (t, e, i) {
      for (
        var s = t._timeline, r = s._timeScale, n = t._startTime;
        s._timeline;

      ) {
        if (((n += s._startTime), (r *= s._timeScale), s._paused)) { return -100 }
        s = s._timeline
      }
      return (
        (n /= r),
        n > e
          ? n - e
          : (i && n === e) || (!t._initted && 2 * _ > n - e)
            ? _
            : (n += t.totalDuration() / t._timeScale / r) > e + _
              ? 0
              : n - e - _
      )
    };
    (n._init = function () {
      let t
      let e
      let i
      let s
      let r
      const n = this.vars
      const a = this._overwrittenProps
      const o = this._duration
      let l = !!n.immediateRender
      let h = n.ease
      if (n.startAt) {
        this._startAt && (this._startAt.render(-1, !0), this._startAt.kill()),
        (r = {})
        for (s in n.startAt) r[s] = n.startAt[s]
        if (
          ((r.overwrite = !1),
          (r.immediateRender = !0),
          (r.lazy = l && n.lazy !== !1),
          (r.startAt = r.delay = null),
          (this._startAt = D.to(this.target, 0, r)),
          l)
        ) {
          if (this._time > 0) this._startAt = null
          else if (o !== 0) return
        }
      } else if (n.runBackwards && o !== 0) {
        if (this._startAt) {
          this._startAt.render(-1, !0),
          this._startAt.kill(),
          (this._startAt = null)
        } else {
          this._time !== 0 && (l = !1), (i = {})
          for (s in n) (q[s] && s !== 'autoCSS') || (i[s] = n[s])
          if (
            ((i.overwrite = 0),
            (i.data = 'isFromStart'),
            (i.lazy = l && n.lazy !== !1),
            (i.immediateRender = l),
            (this._startAt = D.to(this.target, 0, i)),
            l)
          ) {
            if (this._time === 0) return
          } else {
            this._startAt._init(),
            this._startAt._enabled(!1),
            this.vars.immediateRender && (this._startAt = null)
          }
        }
      }
      if (
        ((this._ease = h = h
          ? h instanceof T
            ? h
            : typeof h === 'function'
              ? new T(h, n.easeParams)
              : x[h] || D.defaultEase
          : D.defaultEase),
        n.easeParams instanceof Array
            && h.config
            && (this._ease = h.config.apply(h, n.easeParams)),
        (this._easeType = this._ease._type),
        (this._easePower = this._ease._power),
        (this._firstPT = null),
        this._targets)
      ) {
        for (t = this._targets.length; --t > -1;) {
          this._initProps(
            this._targets[t],
            (this._propLookup[t] = {}),
            this._siblings[t],
            a ? a[t] : null,
          ) && (e = !0)
        }
      } else { e = this._initProps(this.target, this._propLookup, this._siblings, a) }
      if (
        (e && D._onPluginEvent('_onInitAllProps', this),
        a
            && (this._firstPT
              || (typeof this.target !== 'function' && this._enabled(!1, !1))),
        n.runBackwards)
      ) { for (i = this._firstPT; i;) (i.s += i.c), (i.c = -i.c), (i = i._next) }
      (this._onUpdate = n.onUpdate), (this._initted = !0)
    }),
    (n._initProps = function (e, i, s, r) {
      let n; let a; let o; let l; let h; let
        _
      if (e == null) return !1
      I[e._gsTweenID] && Q(),
      this.vars.css
              || (e.style
                && e !== t
                && e.nodeType
                && j.css
                && this.vars.autoCSS !== !1
                && z(this.vars, e))
      for (n in this.vars) {
        if (((_ = this.vars[n]), q[n])) {
          _
                && (_ instanceof Array || (_.push && f(_)))
                && _.join('').indexOf('{self}') !== -1
                && (this.vars[n] = _ = this._swapSelfInParams(_, this))
        } else if (
          j[n]
              && (l = new j[n]())._onInitTween(e, this.vars[n], this)
        ) {
          for (
            this._firstPT = h = {
              _next: this._firstPT,
              t: l,
              p: 'setRatio',
              s: 0,
              c: 1,
              f: 1,
              n,
              pg: 1,
              pr: l._priority,
            },
            a = l._overwriteProps.length;
            --a > -1;

          ) i[l._overwriteProps[a]] = this._firstPT;
          (l._priority || l._onInitAllProps) && (o = !0),
          (l._onDisable || l._onEnable)
                  && (this._notifyPluginsOfEnabled = !0),
          h._next && (h._next._prev = h)
        } else {
          i[n] = X.call(
            this,
            e,
            n,
            'get',
            _,
            n,
            0,
            null,
            this.vars.stringFilter,
          )
        }
      }
      return r && this._kill(r, e)
        ? this._initProps(e, i, s, r)
        : this._overwrite > 1
              && this._firstPT
              && s.length > 1
              && K(e, this, i, this._overwrite, s)
          ? (this._kill(i, e), this._initProps(e, i, s, r))
          : (this._firstPT
                && ((this.vars.lazy !== !1 && this._duration)
                  || (this.vars.lazy && !this._duration))
                && (I[e._gsTweenID] = !0),
          o)
    }),
    (n.render = function (t, e, i) {
      let s
      let r
      let n
      let a
      const o = this._time
      const l = this._duration
      const h = this._rawPrevTime
      if (t >= l) {
        (this._totalTime = this._time = l),
        (this.ratio = this._ease._calcEnd ? this._ease.getRatio(1) : 1),
        this._reversed
                || ((s = !0),
                (r = 'onComplete'),
                (i = i || this._timeline.autoRemoveChildren)),
        l === 0
                && (this._initted || !this.vars.lazy || i)
                && (this._startTime === this._timeline._duration && (t = 0),
                (t === 0 || h < 0 || (h === _ && this.data !== 'isPause'))
                  && h !== t
                  && ((i = !0), h > _ && (r = 'onReverseComplete')),
                (this._rawPrevTime = a = !e || t || h === t ? t : _))
      } else if (t < 1e-7) {
        (this._totalTime = this._time = 0),
        (this.ratio = this._ease._calcEnd ? this._ease.getRatio(0) : 0),
        (o !== 0 || (l === 0 && h > 0))
                && ((r = 'onReverseComplete'), (s = this._reversed)),
        t < 0
                && ((this._active = !1),
                l === 0
                  && (this._initted || !this.vars.lazy || i)
                  && (h >= 0 && (h !== _ || this.data !== 'isPause') && (i = !0),
                  (this._rawPrevTime = a = !e || t || h === t ? t : _))),
        this._initted || (i = !0)
      } else if (((this._totalTime = this._time = t), this._easeType)) {
        let u = t / l
        const c = this._easeType
        const f = this._easePower;
        (c === 1 || (c === 3 && u >= 0.5)) && (u = 1 - u),
        c === 3 && (u *= 2),
        f === 1
          ? (u *= u)
          : f === 2
            ? (u *= u * u)
            : f === 3
              ? (u *= u * u * u)
              : f === 4 && (u *= u * u * u * u),
        (this.ratio = c === 1
          ? 1 - u
          : c === 2
            ? u
            : t / l < 0.5
              ? u / 2
              : 1 - u / 2)
      } else this.ratio = this._ease.getRatio(t / l)
      if (this._time !== o || i) {
        if (!this._initted) {
          if ((this._init(), !this._initted || this._gc)) return
          if (
            !i
                && this._firstPT
                && ((this.vars.lazy !== !1 && this._duration)
                  || (this.vars.lazy && !this._duration))
          ) {
            return (
              (this._time = this._totalTime = o),
              (this._rawPrevTime = h),
              F.push(this),
              (this._lazy = [t, e]),
              void 0
            )
          }
          this._time && !s
            ? (this.ratio = this._ease.getRatio(this._time / l))
            : s
                  && this._ease._calcEnd
                  && (this.ratio = this._ease.getRatio(this._time === 0 ? 0 : 1))
        }
        for (
          this._lazy !== !1 && (this._lazy = !1),
          this._active
                  || (!this._paused
                    && this._time !== o
                    && t >= 0
                    && (this._active = !0)),
          o === 0
                  && (this._startAt
                    && (t >= 0
                      ? this._startAt.render(t, e, i)
                      : r || (r = '_dummyGS')),
                  this.vars.onStart
                    && (this._time !== 0 || l === 0)
                    && (e || this._callback('onStart'))),
          n = this._firstPT;
          n;

        ) {
          n.f
            ? n.t[n.p](n.c * this.ratio + n.s)
            : (n.t[n.p] = n.c * this.ratio + n.s),
          (n = n._next)
        }
        this._onUpdate
              && (t < 0
                && this._startAt
                && t !== -1e-4
                && this._startAt.render(t, e, i),
              e || ((this._time !== o || s) && this._callback('onUpdate'))),
        r
                && (!this._gc || i)
                && (t < 0
                  && this._startAt
                  && !this._onUpdate
                  && t !== -1e-4
                  && this._startAt.render(t, e, i),
                s
                  && (this._timeline.autoRemoveChildren && this._enabled(!1, !1),
                  (this._active = !1)),
                !e && this.vars[r] && this._callback(r),
                l === 0
                  && this._rawPrevTime === _
                  && a !== _
                  && (this._rawPrevTime = 0))
      }
    }),
    (n._kill = function (t, e, i) {
      if (
        (t === 'all' && (t = null),
        t == null && (e == null || e === this.target))
      ) { return (this._lazy = !1), this._enabled(!1, !1) }
      e = typeof e !== 'string'
        ? e || this._targets || this.target
        : D.selector(e) || e
      let s
      let r
      let n
      let a
      let o
      let l
      let h
      let _
      let u
      const c = i
              && this._time
              && i._startTime === this._startTime
              && this._timeline === i._timeline
      if ((f(e) || M(e)) && typeof e[0] !== 'number') { for (s = e.length; --s > -1;) this._kill(t, e[s], i) && (l = !0) } else {
        if (this._targets) {
          for (s = this._targets.length; --s > -1;) {
            if (e === this._targets[s]) {
              (o = this._propLookup[s] || {}),
              (this._overwrittenProps = this._overwrittenProps || []),
              (r = this._overwrittenProps[s] = t ? this._overwrittenProps[s] || {} : 'all')
              break
            }
          }
        } else {
          if (e !== this.target) return !1;
          (o = this._propLookup),
          (r = this._overwrittenProps = t ? this._overwrittenProps || {} : 'all')
        }
        if (o) {
          if (
            ((h = t || o),
            (_ = t !== r
                  && r !== 'all'
                  && t !== o
                  && (typeof t !== 'object' || !t._tempKill)),
            i && (D.onOverwrite || this.vars.onOverwrite))
          ) {
            for (n in h) o[n] && (u || (u = []), u.push(n))
            if ((u || !t) && !H(this, i, e, u)) return !1
          }
          for (n in h) {
            (a = o[n])
                  && (c && (a.f ? a.t[a.p](a.s) : (a.t[a.p] = a.s), (l = !0)),
                  a.pg && a.t._kill(h) && (l = !0),
                  (a.pg && a.t._overwriteProps.length !== 0)
                    || (a._prev
                      ? (a._prev._next = a._next)
                      : a === this._firstPT && (this._firstPT = a._next),
                    a._next && (a._next._prev = a._prev),
                    (a._next = a._prev = null)),
                  delete o[n]),
            _ && (r[n] = 1)
          }
          !this._firstPT && this._initted && this._enabled(!1, !1)
        }
      }
      return l
    }),
    (n.invalidate = function () {
      return (
        this._notifyPluginsOfEnabled
              && D._onPluginEvent('_onDisable', this),
        (this._firstPT = this._overwrittenProps = this._startAt = this._onUpdate = null),
        (this._notifyPluginsOfEnabled = this._active = this._lazy = !1),
        (this._propLookup = this._targets ? {} : []),
        O.prototype.invalidate.call(this),
        this.vars.immediateRender
              && ((this._time = -_), this.render(-this._delay)),
        this
      )
    }),
    (n._enabled = function (t, e) {
      if ((o || a.wake(), t && this._gc)) {
        let i
        const s = this._targets
        if (s) { for (i = s.length; --i > -1;) this._siblings[i] = $(s[i], this, !0) } else this._siblings = $(this.target, this, !0)
      }
      return (
        O.prototype._enabled.call(this, t, e),
        this._notifyPluginsOfEnabled && this._firstPT
          ? D._onPluginEvent(t ? '_onEnable' : '_onDisable', this)
          : !1
      )
    }),
    (D.to = function (t, e, i) {
      return new D(t, e, i)
    }),
    (D.from = function (t, e, i) {
      return (
        (i.runBackwards = !0),
        (i.immediateRender = i.immediateRender != 0),
        new D(t, e, i)
      )
    }),
    (D.fromTo = function (t, e, i, s) {
      return (
        (s.startAt = i),
        (s.immediateRender = s.immediateRender != 0 && i.immediateRender != 0),
        new D(t, e, s)
      )
    }),
    (D.delayedCall = function (t, e, i, s, r) {
      return new D(e, 0, {
        delay: t,
        onComplete: e,
        onCompleteParams: i,
        callbackScope: s,
        onReverseComplete: e,
        onReverseCompleteParams: i,
        immediateRender: !1,
        lazy: !1,
        useFrames: r,
        overwrite: 0,
      })
    }),
    (D.set = function (t, e) {
      return new D(t, 0, e)
    }),
    (D.getTweensOf = function (t, e) {
      if (t == null) return []
      t = typeof t !== 'string' ? t : D.selector(t) || t
      let i; let s; let r; let
        n
      if ((f(t) || M(t)) && typeof t[0] !== 'number') {
        for (i = t.length, s = []; --i > -1;) { s = s.concat(D.getTweensOf(t[i], e)) }
        for (i = s.length; --i > -1;) { for (n = s[i], r = i; --r > -1;) n === s[r] && s.splice(i, 1) }
      } else { for (s = $(t).concat(), i = s.length; --i > -1;) (s[i]._gc || (e && !s[i].isActive())) && s.splice(i, 1) }
      return s
    }),
    (D.killTweensOf = D.killDelayedCallsTo = function (t, e, i) {
      typeof e === 'object' && ((i = e), (e = !1))
      for (let s = D.getTweensOf(t, e), r = s.length; --r > -1;) { s[r]._kill(i, t) }
    })
    var te = g(
      'plugins.TweenPlugin',
      function (t, e) {
        (this._overwriteProps = (t || '').split(',')),
        (this._propName = this._overwriteProps[0]),
        (this._priority = e || 0),
        (this._super = te.prototype)
      },
      !0,
    )
    if (
      ((n = te.prototype),
      (te.version = '1.18.0'),
      (te.API = 2),
      (n._firstPT = null),
      (n._addTween = X),
      (n.setRatio = N),
      (n._kill = function (t) {
        let e
        const i = this._overwriteProps
        let s = this._firstPT
        if (t[this._propName] != null) this._overwriteProps = []
        else for (e = i.length; --e > -1;) t[i[e]] != null && i.splice(e, 1)
        for (; s;) {
          t[s.n] != null
              && (s._next && (s._next._prev = s._prev),
              s._prev
                ? ((s._prev._next = s._next), (s._prev = null))
                : this._firstPT === s && (this._firstPT = s._next)),
          (s = s._next)
        }
        return !1
      }),
      (n._roundProps = function (t, e) {
        for (let i = this._firstPT; i;) {
          (t[this._propName]
              || (i.n != null && t[i.n.split(`${this._propName}_`).join('')]))
              && (i.r = e),
          (i = i._next)
        }
      }),
      (D._onPluginEvent = function (t, e) {
        let i
        let s
        let r
        let n
        let a
        let o = e._firstPT
        if (t === '_onInitAllProps') {
          for (; o;) {
            for (a = o._next, s = r; s && s.pr > o.pr;) s = s._next;
            (o._prev = s ? s._prev : n) ? (o._prev._next = o) : (r = o),
            (o._next = s) ? (s._prev = o) : (n = o),
            (o = a)
          }
          o = e._firstPT = r
        }
        for (; o;) {
          o.pg && typeof o.t[t] === 'function' && o.t[t]() && (i = !0),
          (o = o._next)
        }
        return i
      }),
      (te.activate = function (t) {
        for (let e = t.length; --e > -1;) { t[e].API === te.API && (j[new t[e]()._propName] = t[e]) }
        return !0
      }),
      (d.plugin = function (t) {
        if (!(t && t.propName && t.init && t.API)) { throw 'illegal plugin definition.' }
        let e
        const i = t.propName
        const s = t.priority || 0
        const r = t.overwriteProps
        const n = {
          init: '_onInitTween',
          set: 'setRatio',
          kill: '_kill',
          round: '_roundProps',
          initAll: '_onInitAllProps',
        }
        const a = g(
          `plugins.${i.charAt(0).toUpperCase()}${i.substr(1)}Plugin`,
          function () {
            te.call(this, i, s), (this._overwriteProps = r || [])
          },
          t.global === !0,
        )
        const o = (a.prototype = new te(i));
        (o.constructor = a), (a.API = t.API)
        for (e in n) typeof t[e] === 'function' && (o[n[e]] = t[e])
        return (a.version = t.version), te.activate([a]), a
      }),
      (s = t._gsQueue))
    ) {
      for (r = 0; s.length > r; r++) s[r]()
      for (n in p) {
        p[n].func
            || t.console.log(
              `GSAP encountered missing dependency: com.greensock.${n}`,
            )
      }
    }
    o = !1
  }
}(
  typeof module !== 'undefined'
      && module.exports
      && typeof global !== 'undefined'
    ? global
    : this || window,
  'TweenMax',
))
