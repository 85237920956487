<template>
  <div>
    <div class="h168" />
    <div class="account-section">
      <div class="panel">
        <div align="center">
          <br>
          <br><br><br><br><br>
          <div
            id="screenshot-section"
            class="the_wheel"
          >
            <img
              class="wheel_out"
              alt="หมุนโชควงล้อ"
              src="@/assets/wheel_img/wheel_back.gif"
            >
            <img
              class="wheel_logo"
              alt="หมุนโชควงล้อ"
              src="@/assets/wheel_img/wheel_logo.png"
            >
            <canvas
              id="canvas"
              width="500"
              height="500"
              class="wheel"
              data-responsiveminwidth="260"
              data-responsivescaleheight="true"
              data-responsivemargin="30"
            />
          </div>

          <div class="wheel-section mt">
            <div class="btn-spin">
              <center>
                <button
                  class="btn-gold-btns"
                  @click="clicks"
                >
                  หมุนโชคกงล้อ
                </button>
              </center>
            </div>
          </div><br>

          <div class="table-in">
            <h3 class="text-center mb-2">
              ประวัติการหมุนกงล้อของผู้เล่นอื่น
            </h3>
            <table class="history-table">
              <thead>
                <tr>
                  <th
                    class="text-center"
                    width="35%"
                  >
                    เบอร์โทรศัพท์
                  </th>
                  <th
                    class="text-center"
                    width="65%"
                  >
                    รายละเอียด
                  </th>
                </tr>
              </thead>
              <tbody>
                <!-- resdata -->
                <tr
                  v-for="(item) in resdata"
                  :key="item"
                >
                  <td class="text-center">
                    {{ item.tel }}
                  </td>

                  <td class="text-center">
                    <span class="font-plus">ได้รับโบนัส {{ item.bunus_amount }} ฿</span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import html2canvas from 'html2canvas'
import Winwheel from './js/Winwheel'
// eslint-disable-next-line no-unused-vars
import TweenMax from './js/TweenMax'
// eslint-disable-next-line semi
// import ThisFooter from './components/ThisFooter.vue';

export default ({
  components: {
    // ThisFooter,
  },

  data() {
    return {
      loading: false,
      show: true,
      UserData: JSON.parse(localStorage.getItem('userData')),
      balance: JSON.parse(localStorage.getItem('balance')),
      fullbank: '',
      amount: '',
      wto: 'bank',
      theWheel: '',
      loadedImg: null,
      xhr: {
        readyState: 4,
        status: 200,
      },
      resdata: [],
      screenshot: null,
      dataupdate: null,
    }
  },
  mounted() {
    this.GetShow()
    this.theWheel = new Winwheel({
      numSegments: 12,
      drawMode: 'image',
      responsive: true,
      segments:
    [
      { text: 'Bonus 0' },
      { text: 'Bonus 200' },
      { text: 'Bonus 500' },
      { text: 'Bonus 1,000' },
      { text: 'Bonus 0' },
      { text: 'Bonus 25' },
      { text: 'Bonus 50' },
      { text: 'Bonus 100' },
      { text: 'Bonus 0' },
      { text: 'Bonus 2000' },
      { text: 'Bonus 4000' },
      { text: 'Bonus 10000' },
    ],
      animation:
    {
      type: 'spinToStop',
      duration: 5,
      spins: 8,
      callbackFinished: this.alertPrize,
    },
    })

    this.loadedImg = new Image()
    this.loadedImg.onload = () => {
      this.theWheel.wheelImage = this.loadedImg
      this.theWheel.draw()
    }
    // eslint-disable-next-line global-require
    this.loadedImg.src = require('@/assets/wheel_img/wheel_in2.png')
  },
  methods: {
    takeScreenshot() {
      html2canvas(document.querySelector('#screenshot-section')).then(canvas => {
        // this.screenshot = canvas.toDataURL()
        this.Updatefile(canvas.toDataURL())
      })
    },
    GetShow() {
      this.$http
        .get('/luckywheel/index')
        .then(response => {
          this.resdata = response.data
        })
        .catch(error => {
          console.log(error)
        })
    },
    clicks() {
      this.$http
        .get('/luckywheel')
        .then(response => {
          this.dataupdate = response.data.data
          const xhr = new XMLHttpRequest()
          xhr.onreadystatechange = this.ajaxStateChange(response.data.spin)
        })
        .catch(error => {
          this.SwalError(error.response.data.message)
        })
    },
    Updatefile(img) {
      const Obj = {
        // eslint-disable-next-line no-underscore-dangle
        id: this.dataupdate._id,
        screenshot: img,
      }
      this.$http
        .post('/luckywheel/UploadImg', Obj)
        // eslint-disable-next-line no-unused-vars
        .then(response => {
          // this.resdata = response.data
        })
        .catch(error => {
          console.log(error)
        })
    },
    ajaxStateChange(num) {
      const stopAt = this.theWheel.getRandomForSegment(num)
      this.theWheel.animation.stopAngle = stopAt
      this.theWheel.startAnimation()
    },
    async alertPrize(indicatedSegment) {
      this.takeScreenshot()
      // eslint-disable-next-line no-alert
      // alert(`คุณได้รับโบนัส : ${indicatedSegment.text}`)
      await this.$swal({
        icon: 'success',
        title: `คุณได้รับโบนัส : ${indicatedSegment.text}`,
        showConfirmButton: true,
        // timer: 10000,
        background: '#202124',
        customClass: {
          confirmButton: 'btn btn-success',
        },
      })
      // eslint-disable-next-line no-restricted-globals
      location.reload()
    },
    SwalError(mes) {
      this.$swal({
        icon: 'warning',
        title: '<h3 style="color: #FFF">แจ้งเตือน !</h3>',
        text: mes,
        customClass: {
          confirmButton: 'btn btn-primary',
        },
      })
    },
  },
})
</script>

<style scoped>
canvas.wheel {
    width: 100%;
}
</style>
